import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSingleDeal } from "../../../redux/actions/dealAction";
import useGetSingleDeal from "../dealHooks/useGetSingleDeal";

const useSingleDeal = (id) => {
  const { data, isLoding } = useGetSingleDeal(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleDeal(data.data));
    }
  }, [dispatch, data]);
  return { isLoding };
};

export default useSingleDeal;
