import { IconButton } from "@mui/material";
import React, { useRef } from "react";
import { MdUploadFile } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";

const FileInputNf = ({ name, file, setFile, onChange, ...otherProps }) => {
  const fileRef = useRef(null);
  return (
    <>
      {" "}
      <input
        type="file"
        name={name}
        ref={fileRef}
        onChange={onChange}
        hidden
        {...otherProps}
      />
      {!file ? (
        <button
          className="my-3 import-file-button"
          onClick={() => fileRef.current?.click()}
        >
          Select the file
        </button>
      ) : (
        <button
          className="my-3 import-button"
          style={{ position: "relative", cursor: "unset" }}
        >
          {" "}
          <MdUploadFile size={18} /> {file?.name}
          <IconButton
            style={{
              position: "absolute",
              top: "1px",
              right: "2px",
            }}
            onClick={() => {
              setFile(null);
              fileRef.current.value = null;
            }}
          >
            <TiDeleteOutline size={20} />
          </IconButton>
        </button>
      )}
    </>
  );
};

export default FileInputNf;
