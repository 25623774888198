import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import getDomain from "../../../utils/getDomain";
import { MdOutlineModeEdit } from "react-icons/md";
import LeadDrower from "../../../pages/lead/leadDrower/index";
import { useUpdateContact } from "../../../redux/actions/contactAction";

const ContactAvatar = () => {
  const { contact } = useSelector((state) => state.contactStore);
  const { user } = useSelector((state) => state.userStore);
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];
  const { mutate, isLoading } = useUpdateContact(contact?.uuid);

  return (
    <>
      <Grid
        container
        sx={{
          borderBottom: "0.5px solid #C2C2C2",
          position: "relative",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ top: "1px", right: "1px", position: "absolute" }}>
          <LeadDrower
            name={
              <Tooltip title="Update Contact">
                <IconButton sx={{ padding: "4px" }}>
                  <MdOutlineModeEdit />
                </IconButton>
              </Tooltip>
            }
            heading="Contact Card"
            data={contact}
            mutate={mutate}
            isLoading={isLoading}
            needRightClickMenu={false}
            needEditButton={true}
          />
        </div>
        <Grid item xs={12} alignItems="center" display={"flex"} pt={2}>
          <Avatar
            alt={userData.full_name}
            src={`${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
              process.env.REACT_APP_API_URL
            }${userData?.profile_pic}`}
            sx={{
              width: "65px",
              height: "65px",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="pt-3 pb-4"
            style={{
              color: "#313131",
              fontSize: "18px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {contact?.prefix
              ? contact?.prefix + ". " + contact?.first_name
              : contact?.first_name}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactAvatar;
