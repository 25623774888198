import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const getSingleContact = async (args) => {
  return await tenantAPI.get(`/contacts/${args}/`);
};

const useGetSingleContact = (id, options = {}) => {
  return useQuery(["contact", id], () => getSingleContact(id), options);
};

export default useGetSingleContact;
