import React from "react";

import ActivityHOC from "./ActivityHOC";

import { Typography } from "@mui/material";

import { AllInclusive } from "@mui/icons-material";

const Sequences = () => {
  return (
    <ActivityHOC
      sx={{ marginTop: "0px" }}
      icon={<AllInclusive />}
      title='Sequences'
      subtitle='No Sequences'
    >
      <Typography variant='p' className='second mt-0' fontSize='10px'>
        Create First Sequence and start using it to send automated emails to
        contacts
      </Typography>
    </ActivityHOC>
  );
};

export default Sequences;
