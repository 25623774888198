import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import DatePicker from "@mui/lab/DatePicker";

const SelectDate = ({ name, className, label, format, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
  };
  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, new Date());
    // eslint-disable-next-line
  }, [name]);

  return (
    <div className={className}>
      <DatePicker
        type="date"
        label={label}
        value={configTextfield.value}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
        }}
        // format={format}
        renderInput={(params) => (
          <TextField
            // className={root}
            {...params}
            hiddenLabel
            {...configTextfield}
          />
        )}
      />
    </div>
  );
};

export default SelectDate;
