import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { setSingleAccount } from "../../../redux/actions/accountAction";

import useGetSingleAccount from "../accountHooks/useGetSingleAccount";

const useSingleAccount = (id) => {
  const { data, isLoading } = useGetSingleAccount(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleAccount(data.data));
    }
  }, [dispatch, data]);

  return { isLoading };
};

export default useSingleAccount;
