/** @format */

import { useMutation } from "react-query";
import axios from "axios";
import moment from "moment";
import getDomain from "../../utils/getDomain";

const useGetC2CReport = (pNumber , sNumber , wNumber, fromDate, toDate, name) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  const getCallLogs = async () => {
    const primary_number = pNumber?.replace(/-/g, "");
    const secondary_number = sNumber?.replace(/-/g, "");
    const work_number = wNumber?.replace(/-/g, "");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
          process.env.REACT_APP_API_URL
        }/tportal/get-call-logs/`,
        {
          primary_number : primary_number,
          secondary_number : secondary_number,
          work_number : work_number,
          fdate: moment(fromDate).format("YYYY-MM-DD"),
          tdate: moment(toDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error making the API request", error);
      throw error;
    }
  };

  const mutation = useMutation(getCallLogs);
  return { mutate: mutation.mutateAsync };
};

export default useGetC2CReport;
