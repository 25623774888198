/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Phone, MailOutline } from "@mui/icons-material";

import { Grid, Box, Typography, useMediaQuery } from "@mui/material";

import { useSelector } from "react-redux";

import PhoneMenu from "../../ivr/PhoneMenu";
import "./contact.css";
const ContactCompany = () => {
  const maxMatches = useMediaQuery("(min-width:1100px)");
  const minMatches = useMediaQuery("(min-width:900px)");
  // const matches = useMediaQuery("(max-width:900px)");

  const { contact } = useSelector((state) => state.contactStore);

  return (
    <Grid
      container
      className='py-3 contact-about-wrapper'
      display='grid'
      direction='column'
      alignItems='center'
    >
      <Grid
        item
        xs={12}
        //  className= {minMatches ? "mb-1" : "d-flex align-items-center mb-1"}
        className='d-flex align-items-center mb-1 contact-work-wrapper'
      >
        <Grid item xs={4} md={12}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Work Number
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          md={12}
          className='d-flex justify-content-end contact-work-data'
        >
          {" "}
          {contact?.work_number ? (
            <PhoneMenu
              name={
                <Box
                  className='text-truncate overflow-hidden me-2 mt-2'
                  sx={{
                    maxWidth: "200px",
                    border: "1px solid #1F73B7",
                    borderRadius: "5px",
                    color: "#1F73B7",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "2px 4px",
                    }}
                    className='d-flex align-items-center me-1 custom-link-color'
                  >
                    <Phone fontSize='small' /> {contact?.work_number}
                  </p>
                </Box>
              }
              phNum={contact?.work_number}
            />
          ) : (
            "-"
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className='d-flex align-items-center mb-1 contact-work-wrapper'
      >
        <Grid item xs={4}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Mobile Number
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          className='d-flex justify-content-end contact-work-wrapper'
        >
          {contact?.primary_number ? (
            <PhoneMenu
              name={
                <Box
                  className='text-truncate overflow-hidden me-2 mt-2'
                  sx={{
                    maxWidth: "200px",
                    border: "1px solid #1F73B7",
                    borderRadius: "5px",
                    color: "#1F73B7",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "2px 4px",
                    }}
                    className='d-flex align-items-center me-1 custom-link-color'
                  >
                    <Phone fontSize='small' /> {contact?.primary_number}
                  </p>
                </Box>
              }
              phNum={contact?.primary_number}
            />
          ) : (
            "-"
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className='d-flex align-items-center  mb-1 contact-work-wrapper'
      >
        <Grid item xs={4}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Email
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          className='d-flex justify-content-end contact-work-wrapper'
        >
          {contact?.primary_email ? (
            <Box
              className='text-truncate overflow-hidden me-2 mt-2'
              sx={{
                maxWidth: "200px",
                border: "1px solid #1F73B7",
                borderRadius: "5px",
                color: "#1F73B7",
                display: "inline-block",
              }}
            >
              <Link
                to='#'
                style={{
                  fontSize: "14px",
                  margin: "2px 4px",
                }}
                className='d-flex align-items-center me-1 custom-link-color'
              >
                <MailOutline fontSize='small' className='me-1' />
                <span className='text-truncate'> {contact?.primary_email}</span>
              </Link>
            </Box>
          ) : (
            "-"
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className='d-flex align-items-center mt-2 mb-1 contact-work-wrapper'
      >
        <Grid item xs={4}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Company
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          className='d-flex justify-content-end contact-work-wrapper'
        >
          <Typography
            component='p'
            style={{ color: "#919191", fontWeight: 500, fontSize: "14px" }}
          >
            {contact?.company?.company_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className='d-flex align-items-center  my-2 contact-work-wrapper'
      >
        <Grid item xs={4}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Website{" "}
          </Typography>
        </Grid>
        <Grid item xs={8} className='d-flex justify-content-end'>
          <Typography
            component='p'
            style={{ color: "#919191", fontWeight: 500, fontSize: "14px" }}
          >
            {contact?.website}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactCompany;
