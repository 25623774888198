import React from "react";

import { Grid } from "@mui/material";

import InputField from "../../../../components/formsUI/InputField";
import CountrySelect from "../../../../components/formsUI/CountriesSelect";

const AddressInfo = ({ name }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputField
          name={name.address.name}
          label={name.address.lable}
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={name.city.name}
          label={name.city.lable}
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={name.state.name}
          label={name.state.lable}
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountrySelect
          name={name.nationality.name}
          label={name.nationality.lable}
          color='secondary'
          selectValue='name'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={name.pin_code.name}
          label={name.pin_code.lable}
          color='secondary'
          size='small'
          type='number'
        />
      </Grid>
    </Grid>
  );
};

export default AddressInfo;
