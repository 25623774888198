import * as Yup from "yup";

const useUpdateQuote = (data) => {
  const QUOTE_UPDATE_INITIAL_FORM_STATE = {
    discount: data?.discount ? data?.discount : "",
    expiry_date: data?.expiry_date ? data?.expiry_date : "",
    quote_title: data?.quote_title ? data?.quote_title : "",
    terms_and_condition: data?.terms_and_condition
      ? data?.terms_and_condition
      : "",
    contact: { uuid: data?.contact, label: data?.contact_name },
  };

  const QUOTE_FORM_VALIDATION = Yup.object().shape({
    quote_title: Yup.string().required("Quote Name is required"),
    terms_and_condition: Yup.string().required("T&C is required"),
    expiry_date: Yup.date().required("Expiry date is required"),
    discount: Yup.number()
      .required("Discount is required")
      .positive()
      .integer(),
    deal: Yup.string(),
    quote_file: Yup.string().nullable(),
    owner: Yup.string(),
    contact: Yup.mixed().required("Contact is required"),
  });
  return {
    QUOTE_UPDATE_INITIAL_FORM_STATE,
    QUOTE_FORM_VALIDATION,
  };
};

export default useUpdateQuote;
