  import Button from "@mui/material/Button";
  import Dialog from "@mui/material/Dialog";
  import DialogContent from "@mui/material/DialogContent";
  import DialogContentText from "@mui/material/DialogContentText";
  import DialogTitle from "@mui/material/DialogTitle";

  import useDelete from "./useDelete";
  import { DialogActions } from "@mui/material";

  const DeleteDialog = ({
    handleClose,
    mutate,
    getApiRefetch,
    uuid,
    description,
    anotherAction,
    element,
  }) => {
    const { open, handleClickOpen, handleClickClose } = useDelete(handleClose);

    const handleDelete = () => {
      mutate
        ? mutate(uuid, {
            onSuccess: () => {
              handleClickClose();
              handleClose && handleClose();
            },
          })
        : (() => {
            getApiRefetch();
            handleClickClose();
          })();
    };

    return (
      <>
        <div onClick={handleClickOpen}>{element}</div>
        <Dialog
          open={open}
          onClose={handleClickClose}
          maxWidth="sm"
          fullWidth="true"
          aria-labelledby="alert-Dialog-title"
          aria-describedby="alert-Dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="border-bottom">
            {"Are you sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="mt-1">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="border-top">
            <Button onClick={handleClickClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              color={anotherAction ? "success" : "error"}
              autoFocus
              variant="contained"
            >
              {anotherAction ? anotherAction : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  export default DeleteDialog;
