import { useState } from "react";

import * as Yup from "yup";

const useConvertToDeal = (handleClose, data) => {
  const [open, setOpen] = useState(false);

  const defaultState = {
    // uuid: data.uuid ? data.uuid : "",
    // proposed_price: "",
    valuation: "",
  };

  const validation = Yup.object().shape({
    // proposed_price: Yup.number(),
    valuation: Yup.number(),
  });

  const handleClickOpen = (e) => {
    e?.preventDefault();
    setOpen(true);
  };

  const handleCtoCClose = () => {
    setOpen(false);
    handleClose && handleClose();
  };

  return { open, defaultState, validation, handleClickOpen, handleCtoCClose };
};

export default useConvertToDeal;
