/** @format */

import { Grid } from "@mui/material";
import React from "react";
import AutoSelectField from "../../../../formsUI/AutoComplete";
import useContactData from "../../../../../pages/contact/contactHooks/useContactData";

const AddContactForm = () => {
  const { data } = useContactData({});
  const Options = data?.data.map((item) => ({
    lead_uuid: item?.uuid,
    label: item?.first_name + " " + item?.last_name,
  }));

  return (
    <>
      <Grid container>
        <Grid xs={12} mt={2} sm={6} className='p-2'>
          <AutoSelectField
            name='contact'
            label='Contact*'
            color='secondary'
            size='small'
            multiple={false}
            options={Options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddContactForm;
