import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useGetC2CReport from "../../../../ivr/useGetC2CReport";
import { MdOutlinePhoneMissed } from "react-icons/md";
import DateRangePickerMui from "../../../../formsUI/DateRangePicker";
import moment from "moment";
import { toast } from "react-toastify";
import getDomain from "../../../../../utils/getDomain";

const PreviousCalls = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const { contact } = useSelector((state) => state.contactStore);
  const [previousCalls, setPreviousCalls] = useState(null);
  const date = new Date();
  console.log(contact, "<=====contact data ");
  const [dateValue, setDateValue] = useState([
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "MM/DD/YYYY"
    ),
    moment(date).format("MM/DD/YYYY"),
  ]);

  const { mutate: getCallLogs } = useGetC2CReport(
    contact?.primary_number,
    contact?.secondary_number,
    contact?.work_number,
    dateValue[0],
    dateValue[1]
  );

  const handleFetchLogs = () => {
    if (dateValue[0] === null || dateValue[1] === null) {
      toast.error("Please select a valid date range");
    } else {
      toast.promise(
        new Promise((resolve, reject) => {
          getCallLogs(undefined, {
            onSuccess: (data) => {
              console.log("API Response:", data);
              setPreviousCalls(data.data);
              resolve();
            },
            onError: (error) => {
              reject();
              console.error("API Error:", error);
            },
          });
        }),
        {
          pending: "Fetching data...",
          success: "List updated successfully 👌",
          error: "Error fetching call logs 🤯",
        }
      );
    }
  };

  return (
    <>
      <Grid container className='mb-3'>
        <Grid
          item
          xs={12}
          sm={8}
          className='mb-2'
          style={matches ? { display: "grid", placeContent: "center" } : {}}>
          <DateRangePickerMui
            value={dateValue}
            setValue={setDateValue}
            startLabel='From Date'
            endLabel='To Date'
            style={matches ? { width: "120px" } : { width: "140px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={matches ? { display: "grid", placeContent: "center" } : {}}>
          <Button
            variant='contained'
            type='submit'
            className='text-capitalize bg-first fifth px-3 py-1 ms-3 d-block'
            style={{
              paddingInline: "15px",
              paddingBlock: "0px",
            }}
            onClick={handleFetchLogs}>
            Get Details
          </Button>
        </Grid>
      </Grid>

      {previousCalls?.length > 0 ? (
        previousCalls.map((log, index) => (
          <div key={index}>
            <div className='d-flex justify-content-between'>
              <Typography
                color='text.dark'
                fontSize='12px'
                className='d-flex align-items-center'>
                To: {contact.first_name} - {contact.last_name},{" "}
                {contact?.company?.company_name}
              </Typography>
              <span>
                <Typography
                  fontSize='12px'
                  color='text.dark'
                  display='inline'
                  className='me-2'>
                  {log?.start_time}
                </Typography>
              </span>
            </div>
            <div>
              <Typography color='text.dark' fontSize='12px' display='inline'>
                Number:
                <span className='ms-2'>{log?.to_call}</span>
              </Typography>
            </div>
            <div className='mt-1 d-flex align-items-center'>
              {log?.recording !== null ? (
                <>
                  <Typography
                    color='text.dark'
                    fontSize='12px'
                    display='inline'>
                    Call Recording:
                  </Typography>
                  <audio
                    style={{ height: "30px" }}
                    className='ms-2'
                    controls
                    src={`${
                      process.env.REACT_APP_API_PROTOCOL
                    }${getDomain()}${"."}${
                      process.env.REACT_APP_API_URL
                    }${log?.recording?.slice(4)}`}>
                    Your browser does not support the <code>audio</code>{" "}
                    element.
                  </audio>
                </>
              ) : (
                <Typography
                  color='text.dark'
                  fontSize='12px'
                  display='inline'
                  className='d-flex align-items-center'>
                  Call Unanswered{" "}
                  <MdOutlinePhoneMissed
                    style={{ color: "red" }}
                    className='ms-2'
                  />
                </Typography>
              )}
            </div>

            <Divider className='mt-2 mb-4' />
          </div>
        ))
      ) : (
        <Typography>No call logs found.</Typography>
      )}
    </>
  );
};

export default PreviousCalls;
