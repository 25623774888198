import { List } from "@mui/material";
import React from "react";
import CustomListItem from "./CustomListItem";
import "./SideBar.css";
import { ListAlt } from "@mui/icons-material";
import { useState } from "react";

const SideBar = ({ menu }) => {
  const [open, setOpen] = useState("");
  return (
    <div className="sidebar">
      <List className="px-2">
        {Object.keys(menu).map((key) => (
          <CustomListItem
            title={key}
            icon={ListAlt}
            setOpen={setOpen}
            open={open}
            subItemList={menu[key]}
          />
        ))}
      </List>
    </div>
  );
};

export default SideBar;
