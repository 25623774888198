import { useState } from "react";

const useDelete = (handleClose) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    handleClose && handleClose();
  };

  return { open, handleClickOpen, handleClickClose };
};

export default useDelete;
