import "./css.css"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import { Grid } from "@mui/material"
import { useDispatch } from "react-redux"
import { closeRoleDailog } from "../../../redux/actions/LayoutAction"
import * as Yup from "yup"
import useCreateRoles from "../rolesHooks/useCreateRoles"
import { useRolesData, usePermissionsData } from "../rolesHooks/useRolesData"
import RolesForm from "../rolesForm"

const CreateNewRole = ({ dialogOpen }) => {


  const dispatch = useDispatch()
  const { refetch } = useRolesData()

  const { mutate } = useCreateRoles()

  const handleClose = () => {
    dispatch(closeRoleDailog())
  }
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          description: "",
          is_active: false,
          permissionsList: [],
          expanded: ["Select"],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required!"),
          description: Yup.string().required("Description is required!"),
          permissionsList: Yup.array().required(
            "Please select atleast one permission"
          ),
        })}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          mutate(values, {
            onSuccess: () => {
              resetForm()
              refetch()
              handleClose()
            },
          })
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Dialog
              fullWidth
              maxWidth="md"
              scroll="paper"
              open={dialogOpen}
              aria-labelledby="scroll-Dialog-title"
              aria-describedby="scroll-Dialog-content"
            >
              <DialogTitle
                sx={{
                  background: "#ededed",
                  position: "sticky",
                  top: "0",
                  right: "0",
                  zIndex: 10,
                }}
                className="border-bottom border-dark shadow-sm"
                id="scroll-Dialog-title"
              >
                New Role
              </DialogTitle>
              <DialogContent>
                <RolesForm></RolesForm>
              </DialogContent>
              <DialogActions
                sx={{
                  background: "#ededed",
                }}
                className="border-top border-dark"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className="text-capitalize"
                      sx={{
                        padding: "4px 10px !important",
                      }}
                      onClick={() => submitForm()}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateNewRole
