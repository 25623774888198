import React from "react";

import { Route, Routes } from "react-router-dom";
import Navbar from "../../../components/Layout/navbar";

import PrivateRoute from "../../../utils/PrivateRoute";

import AccountTable from "../accountTable";
import SingleAccount from "../singleAccount";

const Account = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Routes>
        <Route path='/' element={<AccountTable />} />
        <Route path='/:uuid' element={<SingleAccount />} />
      </Routes>
    </PrivateRoute>
  );
};

export default Account;
