import React from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import InputField from "../../../components/formsUI/InputField";
import Subdomain from "../../../components/formsUI/Subdomain";

import { Formik, Form } from "formik";

import useRegisterData from "./useRegister";
import { useCompanyRegister } from "../../../redux/actions/authAction";
import { useSelector } from "react-redux";

const CompanyInfo = () => {
  const style = {
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };

  const { comapnyState, comapnyValidation } = useRegisterData();
  const { mutate, isLoading } = useCompanyRegister();
  const { userid, password } = useSelector((state) => state.authStore);

  return (
    <Formik
      initialValues={{
        ...comapnyState,
      }}
      validationSchema={comapnyValidation}
      onSubmit={async (values) => {
        mutate({
          ...values,
          domain: `${values.domain.trim()}.${
            process.env.REACT_APP_API_HOSTNAME_FOR_DOMAIN
          }`
            .toLowerCase()
            .replace(/ /g, "-"),
          user: userid,
          password,
        });
      }}
    >
      <Form>
        <Grid container spacing={2} className="pt-4">
          <Grid item xs={6}>
            <InputField
              name="company_name"
              label="Company Name"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              name="no_of_employee"
              label="Number of Employee"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={12}>
            <Subdomain
              name="domain"
              label="Your Lead Masters CRM Subdomain"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={isLoading}
              variant="contained"
              color="secondary"
              type="submit"
              className="text-light"
              sx={{
                borderRadius: 0,
                "&:hover": {
                  background: "#576a77e7",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress className="me-2" color="primary" size={20} />
              ) : (
                "Register Company"
              )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="12px">
              By clicking “Complete Sign Up” you agree to the Lead Masters CRM
              Subscription Agreement and Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default CompanyInfo;
