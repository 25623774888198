import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const DisplayAddress = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          item.add?.length > 0 && (
            <Grid
              key={index}
              item
              xs={12}
              alignItems="center"
              sx={{ marginTop: "5px" }}
            >
              <Box
                className="d-flex align-items-end"
                sx={{
                  maxWidth: "90%",
                }}
              >
                <Typography
                  variant="p"
                  fontSize="14px"
                  color="#313131"
                  sx={{
                    marginRight: "5px",
                    inlineSize: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  {item.add.split("^").join(", ")} &nbsp;
                  <span
                    style={{
                      fontSize: "12px",
                      marginBottom: "1px",
                      overflowWrap: "break-word",
                      fontWeight: "300",
                    }}
                    className="second"
                  >
                    {item.label}
                  </span>
                </Typography>
              </Box>
            </Grid>
          )
        );
      })}
    </>
  );
};

export default DisplayAddress;
