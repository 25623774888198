/** @format */

import { Link } from "react-router-dom";
import { CallOutlined, Person } from "@mui/icons-material";
import RightClickMenu from "../../../components/lead/RightClickMenu";
import LaunchIcon from "@mui/icons-material/Launch";
import PhoneMenu from "../../../components/ivr/PhoneMenu";
import { Box } from "@mui/system";
import DeleteDialog from "../../../components/utils/deleteDialog";
import { MenuItem } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useTaskData from "../taskHooks/useTaskData";
import { useTaskDelete } from "../../../redux/actions/dealAction";

const useTaskTable = () => {
  const { isLoading, data } = useTaskData({});
  const { mutate: deleteTask } = useTaskDelete();

  const createColumnSchema = (data) => {
    if (!data) return [];

    const columns = Object.keys(data)
      .filter(
        (key) =>
          ![
            "uuid",
            "additional_fields",
            "created_on",
            "updated_on",
            "created_by",
            "middle_name",
            "id",
            "notes",
            "prefix",
          ].includes(key)
      ) // filter out unwanted fields
      .map((key, pos) => ({
        field: key,
        headerName: key.replace("_", " "),
        minWidth: 150,
        hide: pos > 10 ? true : false,
        headerClassName: "text-capitalize full-width",
        cellClassName: "p-0",
        flex: 1,
        renderCell: (params) => {
          if (key === "task_title") {
            return (
              <RightClickMenu
                className='d-flex'
                name={
                  <Link
                    to={`/deals/${params?.row?.Deal?.uuid}`}
                    className='ps-3 primary-main text-truncate second text-decoration-underline'
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {params.value}
                  </Link>
                }
              >
                <DeleteDialog
                  mutate={deleteTask}
                  uuid={data?.uuid}
                  element={
                    <MenuItem sx={{ fontSize: "13px" }}>
                      <DeleteForeverIcon fontSize='18' className='me-2' />
                      Delete
                    </MenuItem>
                  }
                  description='This Task will be permanently deleted.'
                />
              </RightClickMenu>
            );
          } else if (
            ["primary_number", "secondary_number", "work_number"].includes(key)
          ) {
            return params.value ? (
              <PhoneMenu
                name={
                  <Box
                    className='ps-3 primary-main text-truncate second'
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      width: "100%",
                      fontSize: 13,
                      height: "100%",
                    }}
                  >
                    <CallOutlined className='me-1' fontSize='12px' />
                    {params.row.prefix
                      ? params.row.prefix + ". " + params.value
                      : params.value}
                  </Box>
                }
                phNum={params.value}
              />
            ) : (
              <Box
                className='ps-3 primary-main text-truncate second'
                style={{
                  width: "100%",
                  height: "100%",
                  textDecoration: "none",
                }}
              >
                {params.value}
              </Box>
            );
          } else if (key === "Deal") {
            return (
              <Link
                to={`/deals/${params?.row?.Deal?.uuid}`}
                className='primary-main text-truncate second text-decoration-underline d-flex align-items-center ms-3'
                style={{
                  width: "100%",
                  height: "100%",
                  textDecoration: "none",
                }}
              >
                <LaunchIcon className='me-1' />
                {params.row.Deal?.name}
              </Link>
            );
          } else {
            return (
              <p
                className='ps-3 primary-main d-flex align-items-center text-truncate second'
                style={{
                  width: "100%",
                  height: "100%",
                  textDecoration: "none",
                }}
              >
                {typeof params.value === "string"
                  ? params.value
                  : params.value?.company_name}
              </p>
            );
          }
        },
      }));

    // Move the 'Deal' column to the first position
    const dealColumnIndex = columns.findIndex((col) => col.field === "Deal");
    if (dealColumnIndex > -1) {
      const [dealColumn] = columns.splice(dealColumnIndex, 1);
      columns.unshift(dealColumn); // Add 'Deal' column to the start
    }

    return columns;
  };

  return { isLoading, createColumnSchema, data };
};

export default useTaskTable;
