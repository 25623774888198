import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const LeadsLostDetails = () => {
  const columns = [
    { id: "deal_name", label: "Deal Name" },
    { id: "company", label: "Company" },
    { id: "contact", label: "Contact" },
    { id: "deal_owner", label: "Deal Owner" },
    { id: "created_date", label: "Created Date" },
    { id: "closed_date", label: "Closed Date" },
    { id: "product_name", label: "Product Name" },
    { id: "product_quantity", label: "Product Quantity" },
    { id: "discount", label: "Discount" },
    { id: "deal_amount", label: "Deal Amount" },
  ];

  const data = [
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
    [
      "Amaan Khan",
      "Fladdra Tech",
      "Local Sajid",
      "Tanmay Shinde",
      "30/7/2022",
      "30/7/2022",
      "Product 01",
      "5",
      "2,500",
      "3,444",
    ],
  ];

  return (
    <>
      <Typography component="p" fontWeight="500" fontSize="20px">
        Leads Lost in May 2022
      </Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  tyle={{ minWidth: column.minWidth, fontWeight: "600" }}
                  className="bg-third"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {row.map((column) => {
                    return <TableCell>{column}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LeadsLostDetails;
