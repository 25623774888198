/** @format */
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import for navigation
import { useTaskUpdate } from "../../../redux/actions/dealAction";
import { tenantAPI } from "../../../config/api";

const localizer = momentLocalizer(moment);

const MyCalendar = ({ data }) => {
  const [events, setEvents] = useState([]);
  const phone = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate(); // Initialize history for navigation

  useEffect(() => {
    const fetchedTasks = data
      ?.filter((task) => task !== null && task !== undefined)
      .map((task) => ({
        ...task,
        completed: task.completed || false, // Ensure completed property is present
      }));
    console.log("25 25 25 fetchedTasks", fetchedTasks);
    setEvents(fetchedTasks);
  }, [data]);

  const handleCheckboxChange = async (task) => {
    // const updatedEvents = events.map((task) => {
    // if (task.title === event.title) {
    const newCompletedStatus = !task.completed;
    const updateTaskData = { id: task.id, is_completed: newCompletedStatus };
    let newData = {
      is_completed: newCompletedStatus,
      id: task?.id,
    };
    await tenantAPI.put(`/deals/add_task/${task?.id}/`, newData);

    const newEvents = events.map((eve) =>
      eve.id == task.id ? { ...eve, completed: newCompletedStatus } : eve
    );

    setEvents(newEvents);
  };

  const handleEventClick = (data) => {
    const url = data.related_links;
    const id = url.split("/").filter(Boolean).pop(); // Filter removes empty strings
    console.log(id); // Output: "d6e64155-ea8e-48a9-bd3d-7b82027a7fcb"

    navigate(`/deals/${id}`);
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.completed ? "#4CAF50" : "#274c77"; // Green for completed, pink for pending
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0",
        display: "flex",
        alignItems: "center",
        padding: "5px",
      },
    };
  };

  // Custom Event Component
  const EventComponent = ({ event }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        type='checkbox'
        checked={event.completed}
        onChange={() => handleCheckboxChange(event)}
        style={{ marginRight: "10px" }}
      />
      <span
        onClick={() => handleEventClick(event)}
        style={{ cursor: "pointer" }}
      >
        {event.title}
      </span>
    </div>
  );

  return (
    <div className={`calendar-container`}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        eventPropGetter={eventStyleGetter}
        className='custom-calendar'
        scrollToTime={new Date(2024, 9, 1, 9, 0, 0)}
        components={{ event: EventComponent }} // Use custom event component
      />
    </div>
  );
};

export default MyCalendar;
