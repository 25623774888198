const initialState = {
  leadDialog: false,
  accountDialog: false,
  contactDialog: false,
  openProductDialog: false,
  openUserDialog: false,
  openRoleDialog:false,
  page: 0,
  rowCount: 0,
  size: 20,
}

export const layoutReducer = (state = initialState, action) => {
 
  const reducer = {
    OPEN_LEAD_DAILOG: () => {
      return {
        ...state,
        leadDialog: true,
      }
    },
    CLOSE_LEAD_DAILOG: () => {
      return {
        ...state,
        leadDialog: false,
      }
    },
    OPEN_ACCOUNT_DIALOG: () => {
      return {
        ...state,
        accountDialog: true,
      }
    },
    CLOSE_ACCOUNT_DIALOG: () => {
      return {
        ...state,
        accountDialog: false,
      }
    },
    OPEN_CONTACT_DIALOG: () => {
      return {
        ...state,
        contactDialog: true,
      }
    },
    CLOSE_CONTACT_DIALOG: () => {
      return {
        ...state,
        contactDialog: false,
      }
    },
    OPEN_PRODUCT_DAILOG: () => {
      return {
        ...state,
        openProductDialog: true,
      }
    },
    CLOSE_PRODUCT_DAILOG: () => {
      return {
        ...state,
        openProductDialog: false,
      }
    },
    OPEN_USER_DAILOG: () => {
      return {
        ...state,
        openUserDialog: true,
      }
    },
    CLOSE_USER_DAILOG: () => {
      return {
        ...state,
        openUserDialog: false,
      }
    },
    ON_PAGE_CHANGE: () => {
      return {
        ...state,
        page: action.payload,
      }
    },
    ON_SIZE_CHANGE: () => {
      return {
        ...state,
        size: action.payload,
      }
    },
    ON_DEFAULT_PAGINATION: () => {
      return {
        ...state,
        ...action.payload,
      }
    },

    OPEN_ROLE_DIALOG: () => {
      return {
        ...state,
        openRoleDialog: true,
      }
    },
    CLOSE_ROLE_DIALOG: () => {
      return {
        ...state,
        openRoleDialog: false,
      }
    },
  }

  return reducer[action?.type] ? reducer[action?.type]() : state
}
