/** @format */

import React from "react";

import { Grid, Stack } from "@mui/material";

import AccountOwner from "./AccountOwner";
import AccountCompany from "./AccountCompany";
import AccountAddress from "./AccountAddress";
import AccountAvatar from "./AccountAvatar";

const AccountCard = () => {
  return (
    <Grid item xs={12} sm={12} lg={3}>
      <Stack
        direction='column'
        alignItems='flex-start'
        style={{ borderRadius: "10px", height: "100%" }}
        className='bg-card p-3'
      >
        <AccountAvatar />
        <AccountOwner />
        <AccountCompany />
        <AccountAddress />
      </Stack>
    </Grid>
  );
};

export default AccountCard;
