import React from "react";

import { Box } from "@mui/material";

const DashboardCard = ({ children, sx, isSelected, ...rest }) => {
  // const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        padding: "14px 0px 14px 40px",
        background: "#FFFFFF",
        boxShadow: " 2px 2px 4px rgba(0, 0, 0, 0.2)",
        borderRadius: "5px",
        // display: "flex",
        // justifyContent: "space-between",
        // flexDirection: "column",
        ...sx,
      }}
      className={isSelected ? "bg-third" : ""}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DashboardCard;
