import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const CustomizeSideBar = ({ sideBarMenu, sx }) => {
  const { pathname } = useLocation();
  return (
    <div style={sx ? sx : {}} className="cust-sidepbar p-3">
      {Object.keys(sideBarMenu).map((key, index) => (
        <Grid container>
          <Typography
            className="mb-2 mt-2"
            style={{ fontWeight: 500 }}
            component="p"
          >
            {key}
          </Typography>
          {sideBarMenu[key].map((menuItem) => (
            <Grid item xs={12} className="py-2">
              <Link
                to={menuItem.path}
                style={{ textDecoration: "none", color: "#616161" }}
              >
                <Typography
                  className={pathname === menuItem.path ? "cust-active" : ""}
                  component="p"
                >
                  {menuItem.name}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  );
};

export default CustomizeSideBar;
