import React, { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useField } from "formik";

const PasswordInput = ({ name, label, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <FormControl
      {...configTextfield}
      variant="outlined"
      fullWidth={true}
      helperText="Incorrect entry."
    >
      <InputLabel htmlFor={`outlined-adornment-${label}`}>{label}</InputLabel>
      <OutlinedInput
        id={`outlined-adornment-${label}`}
        type={showPassword ? "text" : "password"}
        name={name}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff
                  color={mata.touched && mata.error ? "error" : ""}
                />
              ) : (
                <Visibility color={mata.touched && mata.error ? "error" : ""} />
              )}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <FormHelperText>
        {mata.touched && mata.error && mata.error}
      </FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
