/** @format */

import { Grid } from "@mui/material";
import React from "react";
import InputField from "../../../../formsUI/InputField";

const AddContactForm = ({ isCompany }) => {
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={6} className='p-2'>
          <Grid xs={12} className='mt-3'>
            <InputField
              name='first_name'
              label='Name*'
              color='secondary'
              size='small'
            />
          </Grid>

          <Grid xs={12} className='mt-3'>
            <InputField
              name='primary_email'
              label='Email*'
              color='secondary'
              size='small'
            />
          </Grid>
          {!isCompany && (
            <>
              <Grid xs={12} className='mt-3'>
                <InputField
                  name='primary_number'
                  label='Phone Number*'
                  color='secondary'
                  size='small'
                />
              </Grid>
              <Grid xs={12} className='mt-3'>
                <InputField
                  name='title'
                  label='Job Title*'
                  color='secondary'
                  size='small'
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid xs={12} sm={6} className='p-2'>
          <Grid xs={12} className='mt-3'>
            <InputField
              name='last_name'
              label='Last Name*'
              color='secondary'
              size='small'
            />
          </Grid>
          {!isCompany && (
            <>
              <Grid xs={12} className='mt-3'>
                <InputField
                  name='company_name'
                  label='Company Name*'
                  color='secondary'
                  size='small'
                />
              </Grid>
              <Grid xs={12} className='mt-3'>
                <InputField
                  name='whatsapp'
                  label='Work Number*'
                  color='secondary'
                  size='small'
                />
              </Grid>
            </>
          )}

          {isCompany && (
            <Grid xs={12} className='mt-3'>
              <InputField
                name='primary_number'
                label='Phone Number*'
                color='secondary'
                size='small'
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddContactForm;
