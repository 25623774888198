import { Link } from "react-router-dom";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./SideBar.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomListItem = ({ title, subItemList = null, open, setOpen }) => {
  const location = useLocation();

  return (
    <>
      <ListItemButton
        component="li"
        sx={{
          borderRadius: "6px !important",
          marginBottom: "10px",
        }}
        className={open === title ? "active-sidenavbar" : ""}
        onClick={() => setOpen((prev) => (prev === title ? "" : title))}
      >
        <ListItemText primary={title} />
        {subItemList && (
          <ExpandMoreIcon
            style={{
              transform: open === title ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        )}
      </ListItemButton>
      {subItemList && (
        <>
          <Collapse
            in={open === title}
            timeout={(1, 1, 1)}
            unmountOnExit
            component="li"
          >
            <List
              component="div"
              sx={{ ml: 4 }}
              className={`border-start border-3 ps-3 mt-2`}
            >
              {subItemList.map((item, index) => {
                return (
                  <Link
                    to={item.path}
                    key={index}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemButton
                      sx={{
                        backgroundColor: location?.pathname.startsWith(
                          item.path
                        )
                          ? "rgba(0, 0, 0, 0.13) "
                          : "",
                        borderRadius: "6px",
                        paddingY: "3px",
                        color: "#000",
                        fontFamily: "FuturaMedium",
                        marginBottom: "0.3rem",
                      }}
                    >
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </Link>
                );
              })}
            </List>
          </Collapse>
        </>
      )}{" "}
    </>
  );
};

export default CustomListItem;
