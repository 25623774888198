import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  openLeadDialog,
  closeLeadDialog,
  openContactDialog,
  closeContactDialog,
  openAccountDialog,
  closeAccountDialog,
} from "../../../redux/actions/LayoutAction";

import { useCreateNewConatct } from "../../../redux/actions/contactAction";
import { useCreateNewLead } from "../../../redux/actions/leadActions";
import { useCreateNewAccount } from "../../../redux/actions/accountAction";

const useCreateLeadFunctional = () => {
  const dispatch = useDispatch();

  const accountValidation = Yup.object().shape({
    company_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s|0-9|\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    gst_number: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Please Enter valid GST number!"
    ),
    primary_email: Yup.string()
      .email("Please enter a valid email.")
      .required("This field is required"),
    secondary_email: Yup.string()
      .email("Please enter a valid email.")
      .when("primary_email", {
        is: (value) => !!value,
        then: Yup.string().notOneOf(
          [Yup.ref("primary_email"), null],
          "Secondary Email Is Not as Your Primary Email"
        ),
      }),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    fax_number: Yup.string()
      .nullable()
      .matches(
        /^(\+)?(((((\d+)|(\(\d+\))|(\(\d+\s(\d)\)))(\s|-|\d+))+)|((\d+)|(\(\d+\))$)+)+\d$/,
        "Please Enter a valid FAX number"
      ),
    title: Yup.string(),
    source: Yup.string().required("This field is required"),
    industry: Yup.string().required("This is a required field !"),
    tags: Yup.string().nullable(),

    address_line1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s|0-9|\-|_|,]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    city1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    state1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    nationality1: Yup.string().nullable(),
    pin_code1: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed"),
    address_line2: Yup.string().nullable(),
    city2: Yup.string().nullable(),
    state2: Yup.string().nullable(),
    nationality2: Yup.string().nullable(),
    pin_code2: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed")
      .nullable(),

    address_line3: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s|0-9|\-|_|,]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    city3: Yup.string().nullable(),
    state3: Yup.string().nullable(),
    nationality3: Yup.string().nullable(),
    pin_code3: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed")
      .nullable(),

    description: Yup.string().nullable(),
  });

  const validation = Yup.object().shape({
    first_name:Yup.string()
    .required("This field is required")
    .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Alphabet','-','_']"),
    middle_name: Yup.string().matches(
      /^[aA-zZ\s\-|_]+$/,
      "You Can Only Use ['Alphabet','-','_']"
    ),
    last_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Alphabet','-','_']"),
    primary_number: Yup.string().matches(
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
      "Please Enter a valid mobile number"
    ).min(11).required(),
    secondary_number: Yup.string()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please Enter a valid mobile number"
      )
      .when("primary_number", {
        is: (value) => !!value,
        then: Yup.string().notOneOf(
          [Yup.ref("primary_number"), null],
          "secondary number should not be same as primary"
        ),
      }),
    work_number: Yup.string().matches(
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
      "Please Enter a valid mobile number"
    ),

    // company_name: Yup.string()
    //   .required("This field is required")
    //   .matches(/^[aA-zZ\s|0-9|\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    gst_number: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Please Enter valid GST number!"
    ),
    primary_email: Yup.string().email("Please enter a valid email.").required("Primary Email is required field !"),

    secondary_email: Yup.string()
      .email("Please enter a valid email.")
      .when("primary_email", {
        is: (value) => !!value,
        then: Yup.string().notOneOf(
          [Yup.ref("primary_email"), null],
          "Secondary Email Is Not as Your Primary Email"
        ),
      }),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    fax_number: Yup.string()
      .nullable()
      .matches(
        /^(\+)?(((((\d+)|(\(\d+\))|(\(\d+\s(\d)\)))(\s|-|\d+))+)|((\d+)|(\(\d+\))$)+)+\d$/,
        "Please Enter a valid FAX number"
      ),
    title: Yup.string().required("This is a required field !"),
    source: Yup.string(),
    industry: Yup.string().required("This is a required field !"),
    tags: Yup.string().nullable(),

    status: Yup.string(),
    owner: Yup.mixed(),

    skype: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .nullable(),
    facebook: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .nullable(),
    twitter: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .nullable(),
    linkedin: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .nullable(),
    whatsapp: Yup.string()
      .nullable()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Only number is allowed"
      ),

    address_line1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s|0-9|\-|_|,]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    city1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    state1: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    nationality1: Yup.string().nullable(),
    pin_code1: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed"),
    address_line2: Yup.string().nullable(),
    city2: Yup.string().nullable(),
    state2: Yup.string().nullable(),
    nationality2: Yup.string().nullable(),
    pin_code2: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed")
      .nullable(),

    address_line3: Yup.string()
      .nullable()
      .matches(/^[aA-zZ\s|0-9|\-|_|,]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    city3: Yup.string().nullable(),
    state3: Yup.string().nullable(),
    nationality3: Yup.string().nullable(),
    pin_code3: Yup.string("Only [0-9] accepted")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please enter a valid Pin code"
      )
      .length(6, "Maximun 6 digit allowed")
      .nullable(),

    description: Yup.string().nullable(),
  });

  const { mutate: newLeadMutate } = useCreateNewLead();
  const { mutate: newContactMutate } = useCreateNewConatct();
  const { mutate: newAccountMutate } = useCreateNewAccount();

  const handleLeadOpen = () => {
    dispatch(openLeadDialog());
  };

  const handleLeadClose = () => {
    dispatch(closeLeadDialog());
  };

  const handleContactOpen = () => {
    dispatch(openContactDialog());
  };

  const handleContactClose = () => {
    dispatch(closeContactDialog());
  };

  const handleAccountOpen = () => {
    dispatch(openAccountDialog());
  };

  const handleAccountClose = () => {
    dispatch(closeAccountDialog());
  };

  return {
    validation,
    handleLeadOpen,
    handleLeadClose,
    handleContactOpen,
    handleContactClose,
    handleAccountOpen,
    handleAccountClose,
    newLeadMutate,
    newContactMutate,
    newAccountMutate,
    accountValidation,
  };
};

export default useCreateLeadFunctional;
