import React from "react";

import { Divider, Grid } from "@mui/material";

import InputField from "../../../../components/formsUI/InputField";

const SocialInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Social Info</Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name='skype'
          type='url'
          label='Skype'
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name='facebook'
          type='url'
          label='Facebook'
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name='twitter'
          label='Twitter'
          type='url'
          color='secondary'
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name='linkedin'
          type='url'
          label='Linkedin'
          color='secondary'
          size='small'
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputField
          name='whatsapp'
          label='Whatsapp'
          color='secondary'
          size='small'
          type='text'
        />
      </Grid>
    </>
  );
};

export default SocialInfo;
