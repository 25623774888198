import { Divider, Grid } from "@mui/material";
import React from "react";
import InputField from "../../../../../components/formsUI/InputField";

const ProductInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Product Info</Divider>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="product_id"
          label="Product ID*"
          color="secondary"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="variant_id"
          label="Variant ID"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={12} sm={4} md={4}>
        <InputField name="sku" label="SKU" color="secondary" size="small" />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="category"
          label="Category*"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="category_2"
          label="Category*"
          color="secondary"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="supplier_id"
          label="Supplier ID"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="sub_category"
          label="Sub Category*"
          color="secondary"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={6}>
        <InputField
          name="sub_sub_category"
          label="Sub Sub Category"
          color="secondary"
          size="small"
        />
      </Grid> */}
    </>
  );
};

export default ProductInfo;
