import * as Yup from "yup";

const useAddWhatsapp = () => {
  const validation = Yup.object().shape({
    whatsapp: Yup.string()
      .required("Please enter whatsapp number!")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Only number is allowed"
      ),
  });

  return { validation };
};

export default useAddWhatsapp;
