import React from "react";

import { Route, Routes } from "react-router-dom";
import Navbar from "../../../components/Layout/navbar";

import PrivateRoute from "../../../utils/PrivateRoute";

import ContactTable from "../contactTable";
import SingleContact from "../singleContact";

const Contact = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Routes>
        <Route path='/' element={<ContactTable />} />
        <Route path='/:uuid' element={<SingleContact />} />
      </Routes>
    </PrivateRoute>
  );
};

export default Contact;
