import React from "react";

import { Divider, Grid } from "@mui/material";

import { leadStatusData } from "../../../../utils/leadVariables";

import SelectField from "../../../../components/formsUI/SelectField";
import AutoSelectField from "../../../../components/formsUI/AutoComplete";
import useAddUserData from "../../../settings/user/userHooks/useAddUser";
import { useSelector } from "react-redux";

const LeadInfo = () => {
  const { data: userList } = useAddUserData();

  const Options = userList?.data?.map((item) => ({
    uuid: item.uuid,
    label: item.profile.full_name,
  }));

  const { user } = useSelector((state) => state.userStore);
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  return (
    <>
      <Grid item xs={12}>
        <Divider>Lead Info</Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="status"
          label="Status *"
          color="secondary"
          selectValue="name"
          data={leadStatusData}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <InputField
          name="owner"
          label="Owner *"
          color="secondary"
          size="small"
        /> */}

        <AutoSelectField
          name="owner"
          label="Owner*"
          color="secondary"
          size="small"
          multiple={false}
          options={Options ? Options : []}
          getOptionLabel={(options) => options?.label || options || ""}
          // defaultValue={userData?.owner}
        />
      </Grid>
    </>
  );
};

export default LeadInfo;
