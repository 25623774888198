import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import RightClickMenu from "../../../../components/lead/RightClickMenu";
import useCreateProduct from "../newProduct/useCreateProduct";
import useProductDrower from "./useProductDrower";
import ProductInfo from "../newProduct/createComponent/ProductInfo";
import { useProductDelete } from "../../../../redux/actions/productAction";
import SubmitButton from "../../../../components/formsUI/SubmitButton";
import KeyboardBadge from "../../../../components/Layout/KeyboardBadge";
import ProductDetails from "../newProduct/createComponent/ProductDetails";
import ProductPrice from "../newProduct/createComponent/ProductPrice";
import { Edit } from "@mui/icons-material";
import DeleteDialog from "../../../../components/utils/deleteDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const ProductDrower = ({ name, data, mutate, heading }) => {
  const { state, INITIAL_FORM_STATE, toggleDrawer } = useProductDrower(data);
  const { FORM_VALIDATION } = useCreateProduct(null);

  const sm = useMediaQuery("(max-width:600px)");
  const { mutate: deleteProduct } = useProductDelete();
  return (
    <>
      <RightClickMenu name={<div onClick={toggleDrawer}>{name}</div>}>
        <MenuItem
          onClick={() => {
            toggleDrawer();
          }}
        >
          <Edit fontSize="18" className="me-2" />
          Edit Product
        </MenuItem>
        {/* <ConvertToContact data={data} /> */}
        <DeleteDialog
          mutate={deleteProduct}
          uuid={data?.uuid}
          description="!!!  Warning  !!! :-This Product will be deleted"
          element={
            <MenuItem sx={{ fontSize: "13px" }}>
              <DeleteForeverIcon fontSize="18" className="me-2" />
              Delete
            </MenuItem>
          }
        />
      </RightClickMenu>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer}>
        <Box sx={{ width: "80vw" }}>
          <Box
            sx={{ background: "#ededed" }}
            className="border-bottom border-dark p-3 py-4 d-flex justify-content-between align-items-center"
          >
            {heading}
            {!sm && (
              <span className="text-muted ">
                Press <KeyboardBadge ele="Esc" /> to close
              </span>
            )}
          </Box>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            enableReinitialize={true}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values) => {
              mutate(values, { onSuccess: () => toggleDrawer() });
            }}
          >
            <Form>
              <Box className="mt-3 p-3">
                <Grid container>
                  <Grid container spacing={2}>
                    <ProductInfo />
                    <ProductDetails />
                    <ProductPrice />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{ background: "#ededed" }}
                className="border-top border-dark p-3"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={toggleDrawer}
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <SubmitButton
                      variant="containd"
                      type="submit"
                      sx={{
                        background: "#C4C4C4",
                        padding: "4px 10px !important",
                        color: "#000",
                      }}
                      className="text-capitalize"
                    >
                      Update
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Drawer>
    </>
  );
};

export default ProductDrower;
