import { Button, Divider, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import usePass from "../../../pages/profile/singleUserProfile/usePass";
import { useCreatePass } from "../../../redux/actions/userAction";
import PasswordInput from "../../formsUI/PasswordInput";

const   ChangePass = () => {
  const style = {
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };
  const { INITIAL_FORM_STATE, FORM_VALIDATION } = usePass();
  const { mutate } = useCreatePass();
  return (
    <Formik
      initialValues={{
        ...INITIAL_FORM_STATE,
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values, { resetForm }) => {
        mutate(values, { onSuccess: () => resetForm() });
      }}
    >
      {({ submitForm }) => (
        <Form>
          <>
            <Grid item xs={12} className="px-5">
              <Divider>Change Password</Divider>
            </Grid>
            <Grid
              item
              xs={12}
              className="px-3 d-flex flex-column justify-content-center align-items-center  "
            >
              <Grid item sm={12} md={12} xs={12} className="mt-3">
                <PasswordInput
                  name="old_password"
                  label="Old Password"
                  color="secondary"
                  size="small"
                  sx={style}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={12} xs={12} className="mt-3">
                <PasswordInput
                  name="new_password"
                  label="New Password"
                  color="secondary"
                  size="small"
                  sx={style}
                />
              </Grid>
              <Grid item sm={12} md={12} xs={12} className="mt-3">
                <PasswordInput
                  name="new_password2"
                  label="Confirm Password"
                  color="secondary"
                  size="small"
                  sx={style}
                />
              </Grid>
              <Grid
                item
                md={12}
                className="text-capitalize mt-4 d-flex justify-content-center align-items-center"
              >
                <Button
                  onClick={submitForm}
                  className="text-capitalize"
                  variant="contained"
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePass;
