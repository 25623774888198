import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const getSingleDeal = async (args) => {
  return await tenantAPI.get(`/deals/${args}/`);
};

const useGetSingleDeal = (id, options = {}) => {
  return useQuery(["deal", id], () => getSingleDeal(id), options);
};

export default useGetSingleDeal;
