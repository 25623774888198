// Import the necessary functions from the Firebase SDK
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbG6R-VG6Bq_yNxAp1P-lTRT0cTKf2d2A",
  authDomain: "crm-fladdra.firebaseapp.com",
  projectId: "crm-fladdra",
  storageBucket: "crm-fladdra.appspot.com",
  messagingSenderId: "999615421891",
  appId: "1:999615421891:web:095fa9d6ade6680c60f176",
  measurementId: "G-PR0CBX0EQW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

// Request permission to send notifications and get the FCM token
export const requestFcmToken = async () => {
  try {
    // Request permission to show notifications
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.error("Permission for notifications was denied.");
      return null;
    }

    // Get the FCM token
    const fcmToken = await getToken(messaging, {
      vapidKey:
        "BJ8BqOHbXuLkuBniZOMouUVUH1YTCyyZ295AaaYuHvzvLvA-fiDROlNP8mvpg7ZuVoEu3kRNa1ac6GBBtH1gkaM",
    });

    if (fcmToken) {
      console.log("FCM Token received:", fcmToken);
      return fcmToken;
    } else {
      console.error("No registration token available.");
      return null;
    }
  } catch (error) {
    console.error("Error while retrieving the FCM token:", error);
    return null;
  }
};

// Register the service worker (needed for background notifications)
const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      // Register the service worker
      const swRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Service Worker registered with scope:", swRegistration.scope);
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  } else {
    console.error("Service Worker is not supported in this browser.");
  }
};

// Call the registerServiceWorker function on page load
registerServiceWorker();
