/** @format */
import React, { useState } from "react";
import Navbar from "../../components/Layout/navbar";
import CalendarComponent from "../../components/Layout/Calendar/Index";
import Header from "../../components/Layout/Header";
import useTaskData from "../task/taskHooks/useTaskData";
import { toast } from "react-toastify";

const Calendar = () => {
  const [taskData, setTaskData] = useState([]);
  const { isLoading } = useTaskData({
    onSuccess: (data) => {
      setTaskData(data?.data);
    },
  });
  console.log(taskData[0]?.id, "=========>id");

  const data = taskData?.map((task) => ({
    title: task?.task_title || "No Title",
    start: new Date(task?.due_date),
    end: new Date(task?.due_date),
    completed: task?.is_completed || false,
    id: task?.uuid,
    related_links: task?.related_links,
  }));

  return (
    <>
      <Navbar />
      <Header name='Calendar' showSearch={true} />
      <CalendarComponent data={data} />
    </>
  );
};

export default Calendar;
