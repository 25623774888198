import "../newRole/css.css"
import { Switch } from "@mui/material"
import React, { useState } from "react"
import { Grid } from "@mui/material"
import InputField from "../../../components/formsUI/InputField"
import CheckboxTree from "react-checkbox-tree"
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  IndeterminateCheckBox,
  KeyboardArrowDown,
} from "@mui/icons-material"
import { useFormikContext } from "formik"
import { usePermissionsData } from "../rolesHooks/useRolesData"

const RolesForm = () => {
  const [permissions, setPermissions] = useState([])
  const checkIfInTreeData = (data, parent) => {
    return data.findIndex((item) => item.label === parent)
  }
  const { data } = usePermissionsData({
    onSuccess: (data) => {
      let arr = []

      data.data.forEach((item) => {
        let index = checkIfInTreeData(arr, item.model_name)
        if (index === -1) {
          arr.push({
            value: item.model_name,
            label: item.model_name,
            children: [
              {
                value: item.id,
                label: item.name,
              },
            ],
          })
        } else {
          arr[index].children = [
            ...arr[index].children,
            {
              value: item.id,
              label: item.name,
            },
          ]
        }
      })
      setPermissions([{ value: "Select", label: "Select All", children: arr }])
    },
  })

  const { setFieldValue, values } = useFormikContext()
  const [isChecked, setIsChecked] = useState()

  return (
    <>
      <Grid container sx={{ padding: "1rem" }} spacing={2}>
        <Grid item md={6}>
          <InputField id="outlined-error" label="Name" name="name" />
        </Grid>
        <Grid item md={6}>
          <InputField id="fullWidth" label="Description" name="description" />
        </Grid>
        <Grid item md={4}>
          Active
          <Switch
            checked={values?.is_active}
            onClick={(e) => setFieldValue("is_active", e.target.checked)}
          ></Switch>
        </Grid>

        <Grid item xs={8}>
          Permissions
          <CheckboxTree
            icons={{
              check: <CheckBox />,
              uncheck: <CheckBoxOutlineBlank />,
              halfCheck: <IndeterminateCheckBox />,
              expandClose: <ChevronRight />,
              expandOpen: <KeyboardArrowDown />,
              expandAll: <span />,
              collapseAll: <span />,
              parentClose: <span />,
              parentOpen: <span />,
              leaf: <span />,
            }}
            nodes={permissions}
            checked={values?.permissionsList}
            expanded={values?.expanded}
            onCheck={(checked) => {
              setIsChecked(false)
              setFieldValue("permissionsList", checked)
            }}
            onExpand={(expanded) => setFieldValue("expanded", expanded)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default RolesForm
