/** @format */

import React from "react";
import { Box, Typography, Grid, Button, useMediaQuery } from "@mui/material";
import SearchAppBar from "./navbar/Search";
import { MdOutlineFilterAlt } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { BiExport } from "react-icons/bi";

const Header = ({
  name,
  addButton,
  addBtnClick,
  showSearch,
  customFilter,
  exportButton,
  exportOnClick,
}) => {
  const xsMatch = useMediaQuery("(max-width:600px)");
  const matches = useMediaQuery("(max-width:900px)");
  const lgMatch = useMediaQuery("(max-width:1000px)");
  return (
    <>
      <Grid
        container
        display='row'
        alignItems='center'
        justifyContent='space-between'
        className='bg-header'
        sx={{
          borderBottom: "2px solid #ced4da",
          padding: "15px 0",
          // height: "60px",
        }}
      >
        <Grid item xs={12} md={7} className='d-flex align-items-center'>
          <Box>
            <Typography
              variant='h6'
              className='second'
              sx={{
                marginLeft: !matches ? "5rem" : "1rem",
                fontSize: xsMatch ? "1rem" : "1.35rem",
              }}
            >
              {name}
            </Typography>
          </Box>
          {showSearch && (
            <Box className={xsMatch ? "ms-3" : "ms-5"}>
              <SearchAppBar />
            </Box>
          )}
        </Grid>
        <Grid
          item
          pt={{ xs: "10px", md: "0px" }}
          xs={12}
          md={5}
          className={
            xsMatch
              ? "d-flex justify-content-end pe-3"
              : "d-flex justify-content-end pe-5"
          }
        >
          <Grid
            item
            className={
              xsMatch
                ? "d-flex align-items-center me-1"
                : "d-flex align-items-center me-3"
            }
          >
            {exportButton ? (
              <Button
                variant='outlined'
                sx={{
                  border: "2px solid #5582AD",
                  background: "#ffffff",

                  color: "#5582AD",
                  fontSize: lgMatch ? "12px" : "14px",
                  // minWidth: matches ? "0px" : "auto",
                  "&:hover": {
                    background: "#ffffff",
                  },
                }}
                onClick={exportOnClick}
                size='small'
                className={
                  xsMatch
                    ? "d-flex align-items-center justify-content-center text-capitalize me-1"
                    : "d-flex align-items-center justify-content-center text-capitalize me-3"
                }
              >
                <span className='me-2'>
                  <BiExport />
                </span>
                {"Export CSV"}
              </Button>
            ) : null}
            {addButton ? (
              addButton
            ) : (
              <Button
                variant='outlined'
                onClick={addBtnClick}
                sx={{
                  border: "2px solid #5582AD",
                  background: "#ffffff",
                  color: "#5582AD",
                  fontSize: lgMatch ? "12px" : "14px",
                  minWidth: matches ? "0px" : "auto",
                  "&:hover": {
                    background: "#ffffff",
                  },
                }}
                size='small'
                className='d-flex align-items-center justify-content-center text-capitalize px-2'
              >
                {"add"}
                <span>
                  <IoIosAdd />
                </span>
              </Button>
            )}
          </Grid>
          <Grid item className='d-flex align-items-center'>
            {customFilter ? (
              customFilter
            ) : (
              <Button
                variant='outlined'
                sx={{
                  border: "2px solid #5582AD",
                  background: "#ffffff",
                  color: "#5582AD",
                  fontSize: lgMatch ? "12px" : "14px",
                  minWidth: matches ? "0px" : "auto",
                  "&:hover": {
                    background: "#ffffff",
                  },
                }}
                size='small'
                className='d-flex align-items-center justify-content-center text-capitalize'
              >
                <span>
                  <MdOutlineFilterAlt />
                </span>
                {"Filter"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
