import { PlayForWork } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useProductUpdate } from "../../../../redux/actions/productAction";
import ProductDrower from "../productDrower";
import useProductDrower from "../productDrower/useProductDrower";
import useProductData from "../productHooks/useAddProduct";

const useHomeProduct = () => {
  const [productsData, setProductsData] = useState([]);
  const { isLoading, data } = useProductData({
    onSuccess: (data) => setProductsData(data?.data),
  });

  const { toggleDrawer } = useProductDrower();

  const { mutate } = useProductUpdate();

  const createColumnSchema = (data) => {
    return (
      data &&
      Object.keys(data)
        .map((key, pos) =>
          [
            "id",
            "uuid",
            "is_active",
            "additional_fields",
            "created_on",
            "updated_on",
            "created_by",
          ].includes(key)
            ? null
            : {
                field: key,
                headerName: key.replace("_", " "),
                minWidth: 150,
                hide: pos > 10 ? true : false,
                headerClassName: "text-capitalize",
                cellClassName: "p-0",
                flex: 1,
                renderCell: (params) => {
                  return params.field === "title" ? (
                    <ProductDrower
                      name={
                        <Box
                          onClick={toggleDrawer}
                          className="ps-2 d-flex align-items-center"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            width: "100%",
                            fontSize: 14,
                            height: "100%",
                          }}
                        >
                          <PlayForWork /> {params.value}
                        </Box>
                      }
                      heading="Product Card"
                      data={params.row}
                      mutate={mutate}
                    />
                  ) : (
                    <Box
                      className="ps-3 primary-main text-truncate second"
                      style={{
                        width: "100%",
                        height: "100%",
                        textDecoration: "none",
                      }}
                    >
                      {params.value}
                    </Box>
                  );
                },
              }
        )
        .filter((item) => item !== null)
    );
  };

  return { isLoading, createColumnSchema, data: productsData };
};

export default useHomeProduct;
