/** @format */

import * as Yup from "yup";

const useAddContact = () => {
  const CONTACT_INITIAL_FORM_STATE = [
    {
      first_name: "",
      primary_email: "",
      primary_number: "",
      title: "",
      last_name: "",
      company_name: "",
      whatsapp: "",
      existing_contact: "",
      deal: "",
    },
    {
      contact: "",
      company_name: "",
    },
  ];

  const CONTACT_FORM_VALIDATION = [
    Yup.object().shape({
      first_name: Yup.string().required("This Field is Required"),
      primary_email: Yup.string().email().required("This Field is Required"),
      primary_number: Yup.string().required("This Field is Required"),
      title: Yup.string().required("This Field is Required"),
      last_name: Yup.string().required("This Field is Required"),
      company_name: Yup.string().required("This Field is Required"),
      whatsapp: Yup.string().required("This Field is Required"),
      // existing_contact: Yup.object().required("This Field is Required"),
    }),
    Yup.object().shape({
      contact: Yup.object().required("This field is required!"),
    }),
  ];

  return {
    CONTACT_INITIAL_FORM_STATE,
    CONTACT_FORM_VALIDATION,
  };
};

export default useAddContact;
