import { IconButton, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useRef } from "react";

const FileInput = ({ icon, name }) => {
  const fileRef = useRef(null);

  const { setFieldValue } = useFormikContext();

  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <>
      <input
        ref={fileRef}
        hidden
        multiple
        name={name}
        type='file'
        onChange={(e) => setFieldValue(e.target.name, e.target.files)}
      />
      <IconButton
        onClick={() => {
          fileRef.current.click();
        }}
        style={{ padding: "2px 2px 2px 2px", marginLeft: "8px" }}
      >
        {icon}
      </IconButton>
      {configTextfield?.error && configTextfield?.helperText && (
        <Typography color='error'>{configTextfield?.helperText}</Typography>
      )}
    </>
  );
};

export default FileInput;
