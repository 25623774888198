/** @format */

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridOverlayComp from "./GridOverlay";
import CustomLoadingOverlay from "./LoadingOverlay";
import CustomToolbar from "./CustomToolbar";
import {
  onPageChange,
  onSizeChange,
} from "../../../redux/actions/LayoutAction";
import { useColumnPreference } from "../../../redux/actions/columnActions";

const escapeRegExp = (value) => {
  return value && value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    "& .MuiTablePagination-root": {
      marginBottom: "3rem",
    },
    "& .MuiTablePagination-selectLabel,& .MuiTablePagination-displayedRows,": {
      marginBottom: 0,
      color: "#495057",
    },
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiCheckbox-root.Mui-checked": {
      color: "#000",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E1F3FA",
      border: "none !important",
      color: "#495057",
      "& .MuiDataGrid-columnSeparator": {
        color: "#4C6371",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiButton-root": {
      color: "#495057",
      border: "2px solid #60859d",
      padding: "5px 10px",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)!important",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
  },
}));

const Table = forwardRef(
  (
    {
      data,
      loading,
      apiRef,
      columns,
      hideFooter,
      checkboxSelection = false,
      title = "",
      ...otherProps
    },
    ref
  ) => {
    const { search } = useSelector((state) => state.commonStore);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState(data || []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const { rowCount, size } = useSelector((store) => store.layoutStore);
    const dispatch = useDispatch();
    const clasess = useStyles();
    const { mutate: saveColumnPreference } = useColumnPreference();
    const url = window.location.href;
    const pathname = new URL(url).pathname;
    const tableName = pathname.split("/")[1];
    console.log(title, "=============> Table Title");

    const handleColumnVisibilityChange = (model) => {
      setColumnVisibilityModel(model);

      const payload = {
        table_name: tableName,
        page_url: window.location.href,
        data: model,
      };

      let colpref = JSON.parse(localStorage.getItem("columnVisibility")) || [];

      console.log(colpref, "<====Existing columnVisibility");

      colpref.push(payload);

      localStorage.setItem("columnVisibility", JSON.stringify(colpref));

      saveColumnPreference(payload);
    };
    useEffect(() => {
      const savedColumnVisibility =
        JSON.parse(localStorage.getItem("columnVisibility")) || [];
      console.log(savedColumnVisibility, "=============> row Data");

      const columnDataOnly = savedColumnVisibility
        .filter((item) => item.table_name === title)
        .map((item) => item.data);
      const combinedData = columnDataOnly.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      console.log(combinedData, "=============> Filtered Data");
      setColumnVisibilityModel(combinedData);
    }, [title]);

    const formatDate = (value) => {
      if (!value) return "";

      const date = new Date(value);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const formattedColumns = columns.map((col) => {
      if (
        [
          "created_at",
          "updated_at",
          "expected_closing_date",
          "remind_at",
        ].includes(col.field)
      ) {
        return {
          ...col,
          renderCell: (params) => formatDate(params.value),
        };
      }

      if (col.field === "owner") {
        return {
          ...col,
          renderCell: (params) => {
            const owner = params.value;
            return owner || "No Owner";
          },
        };
      }
      if (col.field === "assigned_to") {
        return {
          ...col,
          renderCell: (params) => {
            const assigned_to = params.row.assigned_to?.email;

            return assigned_to || "";
          },
        };
      }
      if (col.field === "is_completed") {
        return {
          ...col,
          renderCell: (params) => {
            const is_completed = params.row.is_completed;

            return is_completed ? <p>YES</p> : <p>NO</p>;
          },
        };
      }

      return col;
    });

    useEffect(() => {
      const searchRegex = new RegExp(escapeRegExp(search), "gi");
      const filteredRows = data.filter((row) =>
        Object.keys(row).some((field) =>
          searchRegex.test(row[field] ? row[field].toString() : "")
        )
      );
      setRows(filteredRows);
    }, [search, data]);

    useEffect(() => {
      if (data) {
        setRows(data);
      }
    }, [data]);

    // Expose export function to parent component
    useImperativeHandle(ref, () => ({
      exportCSV: () => {
        const headers = columns.map((col) => col.headerName).join(",");

        const csvRows = rows.map((row) => {
          return columns
            .map((col) => {
              const value = row[col.field];
              return value !== null && value !== undefined
                ? `"${value}"`
                : '""';
            })
            .join(",");
        });

        const csvContent = [headers, ...csvRows].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "data-export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    }));

    return (
      <div className='table-container'>
        <div
          style={{
            height: !rows?.length ? "450px" : "auto",
            width: "100%",
          }}
          className='mt-3 table-wrapper'
        >
          <DataGrid
            apiRef={apiRef}
            className={`${clasess.root}`}
            {...otherProps}
            onPageSizeChange={(newPageSize) =>
              dispatch(onSizeChange(newPageSize))
            }
            onPageChange={(newPage) => dispatch(onPageChange(newPage))}
            rowsPerPageOptions={[20, 50, 100, 150].filter(
              (size) => rowCount >= size
            )}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: GridOverlayComp,
              Toolbar: CustomToolbar,
            }}
            rowCount={rowCount}
            paginationMode='server'
            getRowId={(row) => row?.id || row?.uuid}
            columns={formattedColumns} // Use formatted columns
            rows={rows}
            pageSize={size}
            checkboxSelection={checkboxSelection}
            loading={loading}
            rowHeight={40}
            pagination
            hideFooter={hideFooter}
            autoHeight={rows.length !== 0 ? true : false}
            sx={{ fontSize: "13px" }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
          />
        </div>
      </div>
    );
  }
);

export default Table;
