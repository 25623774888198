import React, { useState } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MdOutlineAttachment } from "react-icons/md";
import { Form, Formik } from "formik";
import useAddNote from "./useAddNote";
import FileInput from "../../../formsUI/FileInput";

import getDomain from "../../../../utils/getDomain";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import {
  useUpdateNotes,
  useCreateNewNotes,
  useDeleteAttachment,
} from "../../../../redux/actions/notesAction";

const useStyle = makeStyles({
  root: {
    margin: "0px !important",
    "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px !important",
    },
    "& .css-o4b71y-MuiAccordionSummary-content": {
      margin: "0px !important",
    },
  },
  modifiedTextarea: {
    outline: "none !important",
  },
});

const AddNote = ({ isUpdate, row, setEdit, forDeal }) => {
  const [expanded, setExpanded] = useState(isUpdate ? true : false);
  const { defaultState, validation } = useAddNote();
  const { mutate } = useCreateNewNotes(forDeal);
  const { mutate: updateMutate } = useUpdateNotes(forDeal);
  const { mutate: deleteAttchment } = useDeleteAttachment(forDeal);
  const classes = useStyle();
  const resetform = (resetForm) => {
    setExpanded(false);
    resetForm();
  };

  const onDelete = (value, values, setFieldValue) => {
    setFieldValue(
      "attachments",
      [...values?.attachments].filter((_, i) => i !== value)
    );
    toast.dismiss();
    toast.success("Attachment deleted successfully");
  };

  return (
    <Formik
      initialValues={row ? { ...row, attachments: "" } : defaultState}
      validationSchema={validation}
      enableReinitialize={isUpdate}
      onSubmit={(values, { resetForm }) => {
        if (isUpdate) {
          updateMutate(values);
          setEdit(null);
        } else {
          mutate(values, {
            onSuccess: () => {
              setExpanded(false);
              resetForm();
            },
          });
        }
      }}
    >
      {({ values, setFieldValue, errors, resetForm }) => (
        <Form>
          <Accordion
            expanded={expanded}
            onChange={() => {
              setExpanded(true);
            }}
            style={{
              padding: "5px",
              marginBottom: isUpdate ? "20px" : "",
              border: "0.5px solid #313131",
              boxShadow: "0px 2px 4px rgb(171 190 209 / 60%)",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{
                display: "block",
                minHeight: "unset",
                padding: "0px",
                backgroundColor: "white",
              }}
              className={classes.root}
            >
              <div style={{ width: "100%" }} className="d-flex flex-column">
                <textarea
                  placeholder="Add a note"
                  className={`${classes.modifiedTextarea} p-2`}
                  value={values.text}
                  onChange={(e) => setFieldValue("text", e.target.value)}
                  style={{
                    resize: "none",
                    height: expanded ? "65px" : "40px",
                    backgroundColor: "white",
                    border: "none",
                    width: "100%",
                    transition: "198ms",
                  }}
                />
                {errors.text && (
                  <Typography color="error" className="ps-2">
                    {errors.text}
                  </Typography>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "9px 0px 4px 0px",
                borderTop: "1px solid #D0D5DD",
              }}
            >
              <Grid Container className="d-flex justify-content-between">
                <Grid item xs={9} display="flex" alignItems="center">
                  <div className="me-2">
                    <FileInput
                      name="attachments"
                      icon={<MdOutlineAttachment />}
                    />
                  </div>

                  <div>
                    {Object.values(values?.attachments).map((file, index) => (
                      <Chip
                        key={file.name}
                        label={file.name}
                        size="medium"
                        className="me-2 mt-3 fs-7"
                        onDelete={() => onDelete(index, values, setFieldValue)}
                        deleteIcon={<MdDelete color="rgb(97, 97, 97)" />}
                      />
                    ))}
                    {isUpdate &&
                      values?.files?.map((item) => (
                        <Chip
                          key={item.id}
                          onDelete={() => deleteAttchment(item?.id)}
                          deleteIcon={<MdDelete color="rgb(97, 97, 97)" />}
                          label={
                            <a
                              href={`${
                                process.env.REACT_APP_API_PROTOCOL
                              }${getDomain()}${"."}${
                                process.env.REACT_APP_API_URL
                              }${item.file}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#616161" }}
                            >
                              {item.file.split("/media/notes/")[1]}
                            </a>
                          }
                          size="medium"
                          className="me-2 mt-3 fs-7 text-decoration-underline "
                          clickable
                        />
                      ))}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      isUpdate ? setEdit(null) : resetform(resetForm)
                    }
                    style={{
                      paddingInline: "15px",
                      paddingBlock: "0px",
                      marginRight: "20px",
                    }}
                  >
                    {isUpdate ? "Cancel" : "Reset"}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      paddingInline: "15px",
                      paddingBlock: "0px",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};

export default AddNote;
