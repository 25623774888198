/** @format */

import * as Yup from "yup";

const useAddTask = () => {
  const TASK_INITIAL_FORM_STATE = {
    task_title: "",
    related_links: "",
    related_to: "",
    remind_at: Date.now(),
    due_date: Date.now(),
    assigned_to: "",
    task_priority: "",
    description: "",
  };

  const TASK_FORM_VALIDATION = Yup.object().shape({
    task_title: Yup.string().required("Task Title is Required"),
    // related_links: Yup.string()
    //   .matches(
    //     /((((https?)?:\/\/)(www\.)?|www\.))([a-z0-9.]+)(\.[a-z]{2,4})(\.[a-z]{1,2})?([^?\s]+(\?((\w+)(=[^&\s]+)?&?)+)?)?/,
    //     "Please enter Valid Link"
    //   )
    //   .required("Link is required"),
    related_to: Yup.string(),
    remind_at: Yup.date().required("Reminder is Required"),
    due_date: Yup.date().required(),
    task_priority: Yup.mixed().required(
      "Select a Task Priority it is Required"
    ),
    assigned_to: Yup.mixed().required("Select a Assigned To it is Required"),
    description: Yup.string(),
  });

  return {
    TASK_INITIAL_FORM_STATE,
    TASK_FORM_VALIDATION,
  };
};

export default useAddTask;
