import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BiRupee } from "react-icons/bi";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateDeal } from "../../../redux/actions/dealAction";

const DealDetail = () => {
  const { deal } = useSelector((state) => state.dealStore);
  const [isEdit, setIsEdit] = useState(false);
  const [proposedValue, setProposedValue] = useState(0);
  const params = useParams();
  const { mutate } = useUpdateDeal(params.uuid);

  useEffect(() => {
    if (deal) {
      setProposedValue(deal?.data?.proposed_value);
    }
  }, [deal]);

  const onChange = () => {
    setIsEdit(true);
  };

  const handleChange = (e) => {
    setProposedValue(+e.target.value);
  };
  const handleUpdate = () => {
    if (deal?.data?.proposed_value !== proposedValue && proposedValue !== "") {
      mutate({ name: deal?.data?.name, proposed_value: proposedValue });
    }
    setIsEdit(false);
  };
  return (
    <Grid
      container
      className="py-2"
      sx={{
        borderBottom: "2px solid #d1cac4",
        // padding: 1,
      }}
      display="grid"
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12} alignItems="center">
        <Grid item xs={12} className="d-flex">
          <Grid item xs={6}>
            <Tooltip title="Double Click to Edit" arrow position="bottom">
              <Box className="d-flex flex-column align-items-start">
                {isEdit ? (
                  <TextField
                    size="small"
                    label="Proposed Value"
                    className="mt-2"
                    value={proposedValue}
                    onBlur={() => handleUpdate()}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ fontWeight: 400 }}>
                            <BiRupee />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <>
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#616161",
                      }}
                      component="p"
                    >
                      Proposed Value
                    </Typography>
                    <Typography
                      style={{
                        color: "#313131",
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                      component="p"
                    >
                      <span style={{ fontWeight: 400 }}>
                        <BiRupee />
                      </span>{" "}
                      <span onDoubleClick={onChange}>
                        {deal?.data?.proposed_value}
                      </span>
                    </Typography>
                  </>
                )}
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Box className="d-flex flex-column align-items-start">
              <Typography
                style={{ fontSize: "12px", fontWeight: 500, color: "#616161" }}
                component="p"
              >
                Deal Amount
              </Typography>
              <Typography
                style={{ color: "#313131", fontWeight: 500, fontSize: "20px" }}
                component="p"
                // contenteditable="true"
              >
                <span style={{ fontWeight: 400 }}>
                  <BiRupee />
                </span>{" "}
                {deal?.data?.valuation}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className="mt-3"
          display={"flex"}
          justifyContent="space-between"
        >
          <Typography
            variant="p"
            fontSize="12px"
            style={{ color: "#616161", fontWeight: 500 }}
          >
            Company
          </Typography>
          <Typography
            variant="p"
            fontSize="12px"
            sx={{ fontWeight: 500, color: "#919191" }}
          >
            {deal?.data?.contact_list[0]?.contact?.company?.company}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealDetail;
