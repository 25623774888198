import { useMutation, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tenantAPI } from "../../config/api";
import errorHandle from "../../utils/errorHandle";

export const useCreateNewNotes = (forDeal) => {
  const queryClient = useQueryClient();
  const param = useParams();
  const { pathname } = useLocation();
  const refetch = pathname.split("/");
  const createNewNote = async (data) => {
    const fd = new FormData();
    fd.append(pathname.startsWith("/deals") ? "deal" : "contact", param.uuid);
    fd.append("text", data.text);
    Object.values(data.attachments).forEach((item) => {
      fd.append("attachments", item);
    });
    return await tenantAPI.post(`/tportal/note/0/`, fd);
  };
  const mutation = useMutation(createNewNote, {
    onSuccess: async () => {
      toast.success("Note Created Successfully.");
      queryClient.refetchQueries([forDeal ? "deal" : "contact", refetch[2]], {
        exact: true,
      });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return mutation;
};

export const useUpdateNotes = (forDeal) => {
  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const refetch = pathname.split("/");

  const updateNote = async (data) => {
    const fd = new FormData();
    fd.append("text", data.text);
    fd.append("note", data.uuid);
    console.log(data, "ayttt");
    if (data.attachments) {
      Object.values(data.attachments).forEach((item) => {
        fd.append("attachments", item);
      });
      await tenantAPI.post(`/tportal/note-attachment/0/`, fd);
    }
    return await tenantAPI.put(`/tportal/note/${data.uuid}/`, fd);
  };

  const mutation = useMutation(updateNote, {
    onSuccess: async () => {
      toast.success("Note updated Successfully.");
      queryClient.refetchQueries([forDeal ? "deal" : "contact", refetch[2]], {
        exact: true,
      });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return mutation;
};

export const useDeleteNote = (forDeal) => {
  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const refetch = pathname.split("/");

  const deleteNote = async (uuid) => {
    return await tenantAPI.delete(`/tportal/note/${uuid}/`);
  };

  const mutation = useMutation(deleteNote, {
    onSuccess: () => {
      toast.success("Note deleted successfully.");
      queryClient.refetchQueries([forDeal ? "deal" : "contact", refetch[2]], {
        exact: true,
      });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return mutation;
};

export const useDeleteAttachment = (forDeal) => {
  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const refetch = pathname.split("/");

  const deleteAttachment = async (uuid) => {
    return await tenantAPI.delete(`/tportal/note-attachment/${uuid}/`);
  };

  const mutation = useMutation(deleteAttachment, {
    onSuccess: () => {
      toast.success("Attchment deleted successfully.");
      queryClient.refetchQueries([forDeal ? "deal" : "contact", refetch[2]], {
        exact: true,
      });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return mutation;
};
