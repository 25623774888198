import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";

// const data = {
//   id: "61ab5856cb5ec9ca3b46c6b4",
//   elementName: "sipwise_new_website",
//   category: "ALERT_UPDATE",
//   hsm: null,
//   hsmOriginal: null,
//   customParams: [
//     {
//       paramName: "name",
//       paramValue: "Tanmay Shinde",
//     },
//     {
//       paramName: "company",
//       paramValue: "Fladdra",
//     },
//     {
//       paramName: "greeting",
//       paramValue: "Hello",
//     },
//     {
//       paramName: "bye",
//       paramValue: "bye bye",
//     },
//   ],
//   status: "APPROVED",
//   language: {
//     key: "English (US)",
//     value: "en_US",
//     text: "English (US)",
//   },
//   lastModified: "2021-12-07T00:00:00Z",
//   type: "template",
//   header: {
//     type: 2,
//     typeString: "image",
//     text: null,
//     link: "",
//     mediaHeaderId: "3d14519e-8364-49b1-a70f-1f8d7be0b8c6",
//     mediaFromPC:
//       "Sipwise_Beverages_New_Range_ALL-b8e87b0d-8f30-459f-98ad-97dfca29bf07.jpg",
//     headerOriginaSipwise_Beverages_New_Range_ALLl: null,
//     headerParamMapping: null,
//   },
//   body: "Hello {{1}},\n\nWe meet again! {{2}} It’s been a while since you have shopped with us. We understand, times are tough and everyone’s busy trying to get back on their feet. But we are happy to let you know that our website is completely fresh and has a wide range of new products that you can choose from. As always, all our ingredients like premium teas, herbs and plant extracts are sourced from nature to make these handcrafted beverages with care.\n\nAnd we have listened to each one of you and made all of our drinks sugar free! Yes, you heard that right. All our products have zero added sugar and hundred percent compassion. Our products are vegan certified by the Vegan Society of UK.\n\nWe can’t wait to have you back. Hop on and hop in to pick a beverage that suits your needs.\n\n-Team Sipwise.",
//   // bodyOriginal:
//   //   "Hello sjdjs sdjsdj {{name}},\n\nWe meet again! {{company}} It’s been a while since you have shopped with us. We understand, times are tough and everyone’s busy trying to get back on their feet. But we are happy to let you know that our website is completely fresh and has a wide range of new products that you can choose from. As always, all our ingredients like premium teas, herbs and plant extracts are sourced from nature to make these handcrafted beverages with care.\n\nAnd we have listened to each one of you and made all of our drinks sugar free! Yes, you heard that right. All our products have zero added sugar and hundred percent compassion. Our products are vegan certified by the Vegan Society of UK.\n\nWe can’t wait to have you back. Hop on and hop in to pick a beverage that suits your needs.\n\n-Team Sipwise.",
//   bodyOriginal:
//     "{{greeting}} Hello sjdjs sdjsdj {{name}},\n\nWe meet again! {{company}} It’s been a while since you have shopped with us. We understand, times are tough and everyone’s busy trying to get back on their feet. But we are happy to let you know that our website is completely fresh and has a wide range of new products that you can choose from. As always, all our ingredients like premium teas, herbs and plant extracts are sourced from nature to make these handcrafted beverages with care.\n\nAnd we have listened to each one of you and made all of our drinks sugar free! Yes, you heard that right. All our products have zero added sugar and hundred percent compassion. Our products are vegan certified by the Vegan Society of UK.\n\nWe can’t wait to have you back. Hop on and hop in to pick a beverage that suits your needs.\n\n-Team Sipwise.{{bye}}",
//   footer: "Sipwise Beverages - Inspired By Nature, Crafted By Science",
//   buttons: [
//     {
//       type: "url",
//       parameter: {
//         text: "www.sipwise.co.in/",
//         phoneNumber: "",
//         url: "https://sipwise.co.in/",
//         urlOriginal: null,
//         urlType: "static",
//         buttonParamMapping: null,
//       },
//     },
//   ],
//   buttonsType: "call_to_action",
// };

// const data = {
//   body: "Dear {{1}},\nThank you for your time. ",
//   bodyOriginal: "Dear {{name}},\nThank you for your time. ",
//   buttons: null,
//   buttonsType: "none",
//   category: "ACCOUNT_UPDATE",
//   customParams: [
//     {
//       paramName: "name",
//       paramValuse: "Nhi",
//     },
//   ],
//   elementName: "nhitest",
//   footer: null,
//   header: null,
//   hsm: null,
//   hsmOriginal: null,
//   id: "62f600551567242205d2bb53",
// };

const SingleTemplate = ({ message, setMessage, template, children }) => {
  const [parameters, setParameters] = useState([]);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (template) {
      setData(template);
      setParameters(template.customParams);
    }
  }, [template]);

  const bodyBreakup = useMemo(
    () => (data?.bodyOriginal ? data.bodyOriginal.split(/[{{}}]+/) : []),
    [data?.bodyOriginal]
  );

  console.log(parameters);

  const makeMessage = () => {
    var messageHTML = document.querySelector(".extract-message").innerHTML;
    setMessage({
      message: {
        template_name: template.elementName,
        broadcast_name: "string",
        parameters: parameters.map((param) => ({
          name: param.paramName,
          value: param.paramValue,
        })),
      },
      messageHTML: messageHTML.toString(),
    });
    setOpen(false);
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={open}
        aria-labelledby="scroll-Dialog-title"
        aria-describedby="scroll-Dialog-content"
      >
        <DialogTitle
          sx={{
            background: "#ededed",
            position: "sticky",
            top: "0",
            right: "0",
            zIndex: 10,
          }}
          className="border-bottom border-dark shadow-sm"
          id="scroll-Dialog-title"
        >
          Edit Custom Fields
        </DialogTitle>
        <DialogContent>
          <div className="mt-3 pt-2 pb-3 px-2 border-bottom templet-card">
            <Typography fontWeight={600} className="mb-2">
              {data?.elementName}
            </Typography>
            <span className="extract-message">
              {data?.header && data.header.typeString === "image" && (
                <div
                  style={{
                    height: "100px",
                    width: "500px",
                    overflowX: "scroll",
                    overflowY: "scroll",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src="https://image.shutterstock.com/image-vector/vector-illustration-sample-red-grunge-600w-2065712915.jpg"
                    alt="Header For Template"
                    width={"-webkit-fill-available"}
                  />
                </div>
              )}
              <Typography fontSize={14} style={{ whiteSpace: "pre-line" }}>
                {/* {data.bodyOriginal} */}
                {bodyBreakup.map((chunk, index) => {
                  if (index % 2 === 0) return chunk;
                  else
                    return (
                      // <TextField
                      //   variant="standard"
                      //   // width="fit-content"
                      //   onChange={(e) => {
                      //     e.target.value
                      //       ? setParameters({
                      //           ...parameters,
                      //           [chunk]: e.target.value,
                      //         })
                      //       : setParameters({
                      //           ...parameters,
                      //           [chunk]: `{{${chunk}}}`,
                      //         });
                      //   }}
                      //   placeholder={parameters[chunk]}
                      // ></TextField>
                      <span
                        // style={{
                        //   color: "blue",
                        // }}
                        className="first"
                      >
                        {
                          parameters?.find((item) => item?.paramName === chunk)
                            ?.paramValue
                        }
                      </span>
                    );
                })}
              </Typography>
              {data?.footer && (
                <Typography color="#8d8d8d" fontSize={12} className="my-2">
                  {data?.footer}
                </Typography>
              )}
              {data?.buttons &&
                data.buttons.map((button) => (
                  <Button
                    size="small"
                    sx={{ fontSize: "12px" }}
                    variant="outlined"
                  >
                    {button.parameter.text}
                  </Button>
                ))}
            </span>
          </div>
          <div className="mt-2">
            {parameters?.map((item) => (
              <div className="my-3">
                <Typography
                  fontSize={14}
                  fontWeight={500}
                >{`Custom Field {{${item.paramName}}}`}</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setParameters((prev) =>
                      prev.map((param) =>
                        param.paramName === item.paramName
                          ? { ...param, paramValue: e.target.value }
                          : param
                      )
                    );
                  }}
                  placeholder={item.paramValue}
                ></TextField>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            background: "#ededed",
          }}
          className="border-top border-dark"
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              size="small"
              variant="contained"
              color="error"
              className="text-capitalize me-3"
              onClick={() => {
                setOpen(false);
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="text-capitalize"
              size="small"
              sx={{
                padding: "4px 10px !important",
              }}
              onClick={() => {
                makeMessage();
              }}
            >
              Continue
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleTemplate;
