/** @format */

import { Grid } from "@mui/material";
import React from "react";
import InputField from "../../../../formsUI/InputField";
import SelectDate from "../../../../formsUI/SelectDate";
import { useSelector } from "react-redux";
import AutoSelectField from "../../../../formsUI/AutoComplete";
import DateAndTImeInput from "../../../../formsUI/DateAndTImeInput";
import useAddUserData from "../../../../../pages/settings/user/userHooks/useAddUser";

const AddTaskForm = () => {
  const { deal } = useSelector((state) => state.dealStore);
  const { data: userList } = useAddUserData();
  console.log(userList.data, "============>UserList");
  const Options = [
    {
      uuid: "LOW",
      label: "LOW",
    },
    {
      uuid: "HIGH",
      label: "HIGH",
    },
  ];
  const OptionsUserList = userList?.data?.map((item) => ({
    uuid: item.uuid,
    label: item.profile.full_name,
  }));

  return (
    <>
      <Grid container>
        <Grid item xs={6} className='p-2'>
          <Grid item xs={12} className='mt-3'>
            <InputField
              name='task_title'
              label='Task Title'
              color='secondary'
              size='small'
            />
          </Grid>

          <Grid item xs={12} className='mt-3'>
            <InputField
              name='related_links'
              label='Related Links'
              color='secondary'
              size='small'
            />
          </Grid>
          <Grid item xs={12} className='mt-3'>
            <InputField
              name='related_to'
              label='Deal Related to'
              color='secondary'
              size='small'
              disabled
              value={deal?.data?.name}
            />
          </Grid>
          <Grid item xs={12} className='mt-4'>
            {/* <InputField
              name="reminder"
              label="Reminder "
              color="secondary"
              size="small"
            /> */}
            <DateAndTImeInput
              name='remind_at'
              label='Reminder '
              color='secondary'
              size='small'
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className='p-2'>
          <Grid item xs={12} className='mt-3'>
            <SelectDate
              type='Date'
              name='due_date'
              className='px-2'
              label='Due Date/time'
            />
          </Grid>

          <Grid item xs={12} className='mt-3'>
            {/* <InputField
              name="task_priority"
              label="Task Prority"
              color="secondary"
              size="small"
            /> */}
            <AutoSelectField
              name='assigned_to'
              label='Assigned To*'
              color='secondary'
              size='small'
              multiple={false}
              options={OptionsUserList ? OptionsUserList : []}
              getOptionLabel={(options) => options?.label || options || ""}
            />
          </Grid>

          <Grid item xs={12} className='mt-3'>
            {/* <InputField
              name="task_priority"
              label="Task Prority"
              color="secondary"
              size="small"
            /> */}
            <AutoSelectField
              name='task_priority'
              label='Task Prority*'
              color='secondary'
              size='small'
              multiple={false}
              options={Options ? Options : []}
              getOptionLabel={(options) => options?.label || options || ""}
            />
          </Grid>

          <Grid item xs={12} className='mt-3'>
            <InputField
              name='description'
              label='Task Description'
              color='secondary'
              size='small'
              maxRows={4}
              minRows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTaskForm;
