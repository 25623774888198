/** @format */

import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "../../../components/Layout/Header";
import PrivateRoute from "../../../utils/PrivateRoute";
import Container from "../../../components/Layout/Container";
import CurrMonthInfo from "../components/CurrMonthInfo";
import HotDeals from "../components/HotDeals";
import NewLeads from "../components/NewLeads";
import YearlySalesReport from "../components/YearlySalesReport";
import ClosingDealsMonth from "../components/ClosingDealsMonth";
import DealLostReason from "../components/DealLostReason";
import DealStageReport from "../components/DealStageReport";
import LeadsToDealsReport from "../components/LeadsToDealsReport";
import Navbar from "../../../components/Layout/navbar";
import LeadStatusReport from "../components/LeadStatusReport";
import { toast } from "react-toastify";
import { requestFcmToken } from "../../../constants/firebase";

const useStyles = makeStyles(() => ({
  root: {
    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
    "&.css-15drpbt-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderWidth: 0,
      },
  },
  focused: {},
  notchedOutline: {},
}));

const allTabs = [
  "hot_deals",
  "new_leads",
  "deals_closing_this_month",
  // "yearly_product_sales_report",
  // "deals_lost_reason",
  "deals_stage_report",
  "leads_status_report",
  // "leads_converted_to_deals",
];

const Home = () => {
  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        toast.info("You allowed the notification permission.");
      } else if (permission === "denied") {
        toast.info("You denied the notification permission.");
      }
    } catch (error) {
      console.error(
        "Error during token retrieval or permission request:",
        error
      );
      toast.error("An error occurred while retrieving the notification token.");
    }
  }
  useEffect(() => {
    requestPermission();
  }, []);

  const viewInDefault = JSON.parse(
    localStorage.getItem("dashboard_tabs_in_view")
  );
  const matches = useMediaQuery("(max-width:600px)");

  const [tabsInView, setTabsInView] = useState(
    viewInDefault?.length > 0
      ? viewInDefault
      : viewInDefault === null
      ? allTabs
      : []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    const temp = typeof value === "string" ? value.split(",") : value;
    localStorage.setItem("dashboard_tabs_in_view", JSON.stringify(temp));
    if (value[value.length - 1] === "all") {
      tabsInView.length === allTabs.length
        ? localStorage.setItem("dashboard_tabs_in_view", JSON.stringify([]))
        : localStorage.setItem(
            "dashboard_tabs_in_view",
            JSON.stringify(allTabs)
          );
      setTabsInView(tabsInView.length === allTabs.length ? [] : allTabs);
      return;
    }
    setTabsInView(value);
  };

  const classes = useStyles();
  const isAllSelected =
    allTabs.length > 0 && tabsInView.length === allTabs.length;

  return (
    <PrivateRoute>
      <Navbar />
      <Header
        name='Dashboard'
        showSearch={false}
        addButton={<div />}
        customFilter={
          <Select
            multiple
            value={tabsInView}
            onChange={handleChange}
            MenuProps={{
              style: matches
                ? {
                    left: "-30px",
                    top: "40px",
                  }
                : {},
            }}
            sx={{
              marginLeft: "0.2rem",
            }}
            input={
              <OutlinedInput
                notched={false}
                disableUnderline={true}
                classes={classes}
                style={{
                  width: "100%",
                  height: "2.1rem",
                  background: "white",
                  border: "2px solid #5582AD",
                  color: "#5582AD",
                  fontSize: "14px",
                  fontWeight: "500",
                  "&:hover": {
                    background: "#ffffff",
                  },
                }}
                size='small'
                margin='none'
              />
            }
            displayEmpty
            renderValue={() => "Filter"}
          >
            <MenuItem value='all'>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  tabsInView.length > 0 && tabsInView.length < allTabs.length
                }
                size='small'
                sx={{ padding: "5px 5px" }}
              />
              <ListItemText
                primary='Select All'
                sx={{ fontSize: "12px" }}
                disableTypography
              />
            </MenuItem>
            {allTabs.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={tabsInView.indexOf(name) > -1}
                  size='small'
                  sx={{ padding: "5px 5px" }}
                />
                <ListItemText
                  primary={name.replaceAll("_", " ")}
                  className='text-capitalize'
                  sx={{ fontSize: "12px" }}
                  disableTypography
                />
              </MenuItem>
            ))}
          </Select>
        }
      />
      <Container className=''>
        <Grid container className='pt-3' spacing={2} mb={2}>
          {/* row 1 */}
          <CurrMonthInfo />
          {/* row 2 */}

          {tabsInView.includes("leads_status_report") && (
            <Grid item md={6} lg={3} xs={12}>
              <LeadStatusReport />
            </Grid>
          )}
          {/* row 3 */}

          {/* {tabsInView.includes("yearly_product_sales_report") && (
            <Grid item md={3} xs={12}>
              <YearlySalesReport />
            </Grid>
          )} */}
          {/* {tabsInView.includes("deals_lost_reason") && (
            <Grid item md={3} xs={12}>
              <DealLostReason />
            </Grid>
          )} */}
          {/* row 4 */}
          {tabsInView.includes("deals_stage_report") && (
            <Grid item md={6} lg={3} xs={12}>
              <DealStageReport />
            </Grid>
          )}
          {/* {tabsInView.includes("leads_converted_to_deals") && (
            <Grid item md={4} xs={12}>
              <LeadsToDealsReport />
            </Grid>
          )} */}
          {tabsInView.includes("deals_closing_this_month") && (
            <Grid item md={6} xs={12}>
              <ClosingDealsMonth />
            </Grid>
          )}
          {tabsInView.includes("hot_deals") && (
            <Grid item md={6} xs={12} lg={6}>
              <HotDeals />
            </Grid>
          )}
          {tabsInView.includes("new_leads") && (
            <Grid item md={6} xs={12} lg={6}>
              <NewLeads />
            </Grid>
          )}
        </Grid>
      </Container>
    </PrivateRoute>
  );
};

export default Home;
