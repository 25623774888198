/** @format */

import { Grid } from "@mui/material";
import React from "react";
import DealDetail from "./DealDetail";
import DealDescription from "./DealDescription";
import DealOwner from "./DealOwner";
import DealStatus from "./DealStatus";
import DealAvatar from "./DealAvatar";
const DealCard = () => {
  return (
    <Grid item xs={12} sm={12} lg={2.5}>
      <Grid
        container
        display='grid'
        direction='column'
        style={{ borderRadius: "10px" }}
        className='bg-card p-3'
      >
        <DealAvatar />
        <DealOwner />
        <DealDetail />
        <DealStatus />
        <DealDescription />
      </Grid>
    </Grid>
  );
};

export default DealCard;
