import { Typography } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useDealStage from "../../../pages/deal/dealHooks/useDealStage";
import { useUpdateDeal } from "../../../redux/actions/dealAction";

const Flag = () => {
  const { data } = useDealStage();
  const { deal } = useSelector((state) => state.dealStore);
  const params = useParams();
  const { mutate } = useUpdateDeal(params.uuid);

  const handleClick = (value) => {
    mutate({ name: deal?.data?.name, status: value });
  };

  console.log(data, "-data");

  return (
    <>
      <div className="d-flex align-items-center mb-1">
        {data?.map((item) => (
          <div
            onClick={() => handleClick(item.uuid)}
            className={`pointer-wrapper ${
              item.uuid !== deal?.data?.status.uuid ? "" : "active-pointer"
            } d-flex justify-content-center align-items-center`}
            style={{ backgroundColor: item.colour }}
          >
            <div
              style={{
                cursor: "pointer",
                margin: "5px",
              }}
              className={`pointer ${
                item.uuid !== deal?.data?.status.uuid ? "" : "active-pointer"
              }`}
            >
              <Typography
                className="text-center"
                style={{
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                {item.stage_name}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Flag;
