import React from "react"
import { Grid } from "@mui/material"
import BasicTabs from "../../../components/Layout/tab/MenuTab"
import Field from "./fields"
import LeadsStatus from "./leadsStatus"
import UnqualifiedReason from "./unqualifiedReason"
import SmartListTemplates from "./smartListTemplates"

const data = [
  { label: "Fields", id: 0, component: Field },
  {
    label: "Leads Status",
    id: 1,
    component: LeadsStatus,
  },
  { label: "Unqualified Reason", id: 2, component: UnqualifiedReason },
  {
    label: "Smart List Templates",
    id: 3,
    component: SmartListTemplates,
  },
]

const Leads = () => {
  return (
    <>
      <Grid container className="p-2 px-3">
        {/* <Grid item xs={12}>
                <
                  sx={{ fontWeight: 500, color: "#242424" }}
                  variant="h6"
                >
                  Customize Leads
                </>
              </Grid> */}
        {/* <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "1rem", color: "#889696" }}
                  className="mt-1"
                  component="p"
                >
                  Modify Fields for your leads
                </Typography>
              </Grid> */}
        <Grid item xs={12}>
          <BasicTabs data={data} />
        </Grid>
      </Grid>
    </>
  )
}

export default Leads
