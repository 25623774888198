import React from "react";

import ActivityHOC from "./ActivityHOC";

import { Typography } from "@mui/material";

import { AttachMoney } from "@mui/icons-material";

const DealRevenue = () => {
  return (
    <ActivityHOC
      icon={<AttachMoney />}
      title="Customer Deal Revenue "
      subtitle="1 Active"
    >
      <Typography variant="p" className="second" fontSize="10px">
        No upcoming appointments
      </Typography>
    </ActivityHOC>
  );
};

export default DealRevenue;
