import React from 'react';

import { styled } from '@mui/styles';

const KeyboardBadgeDiv = styled('div')(({ theme }) => ({
  fontSize: '0.8125rem',
  fontWeight: 500,
  color: '#576a77',
  border: '1px solid rgb(175 173 173)',
  backgroundColor: '#f2f2f2',
  padding: '0px 10px',
  borderRadius: 5,
  display: 'inline-block',
  transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  pointerEvents: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const KeyboardBadge = ({ ele }) => {
  return <KeyboardBadgeDiv>{ele}</KeyboardBadgeDiv>;
};

export default KeyboardBadge;
