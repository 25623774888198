import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

const CustomeMenu = ({
  buttonProps,
  menus,
  menuClickHandler,
  renderButton,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    menuClickHandler && menuClickHandler(item);
    setAnchorEl(null);
  };
  return (
    <>
      {renderButton ? (
        <div onClick={handleClick}>{renderButton}</div>
      ) : (
        <Button variant='contained' {...buttonProps} onClick={handleClick}>
          {buttonProps?.text || "Menu"}
        </Button>
      )}

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children && children}
        {!children &&
          menus.map((item) => (
            <MenuItem onClick={() => handleClose(item)}>{item}</MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default CustomeMenu;
