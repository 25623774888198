import axios from "axios";
import { useQuery } from "react-query";
import { tenantAPI } from "../../../../../../config/api";

const useGetMails = (data, options = {}) => {
  return useQuery(
    "previous-mail",
    async () => await tenantAPI.get(`/pportal/email/recv/${data.email}`),
    {
      ...options,
      select: (data) => data.data,
    }
  );
};

export default useGetMails;
