/** @format */

const initialState = {
  users: [],
  user: JSON.parse(localStorage.getItem("userData")),
  error: null,
  userData: localStorage.getItem("userData"),
};

export const userReducer = (state = initialState, action) => {
  const reducer = {
    SET_USER_DATA: () => {
      return {
        ...state,
        users: action.payload,
      };
    },
    SET_SINGLE_USER: () => {
      return {
        ...state,
        user: action.payload,
      };
    },
    LOGOUT: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("columnVisibility");
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    },
    SET_USERDATA: () => {
      return {};
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};
