import React from "react";

import ActivityHOC from "./ActivityHOC";

import { Typography, Stack } from "@mui/material";

import { AttachMoney } from "@mui/icons-material";

const OngoingDeals = () => {
  return (
    <ActivityHOC
      icon={<AttachMoney />}
      title="Ongoing Deals"
      subtitle="1 Active"
    >
      <Stack>
        <Typography variant="p" className="second" fontSize="12px">
          Fladdra Tech
        </Typography>
        <Typography variant="p" className="second" fontSize="10px">
          ITSM sales pipeline
        </Typography>
      </Stack>
    </ActivityHOC>
  );
};

export default OngoingDeals;
