/** @format */

import React from "react";

import Sequences from "./Sequences";
import UpcomingAppointments from "./UpcomingAppointments";
import OngoingDeals from "./OngoingDeals";
import DealRevenue from "./DealRevenue";

import { Grid, Stack } from "@mui/material";

const ContactActivity = () => {
  return (
    <Grid item xs={12} sm={12} lg={3}>
      <Grid container display='grid' direction='column' className='pb-2'>
        <Stack spacing={2}>
          <Sequences />
          <DealRevenue />
          <OngoingDeals />
          <UpcomingAppointments />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactActivity;
