const initialState = {
  pageNo: 1,
  userid: null,
  password: null,
  url: null,
};

export const authReducer = (state = initialState, action) => {
  const reducer = {
    SET_IS_COMPANY: () => {
      return {
        ...state,
        pageNo: action.payload.pageNo,
        userid: action.payload.uuid ? action.payload.uuid : state.uuid,
        password: action.payload.password
          ? action.payload.password
          : state.password,
        url: action.payload?.url,
      };
    },
    
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};
