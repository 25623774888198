import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import { useUserProfileUpdate } from "../../redux/actions/userAction";

const CropEasy = ({ photoURL, setOpenCrop, setPhotoURL }) => {
  const { mutate } = useUserProfileUpdate();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const cropImage = async () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const userData = { ...user, ...user?.profile };
    delete userData["profile"];
    const { file, url } = await getCroppedImg(
      photoURL,
      croppedAreaPixels,
      rotation
    );
    setPhotoURL(url);
    setOpenCrop(false);
    mutate({
      full_name: userData?.full_name,
      phone: userData?.phone,
      job_title: userData?.job_title,
      profile_pic: file,
    });
  };
  return (
    <Dialog open={true}>
      <Grid item xs={8} className='justify-content-center'>
        <DialogContent
          dividers
          sx={{
            background: "#333",
            position: "relative",
            height: 350,
            width: "auto",
            minWidth: { sm: 500 },
          }}
        >
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </DialogContent>
        <DialogActions
          className='d-flex justify-content-center align-items-center'
          style={{ width: "100%", marginLeft: " " }}
          sx={{ flexDirection: "column", mx: 3, my: 2 }}
        >
          <Box sx={{ width: "100%", mb: 1 }}>
            {/*  */}
            <Box>
              <Typography>Zoom: {zoomPercent(zoom)}</Typography>
              <Slider
                valueLabelDisplay='auto'
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            {/*  */}
            <Box>
              <Typography>Rotation: {rotation + "°"}</Typography>
              <Slider
                valueLabelDisplay='auto'
                min={0}
                max={360}
                value={rotation}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Button variant='outlined' onClick={() => setOpenCrop(false)}>
              Cancel
            </Button>
            <Button variant='contained' onClick={() => cropImage()}>
              Crop
            </Button>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
