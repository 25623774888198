import { Typography } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function RevenueFromProduct({ name, revenue, revLastYear, isRevLost, sx }) {
  return (
    <div style={sx}>
      <Typography component="p" fontSize="12px" fontWeight={400}>
        {name}
      </Typography>
      <Typography
        component="p"
        fontSize="14px"
        fontWeight={500}
        style={isRevLost ? { color: "red" } : {}}
      >
        {revenue}{" "}
        <span
          className="second"
          style={{ fontSize: "10px", fontWeight: "400" }}
        >
          Rs
        </span>
      </Typography>
      <Typography conponent="span" fontSize="12px" fontWeight="400">
        {(revLastYear > 0) ^ isRevLost ? (
          <span style={{ color: "#001AFF" }}>
            <ArrowDropUpIcon /> {revLastYear}%
          </span>
        ) : (
          <span style={{ color: "#F53F3F" }}>
            <ArrowDropDownIcon /> {-revLastYear}%
          </span>
        )}{" "}
        <span className="second">Than last year</span>
      </Typography>
    </div>
  );
}

export default RevenueFromProduct;
