/** @format */

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  root: {
    padding: "0px 10px 20px 10px ",
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: "0px 10px",
    },
  },
});

const CustomDialog = ({
  heading,
  mutate,
  isLoading,
  initialValues,
  validationSchema,
  maxWidth,
  button,
  closeButton,
  addButton,
  children,
  styles,
}) => {
  const { root } = useStyle();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <div onClick={handleOpen} style={{ ...styles }}>
        {button}
      </div>

      <Dialog
        open={openDialog}
        fullWidth
        scroll='paper'
        maxWidth={maxWidth}
        aria-labelledby='alert-Dialog-title'
        aria-describedby='alert-Dialog-description'
      >
        <Formik
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            console.log("valuesvaluesvaluesvaluesvalues", values);
            mutate(values, {
              onSuccess: () => {
                handleClose();
                resetForm();
              },
            });
          }}
        >
          {({ submitForm, errors, values }) => (
            <Form>
              <DialogTitle
                style={{ backgroundColor: "#F2F4F7", padding: "13px 22px" }}
                id='alert-Dialog-title'
                className='border-bottom'
              >
                {heading}
              </DialogTitle>
              <DialogContent className={root}>
                <DialogContentText
                  id='alert-Dialog-description'
                  className='mt-1'
                ></DialogContentText>
                {children}
              </DialogContent>
              <DialogActions
                style={{
                  backgroundColor: "#F2F4F7",
                }}
                className='border-top'
              >
                <Button
                  className='text-capitalize'
                  onClick={handleClose}
                  variant='outlined'
                  sx={{ color: "#274C77" }}
                >
                  {closeButton}
                </Button>
                <Button
                  type='submit'
                  autoFocus
                  className='text-capitalize'
                  variant='contained'
                >
                  {isLoading ? (
                    <CircularProgress
                      className='me-2'
                      color='primary'
                      size={20}
                    />
                  ) : (
                    addButton
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CustomDialog;
