export const sourceData = [
  { id: 1, name: "Self" },
  { id: 2, name: "Whatsapp" },
  { id: 3, name: "Instagram" },
  { id: 4, name: "Facebook" },
  { id: 5, name: "Google Ads" },
  { id: 6, name: "IVR" },
  { id: 7, name: "Email" },
  { id: 8, name: "Website" },
  { id: 9, name: "Chatbot" },
];

export const leadStatusData = [
  { id: 1, name: "New" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Un-Qualified" },
  { id: 4, name: "Qualified" },
];

export const titleData = [
  { id: 1, name: "Mr" },
  { id: 2, name: "Mrs" },
  { id: 3, name: "Miss" },
  { id: 4, name: "Mstr" },
  { id: 5, name: "Dr" },
];

export const address = {
  address: {
    name: "address_line1",
    lable: "Address Line",
  },
  city: {
    name: "city1",
    lable: "City",
  },
  state: {
    name: "state1",
    lable: "State",
  },
  nationality: {
    name: "nationality1",
    lable: "Nationality",
  },
  pin_code: {
    name: "pin_code1",
    lable: "Pin Code",
  },
};

export const billing_address = {
  address: {
    name: "address_line2",
    lable: "Address Line",
  },
  city: {
    name: "city2",
    lable: "City",
  },
  state: {
    name: "state2",
    lable: "State",
  },
  nationality: {
    name: "nationality2",
    lable: "Nationality",
  },
  pin_code: {
    name: "pin_code2",
    lable: "Pin Code",
  },
};

export const shipping_address = {
  address: {
    name: "address_line3",
    lable: "Address Line",
  },
  city: {
    name: "city3",
    lable: "City",
  },
  state: {
    name: "state3",
    lable: "State",
  },
  nationality: {
    name: "nationality3",
    lable: "Nationality",
  },
  pin_code: {
    name: "pin_code3",
    lable: "Pin Code",
  },
};
