import React, { useEffect } from "react";

import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";

import { Form, Formik } from "formik";
import InputField from "../../../../components/formsUI/InputField";
import PasswordInput from "../../../../components/formsUI/PasswordInput";
import useLogIn from "./useLogIn";
import AuthWrapper from "../../AuthWrapper";
import { useUserLogin } from "../../../../redux/actions/authAction";
import verifyDomain from "../../../../utils/verifyDomain";

const LogIn = () => {
  const style = {
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };
  const { loginState, loginValidation } = useLogIn();
  const { mutate } = useUserLogin();

  useEffect(() => {
    if (!verifyDomain()) {
      window.location.replace(process.env.REACT_APP_FE_REDIRECT_URL);
    }
  }, []);

  if (!verifyDomain()) {
    return null;
  }

  return (
    <>
      <AuthWrapper>
        <Grid item md={4} className="bg-light p-5">
          <Grid item xs={12}>
            <Typography
              component="h2"
              varient="h1"
              fontSize="22px"
              fontWeight="Medium"
              className="text-center mb-1"
            >
              Log In
            </Typography>
            <LinearProgress
              variant="determinate"
              value={100}
              color="secondary"
            />
          </Grid>

          <Formik
            initialValues={{ ...loginState }}
            validationSchema={loginValidation}
            onSubmit={async (values) => {
              mutate(values);
            }}
          >
            <Form>
              <Grid container spacing={2} className="pt-4">
                <Grid item xs={12}>
                  <InputField
                    name="email"
                    label="Your Email Address"
                    color="secondary"
                    type="email"
                    size="small"
                    sx={style}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    name="password"
                    fullWidth
                    label="Password"
                    color="secondary"
                    sx={style}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className="text-light"
                    sx={{
                      borderRadius: 0,
                      "&:hover": {
                        background: "#576a77e7",
                      },
                    }}
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
              <Divider className="mt-3 mb-3">OR</Divider>
              <Grid item xs={12}>
                <Typography fontSize="12px">
                  Don't have an account?{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.location.replace(
                        process.env.REACT_APP_FE_REDIRECT_URL
                      )
                    }
                    className="text-primary text-decoration-underline"
                  >
                    Create one
                  </span>
                </Typography>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </AuthWrapper>
    </>
  );
};

export default LogIn;
