import { Grid, Input } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useState } from "react";
import useAddProduct from "../../../../../pages/settings/product/productHooks/useAddProduct";
import InputField from "../../../../formsUI/InputField";
import Table from "../../../../Layout/table";
import "./style.css";
import SelectDate from "../../../../formsUI/SelectDate";
import { useSelector } from "react-redux";
import AutoSelectField from "../../../../formsUI/AutoComplete";
const AddQuoteForm = () => {
  const { deal } = useSelector((state) => state.dealStore);

  const [discount, SetDiscount] = useState(10);
  const [tableProduct, setTableProduct] = useState([]);

  useAddProduct({
    select: (data) => {
      return data.data.map((r) => ({
        ...r,
        id: r.uuid,
        quantity: 0,
        total_price: 0,
      }));
    },
    onSuccess: async (data) => {
      let tableData = deal?.data?.product_cart.map((item) => ({
        ...item,
        ...item.product,
        total_price: item?.quantity * item?.product.base_price,
      }));
      await SetDiscount(
        deal?.data?.quote?.discount ? deal?.data?.quote?.discount : 10
      );
      setTableProduct(tableData);
    },
  });
  const { setFieldValue } = useFormikContext();

  const handleDiscount = (e) => {
    SetDiscount(e.target.value);
    setFieldValue("discount", e.target.value);
  };

  const totalProductValue = () => {
    const total = tableProduct?.reduce((prev, current) => {
      return prev + current?.total_price;
    }, 0);

    return total;
  };

  const Options = deal?.data?.contact_list.map((item) => ({
    uuid: item.contact?.uuid,
    label: item.contact?.first_name,
  }));

  const columns = [
    {
      field: "title",
      headerName: "Product Name",
      width: "220",
    },
    {
      field: "product_id",
      headerName: "Product Id",
      width: "220",
    },
    {
      field: "base_price",
      headerName: "Base Price",
      width: "220",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: "220",
      renderCell: (params) => {
        <Input fullWidth={true} name="quantity" id={params.row.uuid} />;
      },
    },

    {
      field: "total_price",
      headerName: "Total Price",
      width: "220",
      renderCell: (params) => {
        <Input
          fullWidth={true}
          name="total_price"
          id={params.row.uuid}
          value={totalProductValue()}
        />;
      },
    },
  ];

  return (
    <>
      <Grid item xs={12} className="d-flex mt-3">
        <Grid item xs={3}>
          <InputField
            name="owner"
            size="small"
            label="Deal Owner"
            disabled
            value={deal?.data?.owner}
          />
        </Grid>
        <Grid item xs={3} className="mx-3">
          <InputField name="quote_title" size="small" label="Quote Name*" />
        </Grid>
        <Grid item xs={3} className="mx-3">
          <SelectDate name="expiry_date" size="small" label="Expiry Date*" />
        </Grid>
        <Grid item xs={3}>
          <AutoSelectField
            name="contact"
            label="Contact*"
            color="secondary"
            size="small"
            multiple={false}
            options={Options ? Options : []}
            getOptionLabel={(options) => options?.label || options || ""}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          className="p-2"
          style={{ width: "100%", height: "auto " }}
          columns={columns}
          hideFooter
          checkboxSelection={false}
          data={tableProduct ? tableProduct : []}
          loading={false}
        />
      </Grid>
      {tableProduct?.length && (
        <Grid xs={12}>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "90%", paddingLeft: "10px" }}>Total</td>
                <td style={{ width: "62px", padding: "0px 30px" }}>
                  {totalProductValue().toFixed(2)}
                </td>
              </tr>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "90%", paddingLeft: "10px" }}>Discount</td>
                <td
                  className="d-flex justify-content-center align-items-center mt-2"
                  style={{
                    padding: "0px 30px",
                    height: "100%",
                    border: "none",
                  }}
                >
                  <Input
                    name="discount"
                    sx={{ height: "100%" }}
                    inputProps={{ style: { textAlign: "right" } }}
                    variant="outlined"
                    onChange={(e) => handleDiscount(e)}
                    value={discount}
                    endAdornment={"%"}
                  />
                </td>
              </tr>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "90%", paddingLeft: "10px" }}>
                  Total Payable
                </td>
                <td
                  name="total_payable"
                  style={{ width: "62px", padding: "0px 30px" }}
                >
                  {(
                    totalProductValue() -
                    (totalProductValue() * +discount) / 100
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )}
      <Grid xs={12} className="mt-4">
        <InputField
          name="terms_and_condition"
          label="Terms & Conditions*"
          color="secondary"
          size="small"
          maxRows={2}
          minRows={2}
          multiline
        />
      </Grid>
    </>
  );
};

export default AddQuoteForm;
