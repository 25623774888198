import { SET_CONTACT_DATA, SET_SINGLE_CONTACT } from "../types/contactTypes";

import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { createAddress } from "./leadActions";
import errorHandle from "../../utils/errorHandle";
import { tenantAPI } from "../../config/api";
import { closeLeadDialog } from "./LayoutAction";
import { useNavigate } from "react-router-dom";

export const setContactData = (data) => (dispatch) => {
  dispatch({ type: SET_CONTACT_DATA, payload: data });
};

export const setSingleContact = (data) => (dispatch) => {
  dispatch({ type: SET_SINGLE_CONTACT, payload: data });
};

export const useConvetToContactUpdate = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (data) => {
      return await tenantAPI.post(`/contacts/0/`, {
        lead_uuid: data.uuid,
        owner: data.owner,
      });
    },

    {
      onSuccess: (data) => {
        navigate(`/contacts/${data?.data?.data?.uuid}`, { replace: true });
        queryClient.refetchQueries(["leads"], { exact: true });
        toast.success(data.data.Success);
      },
      onError: (error) => {
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};

// create new contact manually
export const useCreateNewConatct = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewContact = async (data) => {
    createAddress(data);
    data.owner = data?.owner?.label;
    return await tenantAPI.post(`/contacts/0/`, data);
  };
  const mutation = useMutation(createNewContact, {
    onSuccess: async () => {
      toast.success("Contact created successfully");
      dispatch(closeLeadDialog());
      queryClient.refetchQueries(["contacts"], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

// ###### update Conctact ###########

export const useUpdateContact = (id, successMessage) => {
  const queryClient = useQueryClient();

  const updateContact = async (data) => {
    if (data?.owner) data.owner = data?.owner?.uuid;
    if (data?.company) data.company = data?.company?.uuid;
    createAddress(data);
    return await tenantAPI.put(`/contacts/${id}/`, data);
  };

  const mutation = useMutation(updateContact, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["contact", id], { exact: true });
      toast.success(
        data?.data?.Success || successMessage || "Contact Updated Successfully"
      );
    },
    onError: (error) => {
      if (error.response) {
        errorHandle(error);
      }
    },
  });

  return { mutate: mutation.mutate, isLoading: mutation.isLoading };
};

// Delete Contact

export const useContactDelete = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteContact = async (uuid) => {
    return await tenantAPI.delete(`/contacts/${uuid}/`);
  };

  const mutation = useMutation(deleteContact, {
    onSuccess: () => {
      queryClient.refetchQueries(["contacts"], { exact: true });
      toast.success("Contact deleted successfully.");
      navigate("/contacts", { replace: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};
