import { Edit } from "@mui/icons-material";
import { Button, Drawer, Grid, MenuItem, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import RightClickMenu from "../../../../components/lead/RightClickMenu";
import DeleteDialog from "../../../../components/utils/deleteDialog";
import useUserDrower from "./useUserDrower";
import useCreateUser from "../newUser/useCreateUser";
import UserInfo from "../newUser/createComponent/UserInfo";
import KeyboardBadge from "../../../../components/Layout/KeyboardBadge";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import SubmitButton from "../../../../components/formsUI/SubmitButton";
import { useUserDelete } from "../../../../redux/actions/userAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const UserDrower = ({ name, data, mutate, heading }) => {
  const [state, setState] = useState(false);
  const { INITIAL_FORM_STATE } = useUserDrower(data);
  const { FORM_VALIDATION } = useCreateUser(null);
  const sm = useMediaQuery("(:600px)");
  const { mutate: deleteUser } = useUserDelete();

  const toggleDrawer = () => setState((prev) => !prev);
  return (
    <>
      <RightClickMenu name={<div onClick={toggleDrawer}>{name}</div>}>
        <MenuItem onClick={toggleDrawer}>
          <Edit fontSize="18" className="me-2" />
          Edit User
        </MenuItem>
        <DeleteDialog
          mutate={deleteUser}
          uuid={data?.id}
          description="!!!  Warning  !!! :-This User will be deleted"
          element={
            <MenuItem sx={{ fontSize: "13px" }}>
              <DeleteForeverIcon fontSize="18" className="me-2" />
              Delete
            </MenuItem>
          }
        />
      </RightClickMenu>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer}>
        <Box sx={{ width: "80vw", height: "100%" }}>
          <Box
            sx={{ background: "#ededed" }}
            className="border-bottom border-dark p-3 py-4 d-flex justify-content-between align-items-center"
          >
            {heading}
            {!sm && (
              <span className="text-muted ">
                Press <KeyboardBadge ele="Esc" /> to close
              </span>
            )}
          </Box>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            enableReinitialize={true}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values) => {
              mutate(values, { onSuccess: () => toggleDrawer() });
            }}
          >
            <Form
              style={{ height: "calc(100% - 75px)" }}
              className="d-flex justify-content-between flex-column"
            >
              <Box className="mt-3 p-3">
                <Grid container>
                  <Grid container spacing={2}>
                    <UserInfo />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{ background: "#ededed" }}
                className="border-top border-dark p-3"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={toggleDrawer}
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <SubmitButton
                      variant="containd"
                      type="submit"
                      sx={{
                        background: "#C4C4C4",
                        padding: "4px 10px !important",
                        color: "#000",
                      }}
                      className="text-capitalize"
                    >
                      Update
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Drawer>
    </>
  );
};

export default UserDrower;
