import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Checkboxes from "../../../../components/formsUI/CheckBox";
import SearchAppBar from "../../../../components/Layout/navbar/Search";
import Table from "../../../../components/Layout/table";
import CreateNewProduct from "../newProduct";
import useCreateProduct from "../newProduct/useCreateProduct";
import useHomeProduct from "./useHomeProduct";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";

// const row = [
//   {
//     id: 1,
//     product_id: "101",
//     variant_id: "Jon",
//     sku: "Snow",
//     category: "lorem",
//     sub_category: "Product/Industry: Sales and Marketing Services, Consulting",
//     sub_sub_category: "New",
//     supplier_id: "Christopher Mark",
//     supplier_name: "Self",
//     company_name: "Pune",
//     brand: "67 7654365 874",
//     title: "Manager",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },

//   {
//     id: 2,
//     product_id: "102",
//     variant_id: "Cersei",
//     sku: "Lannister",
//     category: "lorem",
//     sub_category: "Maruti Suzuki",
//     sub_sub_category: "Qualified",
//     supplier_id: "Mark Ron",
//     supplier_name: "Whatsapp",
//     company_name: "Mumbai",
//     brand: "67 7654365 875",
//     title: "CEO",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },

//   {
//     id: 3,
//     product_id: "103",
//     variant_id: "Jaime",
//     sku: "Lannister",
//     category: "lorem",
//     sub_category: "Product/Industry: Mobile Phone, Communication Services",
//     sub_sub_category: "Non-Qualified",
//     supplier_id: "Bruce Lee",
//     supplier_name: "Instagram",
//     company_name: "Sangli",
//     brand: "67 77644365 876",
//     title: "CA",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },

//   {
//     id: 4,
//     product_id: "104",
//     variant_id: "Arya",
//     sku: "Stark",
//     category: "lorem",
//     sub_category: "Fladdra Tech",
//     sub_sub_category: "Qualified",
//     supplier_id: "Craig Khurana",
//     supplier_name: "Facebook",
//     company_name: "Pune",
//     brand: "91 7654365 874",
//     title: "Stock Manager",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },
//   {
//     id: 5,
//     product_id: "105",
//     variant_id: "Daenerys",
//     sku: "Targaryen",
//     category: "lorem",
//     sub_category: "Product/Industry: Children's Clothing, Toys",
//     sub_sub_category: "Lapsed",
//     supplier_id: "Arthur Roman",
//     supplier_name: "IVR",
//     company_name: "Nagpur",
//     brand: "654 7654365 874",
//     title: "CEO",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },
//   {
//     id: 6,
//     product_id: "106",
//     variant_id: "Jhon",
//     sku: "Melisandre",
//     category: "lorem",
//     sub_category:
//       "SwipeWire. Product/Industry: eCommerce, Techny, App Developer",
//     sub_sub_category: "Non-Qualified",
//     supplier_id: "Jaime Nisham",
//     supplier_name: "Google Ads",
//     company_name: "Sangli",
//     brand: "76265 365 874",
//     title: "Site Manager",
//     product_type: "Type",
//     description: "lorem ipsum",
//     price_consumer: "asdf",
//     consumer_discount: "qwert",
//     base_price: "134",
//     effective_mrp: "43",
//     cgst: "qwer",
//     sgst: "qwerwerty",
//     igst: "yuiopig",
//   },
// ];

const Products = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const below777 = useMediaQuery("(max-width:777px)");
  const { isLoading, createColumnSchema, data } = useHomeProduct();
  const tableRef = useGridApiRef();

  useEffect(() => {
    document.title = "All Products | CRM";
  });

  const OnExport = () => {
    tableRef?.current?.exportDataAsCsv();
  };

  const { handleClickOpen } = useCreateProduct(() => {});
  useHotkeys("ctrl+alt+p", (e) => {
    e.preventDefault();
    handleClickOpen();
  });
  return (
    <>
      <CreateNewProduct hideButton={true} matches={matches} />
      <Grid container className='p-2 px-3'>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }} variant='h5'>
            Products
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "1rem" }} className='mt-2' component='p'>
            Manage Products and Product forms
          </Typography>
        </Grid>
        <Grid
          container
          className='d-flex align-items-center justify-content-between mt-3 '>
          <Grid
            item
            xs={12}
            className='d-flex align-items-center justify-content-between'>
            <Grid className='d-flex align-items-center'>
              <Checkboxes />
              <SearchAppBar />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                size='small'
                className='me-2'
                onClick={() => OnExport()}>
                {matches ? <IosShareRoundedIcon /> : "Export to CSV"}
              </Button>
              <Button
                variant='outlined'
                size='small'
                onClick={() => handleClickOpen()}
                className='me-3'
                sx={below777 ? { marginTop: "1rem" } : { marginTop: "0rem" }}>
                {matches ? <AddBoxRoundedIcon /> : "Add Product"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className='px-3'>
        <Table
          className='p-2'
          style={{ width: "100%" }}
          apiRef={tableRef}
          mt={"30px"}
          columns={data && data[0] ? createColumnSchema(data[0]) : []}
          data={data ? data : []}
          loading={isLoading}
        />
      </Grid>
    </>
  );
};

export default Products;
