import { SET_ACCOUNT_DATA, SET_SINGLE_ACCOUNT } from "../types/accountTypes";

import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { createAddress } from "./leadActions";
import errorHandle from "../../utils/errorHandle";
import { tenantAPI } from "../../config/api";
import { closeLeadDialog } from "./LayoutAction";
import { useNavigate } from "react-router-dom";

export const setAccountData = (data) => (dispatch) => {
  dispatch({ type: SET_ACCOUNT_DATA, payload: data });
};

export const setSingleAccount = (data) => (dispatch) => {
  dispatch({ type: SET_SINGLE_ACCOUNT, payload: data });
};

export const useConvetToAccountUpdate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (data) => {
      return await tenantAPI.post(`/companies/0`, {
        lead_uuid: data.uuid,
      });
    },

    {
      onSuccess: (data) => {
        queryClient.refetchQueries(["leads"], { exact: true });
        toast.success(data.data.Success);
      },
      onError: (error) => {
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};

// create new contact manually
export const useCreateNewAccount = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewAccount = async (data) => {
    createAddress(data);
    console.log(data , "<====== data from account")
    data.owner = data?.owner?.label;
    return await tenantAPI.post(`/companies/0`, data);
  };
  const mutation = useMutation(createNewAccount, {
    onSuccess: async () => {
      toast.success("Account Created Successfully.");
      dispatch(closeLeadDialog());
      queryClient.refetchQueries(["accounts"], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

// ###### update Conctact ###########

export const useUpdateAccount = (id, successMessage) => {
  const queryClient = useQueryClient();

  const updateAccount = async (data) => {
    if (data?.owner) data.owner = data?.owner?.label;
    createAddress(data);
    return await tenantAPI.put(`/companies/${id}`, data);
  };

  const mutation = useMutation(updateAccount, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["account", id], { exact: true });
      toast.success(
        data?.data?.Success || successMessage || "Account Updated Successfully"
      );
    },
    onError: (error) => {
      if (error.response) {
        errorHandle(error);
      }
    },
  });

  return { mutate: mutation.mutate, isLoading: mutation.isLoading };
};

// Delete Contact

export const useAccountDelete = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteAccount = async (uuid) => {
    return await tenantAPI.delete(`/companies/${uuid}`);
  };

  const mutation = useMutation(deleteAccount, {
    onSuccess: () => {
      queryClient.refetchQueries(["accounts"], { exact: true });
      toast.success("Account deleted successfully.");
      navigate("/accounts", { replace: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

//######################  Contacts ####################################

export const useCreateNewAccountContact = (uuid, userId) => {
  const queryClient = useQueryClient();

  const createNewContact = async (data) => {
    data["company"] = uuid;
    data["created_by"] = userId;
    data["owner"] = userId;
    console.log(data , "<=====data in accountAction")
    return await tenantAPI.post(`/companies/add-contact/`, data);
  };

  const mutation = useMutation(createNewContact, {
    onSuccess: async () => {
      toast.success("Contact Created Successfully.");
      queryClient.refetchQueries(["account", uuid], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};
