import { SET_LEAD_DATA } from "../types/leadTypes";

import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { closeLeadDialog } from "./LayoutAction";

import errorHandle from "../../utils/errorHandle";
import { tenantAPI } from "../../config/api";

export const createAddress = (data) => {
  data["address"] = [
    data.address_line1,
    data.city1,
    data.state1,
    data.nationality1,
    data.pin_code1,
  ]
    .filter((item) => item)
    .join("^");
  data["billing_address"] = [
    data.address_line2,
    data.city2,
    data.state2,
    data.nationality2,
    data.pin_code2,
  ]
    .filter((item) => item)
    .join("^");
  data["shipping_address"] = [
    data.address_line3,
    data.city3,
    data.state3,
    data.nationality3,
    data.pin_code3,
  ]
    .filter((item) => item)
    .join("^");
};

export const useCreateNewLead = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewLead = async (data) => {
    createAddress(data);
    data.owner = data?.owner?.uuid;
    return await tenantAPI.post(`/leads/0/`, data);
  };

  const mutation = useMutation(createNewLead, {
    onSuccess: (data) => {
      toast.success("Lead Created Successfully.");
      dispatch(closeLeadDialog());
      queryClient.refetchQueries(["leads"], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

export const setLeadData = (data) => (dispatch) => {
  dispatch({ type: SET_LEAD_DATA, payload: data });
};

export const useLeadUpdate = (dontNeedToast) => {
  const queryClient = useQueryClient();

  const updateLead = async (data) => {
    createAddress(data);
    data.owner = data?.owner?.uuid;
    return await tenantAPI.put(`/leads/${data.uuid}/`, data);
  };

  const mutation = useMutation(updateLead, {
    onSuccess: () => {
      queryClient.refetchQueries(["leads"], { exact: true });
      queryClient.refetchQueries(["/leads/bottom_summary/"], { exact: true });
      if (!dontNeedToast) toast.success("Lead updated successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate, isLoading: mutation?.isLoading };
};

export const useLeadDelete = () => {
  const deleteLead = async (uuid) => {
    return await tenantAPI.delete(`/leads/${uuid}/`);
  };
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteLead, {
    onSuccess: () => {
      queryClient.refetchQueries(["leads"], { exact: true });
      toast.success("Lead deleted successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};
