import React from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { Button, Grid, TextField, useMediaQuery } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineLockClosed } from "react-icons/hi";
import { useRef } from "react";
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

const CustomFinal = ({ name, label, showEdit, is_required }) => {
  const matches = useMediaQuery("(max-width:900px)");
  const smMatches = useMediaQuery("(max-width:600px)")
  const ref = useRef();
  const [change, setChange] = useState(true);

  const handleFocus = () => {
    ref.current.children[0].children[0].focus();
    setChange(false);
  };

  return (
    <>
      <Grid
        container
        className='p-2'
        style={{
          border: "1px solid #C2C2C2",
          borderRadius: " 4px",
          backgroundColor: "#FFF",
        }}
      >
        <Grid container item xs={12} 
        // className= 'd-flex'
        >
          <Grid item xs={12} sm={6} md={6}>
            <Grid item xs={12} >
              <CustomField name={name} ref={ref} label={label} />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} md={6} sx={{marginTop:{xs:"1rem",sm:"0rem"}}}
          // className='d-flex justify-content-evenly'
          >
            <Grid item xs={3}>
              <AiOutlineArrowUp className='ms-2' />
            </Grid>
            <Grid item xs={3}>
              <AiOutlineArrowDown className='' />
            </Grid>
            {showEdit && (
              <Grid item xs={5}>
                {change ? (
                  <Button
                    variant='outlined'
                    size='small'
                    className='text-capitalize'
                    onClick={handleFocus}
                  >
                  { smMatches  ?  <EditRoundedIcon/> : "Edit Field" }
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    size='small'
                    className='text-capitalize'
                    onClick={() => setChange(true)}
                  >
                   { smMatches ? <SaveRoundedIcon/> : "Save"} 
                  </Button>
                )}
              </Grid>
            )}
            {!is_required ? (
              <Grid item xs={1}>
                {" "}
                <RiDeleteBin6Line
                  style={{ color: "#FF0000" }}
                  // className='ms-3'
                />
              </Grid>
            ) : (
              <Grid item xs={1}>
                {" "}
                <HiOutlineLockClosed
                  style={{ color: "#98A2B3" }}
                  // className='ms-3'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomFinal;

const useStyles = makeStyles({
  root: {
    "backgroundColor": "#ffffff",
    "borderRadius": "none",
    "borderBottom": "none",
    "fontSize": "1.5rem",
    "& .MuiInput-root::after": {
      borderBottom: "none",
    },

    "& .MuiFilledInput-root::before": {
      display: "none",
    },
    "& .MuiFilledInput-root::after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-input": {
      // padding: "5px 12px",
      height: "18px",
    },
    "& .css-tqypaw-MuiFormHelperText-root.Mui-error": {
      fontSize: "1.3rem",
    },
    "& .css-1isn6k0-MuiInputBase-root-MuiFilledInput-root": {
      fontSize: "1.5rem",
    },
  },
});

const CustomField = React.forwardRef(
  ({ name, className, label, otherProps }, ref) => {
    const [field, meta] = useField(name);
    const { root } = useStyles();
    const configTextfield = {
      ...field,
      ...otherProps,
      fullWidth: true,
      variant: "standard",
      size: "small",
    };
    if (meta && meta.touched && meta.error) {
      configTextfield.error = true;
      configTextfield.helperText = meta.error;
    }
    return (
      <div className={`d-flex ${className}`}>
        <Grid
          item
          xs={3}
          className='d-flex justify-content-center align-items-center'
        >
          <label
            className='d-flex justify-content-center align-items-center text-center'
            style={{
              color: "#484644",
              backgroundColor: "#ffffff",
              fontSize: "11px",
            }}
          >
            {label}
          </label>
        </Grid>
        <TextField
          ref={ref}
          className={root}
          hiddenLabel
          {...configTextfield}
        />
      </div>
    );
  }
);
