import { useSelector } from "react-redux";
import * as Yup from "yup";

const useAddQuoteForm = () => {
  const { deal } = useSelector((state) => state.dealStore);

  const QUOTE_INITIAL_FORM_STATE = {
    quote_title: "",
    terms_and_condition: "",
    expiry_date: Date.now(),
    discount: 15,
    quote_file: "",
    owner: deal?.data?.owner?.uuid,
    contact: "",
  };

  const QUOTE_FORM_VALIDATION = Yup.object().shape({
    quote_title: Yup.string().required("Quote Name is required"),
    terms_and_condition: Yup.string().required("T&C is required"),
    expiry_date: Yup.date().required("Expiry date is required"),
    discount: Yup.number()
      .required("Discount is required")
      .positive()
      .integer(),
    deal: Yup.string(),
    quote_file: Yup.string().nullable(),
    owner: Yup.string(),
    contact: Yup.mixed().required("Contact is required"),
  });
  return {
    QUOTE_INITIAL_FORM_STATE,
    QUOTE_FORM_VALIDATION,
  };
};

export default useAddQuoteForm;
