import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { tenantAPI } from "../../config/api";
import errorHandle from "../../utils/errorHandle";
import { SET_PRODUCT_DATA } from "../types/productTypes";
import { closeProductDailog } from "./LayoutAction";

export const productAction = () => {
  return {};
};

// ######## Create Product ############
export const useCreateNewProduct = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewProduct = async (data) => {
    return await tenantAPI.post(`/products/0/`, data);
  };

  const mutation = useMutation(createNewProduct, {
    onSuccess: () => {
      queryClient.refetchQueries(["getProduct"], { exact: true });
      toast.success("Product Added Successfully");
      dispatch(closeProductDailog());
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

export const setProductData = (data) => (dispatch) => {
  dispatch({ type: SET_PRODUCT_DATA, payload: data });
};

// ######## Product Update ############
export const useProductUpdate = () => {
  const queryClient = useQueryClient();

  const updateProduct = async (data) => {
    return await tenantAPI.put(`/products/${data.id}/`, data);
  };

  const mutation = useMutation(updateProduct, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["Product", { id: variables }], data);
      queryClient.refetchQueries(["getProduct"], { exact: true });
      toast.success("Product updated successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

// ######### Delete Product ########
export const useProductDelete = () => {
  const queryClient = useQueryClient();

  const deleteProduct = async (uuid) => {
    return await tenantAPI.delete(`/products/${uuid}/`);
  };

  const mutation = useMutation(deleteProduct, {
    onSuccess: () => {
      queryClient.refetchQueries(["getProduct"], { exact: true });
      toast.success("Product deleted successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};
