import React from "react";
import { Route, Routes } from "react-router-dom";

import DealPipeline from "../dealPipline";
import PrivateRoute from "../../../utils/PrivateRoute";
import SingleDeal from "../singleDeal";
import Navbar from "../../../components/Layout/navbar";

const Deal = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Routes>
        <Route path='/' element={<DealPipeline />} />
        <Route path='/:uuid' element={<SingleDeal />} />
      </Routes>
    </PrivateRoute>
  );
};

export default Deal;
