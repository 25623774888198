/** @format */

import { useMutation } from "react-query";
import { toast } from "react-toastify";
import errorHandle from "../../utils/errorHandle";
import axios from "axios";

const useSendMessage = () => {
  const sendMessage = async (data) => {
    console.log(data, "-----> data message");
    return await axios.post(
      "https://app-server.wati.io/api/v1/sendTemplateMessage",
      data.message,
      {
        params: {
          whatsappNumber: data.phNum[0],
        },
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYjMzYTA5OC01Y2JlLTQxMDktOWRhYS0yZWQxNDQ5ZDk5MDEiLCJ1bmlxdWVfbmFtZSI6Inlhc2luLmFuc2FyaUBmbGFkZHJhLmNvbSIsIm5hbWVpZCI6Inlhc2luLmFuc2FyaUBmbGFkZHJhLmNvbSIsImVtYWlsIjoieWFzaW4uYW5zYXJpQGZsYWRkcmEuY29tIiwiYXV0aF90aW1lIjoiMDkvMDUvMjAyMiAxMToxNTo0MSIsImRiX25hbWUiOiJ3YXRpX2FwcCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlRSSUFMIiwiZXhwIjoxNjYzMDI3MjAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.zal7pd0QTZTAGVGvHUUa3AQsoe5wPHzsbUpkFTXQYws",
        },
        body: data.message,
      }
    );
  };

  const Mutation = useMutation(sendMessage, {
    onSuccess: () => {
      toast.success("Message Sent");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: Mutation.mutate };
};

export default useSendMessage;
