/** @format */

import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { Suspense, useEffect } from "react";
import Header from "../../../components/Layout/Header";

import Container from "../../../components/Layout/Container";
import PiplineCardHeading from "../../../components/deal/PiplineCardHeading";
import DealPhaseSection from "../../../components/deal/DealPhaseSection";
import useDealData from "../dealHooks/useDealData";
import useConvertToDeal from "../convertToDeal/useConvertToDeal";
import ConvertToDeal from "../convertToDeal";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useGetDealStages from "../dealHooks/useGetDealStages";

const DealPipeline = () => {
  const { data } = useDealData();
  const { data: dealStageData } = useGetDealStages({
    onSuccess: (data) => {
      setStagesInView(
        selectedStage
          ? [selectedStage]
          : viewInDefault
          ? viewInDefault
          : Object.keys(data.StageValuation)
      );
    },
  });

  useEffect(() => {
    document.title = "Deal Pipeline | CRM";
  }, []);
  const matches = useMediaQuery("(max-width:600px)");

  const search = useLocation().search;
  const selectedStage = new URLSearchParams(search).get("select");
  if (selectedStage) {
    window.scrollTo(0, 0);
  }

  const viewInDefault = JSON.parse(localStorage.getItem("deal_stages_in_view"));

  const { handleClickOpen } = useConvertToDeal();

  const allStages = dealStageData?.StageValuation
    ? Object.keys(dealStageData?.StageValuation)
    : [];

  const [stagesInView, setStagesInView] = useState(
    selectedStage ? [selectedStage] : viewInDefault ? viewInDefault : allStages
  );

  const handleChange = (event) => {
    const value = event.target.value;
    const temp = typeof value === "string" ? value.split(",") : value;
    localStorage.setItem("deal_stages_in_view", JSON.stringify(temp));
    if (value[value.length - 1] === "all") {
      stagesInView.length === allStages.length
        ? localStorage.setItem("deal_stages_in_view", JSON.stringify([]))
        : localStorage.setItem(
            "deal_stages_in_view",
            JSON.stringify(allStages)
          );
      setStagesInView(
        stagesInView.length === allStages.length ? [] : allStages
      );
      return;
    }
    setStagesInView(value);
  };

  const isAllSelected =
    allStages.length > 0 && stagesInView.length === stagesInView.length;

  return (
    <>
      <Header
        name='Deals'
        showSearch={true}
        addButton={<ConvertToDeal CreateDeal addButton />}
        addBtnClick={handleClickOpen}
        customFilter={
          <Select
            multiple
            value={stagesInView}
            onChange={handleChange}
            MenuProps={{
              style: matches
                ? {
                    left: "-30px",
                    top: "40px",
                  }
                : {},
            }}
            sx={{
              marginLeft: "0.2rem",
            }}
            input={
              <OutlinedInput
                notched={false}
                disableUnderline={true}
                style={{
                  width: "100%",
                  height: "2.1rem",
                  background: "white",
                  border: "2px solid #5582AD",
                  color: "#5582AD",
                  fontSize: "14px",
                  fontWeight: "500",
                  "&:hover": {
                    background: "#ffffff",
                  },
                }}
                size='small'
                margin='none'
              />
            }
            displayEmpty
            renderValue={() => "Filter"}
          >
            <MenuItem value='all'>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  stagesInView.length > 0 &&
                  stagesInView.length < allStages.length
                }
                size='small'
                sx={{ padding: "5px 5px" }}
              />
              <ListItemText
                primary='Select All'
                sx={{ fontSize: "12px" }}
                disableTypography
              />
            </MenuItem>
            {allStages.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={stagesInView.indexOf(name) > -1}
                  size='small'
                  sx={{ padding: "5px 5px" }}
                />
                <ListItemText
                  primary={name}
                  sx={{ fontSize: "12px" }}
                  disableTypography
                />
              </MenuItem>
            ))}
          </Select>
        }
      />
      {/* <Header
       name="Deals"
       showSearch={true}
       addBtnClick={handleClickOpen}
      /> */}

      <Container
        sx={{
          background: "#fff !important",
          marginLeft: "0px",
          paddingLeft: "15px",
          marginRight: "0px",
          paddingLight: "15px",
          marginTop: 0,
        }}
      >
        <Grid
          item
          xs={12}
          className='d-flex py-3'
          style={{
            overflowX: "auto",
            maxWidth: "99%",
            margin: "auto",
          }}
        >
          {data?.deals?.map(
            (item) =>
              stagesInView.indexOf(item.stage_name) != -1 && (
                <Grid
                  item
                  key={item.uuid}
                  xs={2}
                  className=' mx-1 px-2'
                  sx={{
                    // height: "inherit",
                    borderRadius: "5px",
                    background: "#F2F4F7",
                    minWidth: "260px",
                  }}
                >
                  <PiplineCardHeading
                    item={item}
                    name={item.stage_name}
                    valuation={dealStageData?.StageValuation[item.stage_name]}
                    totalDeals={dealStageData?.TotalDeals}
                    dealsInStage={
                      dealStageData?.StageDealCount[item.stage_name]
                    }
                    color={item.colour}
                  />
                  <div
                    style={{
                      height: "auto",
                      // paddingRight: "5px",
                      marginTop: "10px",
                    }}
                  >
                    {item?.deals_list?.map((item) => (
                      <DealPhaseSection data={item} key={item.uuid} />
                    ))}
                  </div>
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </>
  );
};

export default DealPipeline;
