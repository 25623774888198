import React from "react";

import { useParams } from "react-router-dom";

import { Backdrop, CircularProgress, Grid } from "@mui/material";

import Container from "../../../components/Layout/Container";
import ContactHeader from "../../../components/contact/ContactHeader";
import ContactCard from "../../../components/contact/contactCard";
import ContactHistory from "../../../components/contact/contactHistory";
import ContactActivity from "../../../components/contact/contactActivity";
import useSingleContact from "./useSingleContact";

const SingleContact = () => {
  const params = useParams();
  const { isLoading } = useSingleContact(params.uuid);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <ContactHeader />
          <Container
            sx={{ marginBottom: "15px", height: "calc(100vh - 154px)" }}
          >
            <Grid container>
              <ContactCard />
              <ContactHistory />
              <ContactActivity />
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default SingleContact;
