/** @format */

import { Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import SimpleAccordion from "../../Layout/accordion/Accordion";
import ContactAccordion from "./accordionChildren/contact";
import ProductAccordion from "./accordionChildren/product";
import { useSelector } from "react-redux";
import AddProductForm from "./accordionChildren/product/AddProductForm";
import CustomDialog from "../../Layout/customDialog/CustomDialog";
import AddContactForm from "./accordionChildren/contact/AddContactForm";
import AddQuoteForm from "./accordionChildren/quote/AddQuoteForm";
import AddTaskForm from "./accordionChildren/task/AddTaskForm";
import {
  useCreateTask,
  useCreateQuote,
  useCreateProduct,
  useCreateNewConatct,
} from "../../../redux/actions/dealAction";
import useAddTask from "./accordionChildren/task/useAddTask";
import useAddContact from "./accordionChildren/contact/useAddContact";
import AddExistingContactForm from "./accordionChildren/contact/AddExixtingContactForm";
import TaskAccordion from "./accordionChildren/task";
import useAddQuoteForm from "./accordionChildren/quote/useAddQuoteForm";
import CustomDialogNf from "../../Layout/customDialog/CustomDialogNf";

import QuoteAccordion from "./accordionChildren/quote";
import SetValuation from "./accordionChildren/product/SetValuation";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { Tab, Tabs } from "@mui/material";

const DealAccordion = () => {
  const { deal } = useSelector((state) => state.dealStore);
  const params = useParams();
  const { TASK_INITIAL_FORM_STATE, TASK_FORM_VALIDATION } = useAddTask();
  const { CONTACT_INITIAL_FORM_STATE, CONTACT_FORM_VALIDATION } =
    useAddContact();
  const { QUOTE_INITIAL_FORM_STATE, QUOTE_FORM_VALIDATION } = useAddQuoteForm();

  const [valuation, setValuation] = useState(parseInt(deal?.data?.valuation));

  const { mutate: addTask } = useCreateTask(params.uuid);
  const { mutate: addContact } = useCreateNewConatct(deal?.data?.owner);
  const { mutate: addQuote } = useCreateQuote(params.uuid);
  const { mutate: addProduct } = useCreateProduct(params.uuid, valuation);

  const [tableProduct, setTableProduct] = useState([]);

  const [productAccordion, setProductAccordion] = useState(false);
  const [quoteAccordion, setQuoteAccordion] = useState(false);
  const [contactAccordion, setContactAccordion] = useState(false);
  const [taskAccordion, setTaskAccordion] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const dialogButtonStyles = {
    position: "absolute",
    top: "3px",
    right: "40px",
  };

  const dialogButtonStylesOpen = {
    position: "absolute",
    top: "13px",
    right: "40px",
  };

  return (
    <Grid item xs={12} sm={12} lg={3.5}>
      <Grid container display='grid' direction='column' className='pb-2 py-2'>
        <Stack spacing={2}>
          <div style={{ position: "relative" }}>
            <SimpleAccordion
              name='Product'
              count={
                deal?.data?.product_cart ? (
                  <span>({deal?.data?.product_cart?.length})</span>
                ) : (
                  " "
                )
              }
              setState={() => setProductAccordion(!productAccordion)}
            >
              {deal?.data?.product_cart?.length > 0 ? (
                deal?.data?.product_cart?.map((item) => (
                  <>
                    <Grid className='py-1 border-bottom border-2'>
                      <ProductAccordion key={item.uuid} data={item} />
                    </Grid>
                  </>
                ))
              ) : (
                <p
                  className='text-center text-capitalize'
                  style={{ fontWeight: 500, color: "#919191" }}
                >
                  no products added
                </p>
              )}
              <SetValuation valuation={deal?.data?.valuation} />
            </SimpleAccordion>
            <CustomDialogNf
              closeButton='close'
              addButton='Add to Deal'
              heading='Add Product'
              maxWidth='lg'
              styles={
                productAccordion ? dialogButtonStylesOpen : dialogButtonStyles
              }
              mutate={addProduct}
              data={tableProduct}
            >
              <AddProductForm
                tableProduct={tableProduct}
                setTableProduct={setTableProduct}
                valuation={valuation}
                setValuation={setValuation}
              />
            </CustomDialogNf>
          </div>
          <div style={{ position: "relative" }}>
            <SimpleAccordion
              key={2}
              name='Contact'
              setState={() => setContactAccordion(!contactAccordion)}
              count={
                deal?.data?.contact_list.length ? (
                  <span>({deal?.data?.contact_list.length})</span>
                ) : (
                  " "
                )
              }
            >
              {deal?.data?.contact_list?.map((item) => (
                <Grid className='py-1 border-bottom border-2'>
                  <ContactAccordion key={item.uuid} data={item?.contact} />
                </Grid>
              ))}
            </SimpleAccordion>
            <CustomDialog
              styles={
                contactAccordion ? dialogButtonStylesOpen : dialogButtonStyles
              }
              mutate={addContact}
              maxWidth='md'
              button={
                <IconButton>
                  <MdAdd />
                </IconButton>
              }
              closeButton='Close'
              addButton='Add'
              initialValues={CONTACT_INITIAL_FORM_STATE[selectedTab]}
              validationSchema={CONTACT_FORM_VALIDATION[selectedTab]}
              heading='Add Contact'
            >
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label='Add New Contact' />
                <Tab label='Add Existing Contact' />
              </Tabs>
              {selectedTab === 0 && <AddContactForm />}
              {selectedTab === 1 && <AddExistingContactForm />}
            </CustomDialog>
          </div>

          <div style={{ position: "relative" }}>
            <SimpleAccordion
              key={3}
              name='Quote'
              setState={() => setQuoteAccordion(!quoteAccordion)}
              count={
                deal?.data?.quote ? (
                  <span>({deal?.data?.quote.length})</span>
                ) : (
                  " "
                )
              }
            >
              {deal?.data?.quote ? (
                deal?.data?.quote.map((item) => (
                  <Grid key={item.id} className='py-1 border-bottom border-2'>
                    <QuoteAccordion data={item} />
                  </Grid>
                ))
              ) : (
                <p
                  className='text-center text-capitalize'
                  style={{ fontWeight: 500, color: "#919191" }}
                >
                  no quote added
                </p>
              )}
            </SimpleAccordion>
            <CustomDialog
              mutate={addQuote}
              styles={
                quoteAccordion ? dialogButtonStylesOpen : dialogButtonStyles
              }
              closeButton='Close'
              addButton='Add'
              button={
                <IconButton>
                  <MdAdd />
                </IconButton>
              }
              heading='Add Quote'
              maxWidth='lg'
              initialValues={QUOTE_INITIAL_FORM_STATE}
              validationSchema={QUOTE_FORM_VALIDATION}
            >
              <AddQuoteForm />
            </CustomDialog>
          </div>

          <div style={{ position: "relative" }}>
            <SimpleAccordion
              key={4}
              name='Tasks'
              count={
                deal?.data?.tasks ? (
                  <span>({deal?.data?.tasks?.length})</span>
                ) : (
                  " "
                )
              }
              setState={() => setTaskAccordion(!taskAccordion)}
            >
              {deal?.data?.tasks?.length > 0 ? (
                deal?.data?.tasks?.map((item) => (
                  <Grid key={item.uuid} className='py-1 border-bottom border-2'>
                    <TaskAccordion key={item.uuid} data={item} />
                  </Grid>
                ))
              ) : (
                <p
                  className='text-center text-capitalize'
                  style={{ fontWeight: 500, color: "#919191" }}
                >
                  no task added
                </p>
              )}
            </SimpleAccordion>
            <CustomDialog
              closeButton='Close'
              addButton='Add'
              styles={
                taskAccordion ? dialogButtonStylesOpen : dialogButtonStyles
              }
              mutate={addTask}
              button={
                <IconButton>
                  <MdAdd />
                </IconButton>
              }
              maxWidth='md'
              initialValues={TASK_INITIAL_FORM_STATE}
              validationSchema={TASK_FORM_VALIDATION}
              heading='Add Task'
            >
              <AddTaskForm />
            </CustomDialog>
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DealAccordion;
