/** @format */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSingleUser } from "../../../../redux/actions/userAction";
import useGetSingleUser from "../userHooks/useGetSingleUser";

const useSingleUser = () => {
  const { data, isLoading } = useGetSingleUser({
    onSuccess: (data) => {
      // const columnData = data.data.columnpreference;

      // let lastObject = columnData[columnData.length - 1];

      // localStorage.setItem(
      //   "TableColumnPrefrences",
      //   JSON.stringify(lastObject.data)
      // );
      // console.log(JSON.parse(localStorage.setItem("TableColumnPrefrences")));

      localStorage.setItem("userData", JSON.stringify(data?.data));
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleUser(data?.data?.data));
    }
  }, [dispatch, data]);
  return { isLoading };
};

export default useSingleUser;
