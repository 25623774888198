import React from "react";

const SmsTemplate = ({ template, setTemplate, handleClickClose }) => {
  const data = [
    { id: 0, body: `Custom Message` },
    {
      id: 1,
      body: `hi {{name}} , how are you?`,
      customParams: [{ paramName: "name", paramValue: "" }],
    },
    {
      id: 2,
      body: `hello {{name}} , from {{company_name}} are you?`,
      customParams: [
        { paramName: "name", paramValue: "" },
        { paramName: "company_name", paramValue: "" },
      ],
    },
  ];

  return (
    <>
      {data?.map((item, index) => (
        <div
          key={index + 1}
          className="py-3 px-2 border-bottom template-card"
          onClick={() => {
            setTemplate(item);
            handleClickClose();
          }}
          style={{
            cursor: "pointer",
            backgroundColor: `${template?.id === item?.id ? "#e2f5f7" : ""}`,
          }}
        >
          {index + 1}. {item?.body}
        </div>
      ))}{" "}
    </>
  );
};

export default SmsTemplate;
