import { Button, Typography } from "@mui/material";
import React from "react";

const TemplateCardActionArea = ({ template }) => {
  return (
    <div className="pt-2 pb-3 px-2 border-bottom templet-card">
      <Typography fontWeight={600} className="mb-2">
        {template?.elementName || ""}
      </Typography>
      {template?.header && template.header.typeString === "image" && (
        <div
          style={{
            height: "100px",
            width: "500px",
            overflowX: "scroll",
            overflowY: "scroll",
            marginBottom: "10px",
          }}
        >
          <img
            src="https://image.shutterstock.com/image-vector/vector-illustration-sample-red-grunge-600w-2065712915.jpg"
            alt="Header For Template"
            width={"-webkit-fill-available"}
          />
        </div>
      )}
      <Typography fontSize={14} style={{ whiteSpace: "pre-line" }}>
        {template?.bodyOriginal}
      </Typography>
      {template?.footer && (
        <Typography color="#8d8d8d" fontSize={12} className="my-2">
          {template?.footer}
        </Typography>
      )}
      {template?.buttons &&
        template.buttons.map((button) => (
          <Button size="small" sx={{ fontSize: "12px" }} variant="outlined">
            {button.parameter.text}
          </Button>
        ))}
    </div>
  );
};

export default TemplateCardActionArea;
