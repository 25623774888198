import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const useGetDealStages = (options) => {
  return useQuery(
    "deal-kanban",
    async () => await tenantAPI.get(`/deals/kanban/`),
    {
      ...options,
      select: (data) => {
        return data?.data;
      },
    }
  );
};
export default useGetDealStages;
