import { Divider, Typography } from "@mui/material";
import React from "react";

const data = [
  {
    sentTo: "tabish@gmail.com",
    date: "24/08/22",
    time: "09:43am",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    sentTo: "sajid@gmail.com",
    date: "24/08/22",
    time: "09:43am",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
];

const PreviousSMS = () => {
  return (
    <>
      {data.map((sms) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <Typography
                color="text.dark"
                fontSize="12px"
                className="d-flex align-items-center"
              >
                To: {sms.sentTo}
              </Typography>
              <span>
                <Typography
                  fontSize="12px"
                  color="text.dark"
                  display="inline"
                  className="me-2"
                >
                  {sms.date}
                </Typography>
                <Typography fontSize="12px" color="text.dark" display="inline">
                  {sms.time}
                </Typography>
              </span>
            </div>
            <Typography color="text.dark" fontSize="12px" className="mt-1">
              {sms.body}
            </Typography>
            <Divider className="mt-2 mb-4" />
          </>
        );
      })}
    </>
  );
};

export default PreviousSMS;
