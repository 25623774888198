import { Container} from "@mui/material"
import Table from "../../../components/Layout/table";
import React, { useEffect, useState } from "react"
import Header from "../../../components/Layout/Header"
import { leadAndContactColumns } from "../../../constants/columns"
import useRolesTable from "./useRolesTable"
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from "react-redux";
import { openRoleDailog } from "../../../redux/actions/LayoutAction";
import CreateNewRole from "../newRole/CreateNewRole";

const RolesTable = () => {
  const { openRoleDialog } =
  useSelector((state) => state.layoutStore)
  const { columns,isLoading, rolesData  } = useRolesTable()
  
  const dispatch = useDispatch();

 
  useEffect(() => {
    document.title = "Roles | CRM"
  }, [])

  const handleClickOpen = () =>{
    // openRoleDailog()(dispatch)
    dispatch(openRoleDailog())
  }

  return (
    <div>
      <Header
        name="Roles"
        showSearch={true}
        // exportButton
        // exportOnClick={OnExport}
        addBtnClick={handleClickOpen}
      />

      <Container sx={{ marginTop: "30px" }}>
        <Table
          mt={"30px"}
          data={rolesData ? rolesData : [] }
          loading={isLoading}
          columns={
           columns
          }
        />
      </Container>
    </div>
  )
}

export default RolesTable
