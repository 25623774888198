import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { setSingleContact } from "../../../redux/actions/contactAction";

import useGetSingleContact from "../contactHooks/useGetSingleContact";

const useSingleContact = (id) => {
  const { data, isLoading } = useGetSingleContact(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleContact(data.data));
    }
  }, [dispatch, data]);

  return { isLoading };
};

export default useSingleContact;
