/** @format */

import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import Header from "../../../components/Layout/Header";
import PrivateRoute from "../../../utils/PrivateRoute";
import CustomizeSideBar from "../../../components/Layout/custSidebar/CustomizeSideBar";
import sideBarMenu from "../sideBarMenu";
import Sales from "../sales";
import DealPipelineReport from "../deal-pipeline";
import Lead from "../lead";
import Product from "../product";
import Navbar from "../../../components/Layout/navbar";

function Reports() {
  return (
    <PrivateRoute>
      <Navbar />
      <Header name='Reports' showSearch={true} />
      <div>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={1.5}
            sx={{ borderBottom: { xs: "2px solid gray", lg: "none" } }}
          >
            <CustomizeSideBar sideBarMenu={sideBarMenu} />
          </Grid>
          <Grid item xs={12} md={8} lg={8.5}>
            <Routes>
              <Route
                path='/'
                element={
                  <Container
                    style={{
                      height: "calc(100vh - 50px - 60px)",
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    <Typography className='second' variant='h6'>
                      There is no content here yet
                    </Typography>
                  </Container>
                }
              />
              <Route path='/sales' element={<Sales />} />
              <Route path='/deal-pipeline' element={<DealPipelineReport />} />
              <Route path='/lead' element={<Lead />} />
              <Route path='/product' element={<Product />} />
            </Routes>
          </Grid>
          <Grid item xs={12} md={4} lg={2} p={2}>
            <div className='bg-third text-align-center'>Filter Options</div>
          </Grid>
        </Grid>
      </div>
    </PrivateRoute>
  );
}

export default Reports;
