import React from "react";
import CustomFinal from "../../../../components/custLead/CustomFinal";
import CustomLeadHeader from "../../../../components/custLead/CustomLeadHeader";

const LeadsStatus = () => {
  const LeadsStatus = [
    {
      id: 1,
      name: "New",
      type: "",
      is_active: "",
      is_required: "",
    },
    {
      id: 2,
      name: "Working",
      type: "",
      is_active: "",
      is_required: "",
    },
    {
      id: 3,
      name: "Qualified",
      type: "",
      is_active: "",
      is_required: "",
    },
    {
      id: 4,
      name: "Unqualified",
      type: "",
      is_active: "",
      is_required: "",
    },
  ];
  return (
    <>
      <CustomLeadHeader
        HeadingName="Current Fields"
        style={{ padding: "10px" }}
      />
      <div className="px-5 py-2">
        {LeadsStatus?.map((item) => (
          <div className="mt-2">
            <CustomFinal
              showEdit={false}
              name={item?.name}
              label={item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default LeadsStatus;
