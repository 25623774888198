import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import DashboardCard from "../../../components/home/DashboardCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useCurrMonthData } from "../homeHooks/useHomeData";
import numberWithCommas from "../../../utils/numberWithCommas";

const useStyle = makeStyles({
  root: {
    "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px !important",
    },
  },
});

const AccordionTab = ({ title, content, expanded, setExpanded }) => {
  const classes = useStyle();
  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      style={{
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        style={{ minHeight: "40px", paddingInlineStart: "15px" }}
        expandIcon={<ExpandMoreIcon />}
        className={classes.root}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        style={{
          paddingInlineStart: "20px",
          paddingTop: "0px",
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

var date = new Date();
const curMonth = date.getMonth();

const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const findPercentage = (curr, prev) => {
  if (!prev) {
    if (!curr) return 0;
    return 100;
  }
  if (prev === 1) {
    if (!curr) return -1;
  }
  var percent = (curr / prev) * 100;
  return percent - 100;
};

const CurrMonthInfo = () => {
  const [leadsConvExpanded, setLeadsConvExpanded] = useState(false);
  const [revTargetExpanded, setRevTargetExpanded] = useState(false);
  const [revGenExpanded, setRevGenExpanded] = useState(false);
  const [revLostExpanded, setRevLostExpanded] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const { data } = useCurrMonthData();

  // Leads converted to deal for current month data
  var dealMonthlyCount =
    data?.dealMonthlyCountData?.length > 0
      ? data.dealMonthlyCountData[curMonth] &&
        data.dealMonthlyCountData[curMonth]?.revenue
      : 0;
  var dealCountPrevMonth =
    data?.dealMonthlyCountData?.length > 0
      ? data?.dealMonthlyCountData[curMonth - 1] &&
        data?.dealMonthlyCountData[curMonth - 1]?.revenue
      : 0;
  var dealChangePercent = findPercentage(dealMonthlyCount, dealCountPrevMonth);

  // Revenue Target for Current Month data
  var revTargetForMonth =
    data?.tenantRevenueDataByMonth?.length > 0
      ? data?.dealMonthlyCountData[curMonth] &&
        data?.dealMonthlyCountData[curMonth]?.revenue
      : 0;
  var revTargetForPrevMonth =
    data?.tenantRevenueDataByMonth?.length > 0
      ? data?.dealMonthlyCountData[curMonth - 1] &&
        data?.dealMonthlyCountData[curMonth - 1]?.revenue
      : 0;

  // Revenue Generated in Current month data
  var revGainForMonth =
    data?.dealRevenueGainByMonth?.length > 0
      ? data?.dealRevenueGainByMonth[curMonth] &&
        data?.dealRevenueGainByMonth[curMonth]?.revenue
      : 0;
  var revGainForPrevMonth =
    data?.dealRevenueGainByMonth?.length > 0
      ? data?.dealRevenueGainByMonth[curMonth - 1] &&
        data?.dealRevenueGainByMonth[curMonth - 1]?.revenue
      : 0;
  var revGainChange = findPercentage(revGainForMonth, revGainForPrevMonth);

  // Revenue Lost in Current month data
  var revLostForMonth =
    data?.dealRevenueLostByMonth?.length > 0
      ? data?.dealRevenueLostByMonth[curMonth] &&
        data?.dealRevenueLostByMonth[curMonth]?.revenue
      : 0;
  var revLostForPrevMonth =
    data?.dealRevenueLostByMonth.length > 0
      ? data?.dealRevenueLostByMonth[curMonth - 1] &&
        data?.dealRevenueLostByMonth[curMonth - 1]?.revenue
      : 0;

  return (
    <>
      <Grid item lg={3} md={6} xs={12}>
        <DashboardCard sx={{ padding: "0px 0px" }}>
          <AccordionTab
            title={
              <Typography
                style={{ fontSize: "13px", color: "black", fontWeight: "500" }}
                className="d-flex justify-content-between w-100 align-items-center"
              >
                <span>Leads Converted in {allMonths[curMonth]} </span>
                {!leadsConvExpanded ? (
                  <span className="me-2" style={{ fontSize: "16px" }}>
                    {dealMonthlyCount || 0}
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            }
            expanded={leadsConvExpanded}
            setExpanded={setLeadsConvExpanded}
            content={
              <>
                <div className="d-flex">
                  <span
                    style={{ fontSize: "24px", fontWeight: "600" }}
                    className="me-3"
                  >
                    {dealMonthlyCount || 0}
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "0.25rem",
                    }}
                  >
                    {dealChangePercent}%
                  </span>
                  <span
                    className="second"
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Than last month
                  </span>
                </div>
                <h5
                  className="second"
                  style={{
                    fontSize: "13px",
                    marginTop: "0.1rem",
                    marginBottom: "0",
                  }}
                >
                  Leads Converted in {allMonths[curMonth - 1]}: 90
                </h5>
              </>
            }
          />
        </DashboardCard>
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <DashboardCard sx={{ padding: "0px 0px" }}>
          <AccordionTab
            title={
              <Typography
                style={{ fontSize: "13px", color: "black", fontWeight: "500" }}
                className="d-flex justify-content-between w-100 align-items-center"
              >
                <span>Revenue Target for {allMonths[curMonth]} </span>
                {!revTargetExpanded ? (
                  <span className="me-2" style={{ fontSize: "16px" }}>
                    $ {numberWithCommas(revTargetForMonth)}
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            }
            expanded={revTargetExpanded}
            setExpanded={setRevTargetExpanded}
            content={
              <>
                <div className="d-flex">
                  <span
                    style={{ fontSize: "24px", fontWeight: "500" }}
                    className="me-3"
                  >
                    $ {numberWithCommas(revTargetForMonth)}
                  </span>
                </div>
                <h5
                  className="second"
                  style={{
                    fontSize: "13px",
                    marginTop: "0.1rem",
                    marginBottom: "0",
                  }}
                >
                  Revenue Target for {allMonths[curMonth - 1]}:{" "}
                  {numberWithCommas(revTargetForPrevMonth)}
                </h5>
              </>
            }
          />
        </DashboardCard>
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <DashboardCard sx={{ padding: "0px 0px" }}>
          <AccordionTab
            title={
              <Typography
                style={{ fontSize: "12px", color: "black", fontWeight: "500" }}
                className="d-flex justify-content-between w-100 align-items-center"
              >
                <span>Revenue Generated in {allMonths[curMonth]} </span>
                {!revGenExpanded ? (
                  <span
                    className="me-2"
                    style={{ fontSize: "16px", textOverflow: "ellipsis" }}
                  >
                    $ {numberWithCommas(revGainForMonth)}
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            }
            expanded={revGenExpanded}
            setExpanded={setRevGenExpanded}
            content={
              <>
                <div className="d-flex">
                  <span
                    style={{ fontSize: "24px", fontWeight: "500" }}
                    className="me-3"
                  >
                    $ {numberWithCommas(revGainForMonth)}
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "0.25rem",
                    }}
                  >
                    {revGainChange?.toFixed(2)}%
                  </span>
                  <span
                    className="second"
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Target Achieved
                  </span>
                </div>
                <h5
                  className="second"
                  style={{
                    fontSize: "13px",
                    marginTop: "0.1rem",
                    marginBottom: "0",
                  }}
                >
                  Revenue Generated in {allMonths[curMonth - 1]}: 35,000
                </h5>
              </>
            }
          />
        </DashboardCard>
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <DashboardCard sx={{ padding: "0px 0px" }}>
          <AccordionTab
            title={
              <Typography
                style={{ fontSize: "14px", color: "black", fontWeight: "500" }}
                className="d-flex justify-content-between w-100 align-items-center"
              >
                <span>Revenue Lost in {allMonths[curMonth]} </span>
                {!revLostExpanded ? (
                  <span className="me-2" style={{ fontSize: "16px" }}>
                    $ {revLostForMonth}
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            }
            expanded={revLostExpanded}
            setExpanded={setRevLostExpanded}
            content={
              <>
                <div className="d-flex">
                  <span
                    style={{
                      color: "red",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                    className="me-3"
                  >
                    $ {revLostForMonth}
                  </span>
                </div>
                <h5
                  className="second"
                  style={{
                    fontSize: "13px",
                    marginTop: "0.1rem",
                    marginBottom: "0",
                  }}
                >
                  Revenue Lost in {allMonths[curMonth - 1]}:{" "}
                  {revLostForPrevMonth}
                </h5>
              </>
            }
          />
        </DashboardCard>
      </Grid>
    </>
  );
};

export default CurrMonthInfo;
