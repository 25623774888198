import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Layout/Container";
import Header from "../../components/Layout/Header";
import Table from "../../components/Layout/table";
import verifyDomain from "../../utils/verifyDomain";
import useAdmin from "./useAdmin";

const Admin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (verifyDomain()) {
      navigate("/my-crm/log-in", { replace: true });
    }
  }, [navigate]);
  // if (verifyDomain()) return null;

  const {
    data,
    isLoading,
    columns,
    processRowUpdate,
    handleProcessRowUpdateError,
  } = useAdmin();
  return (
    <>
      <Header name="Admin Panel" showSearch />
      <Container>
        <Table
          style={{ marginTop: "30px" }}
          data={data ? data : []}
          loading={isLoading}
          columns={columns}
        />
      </Container>
    </>
  );
};

export default Admin;
