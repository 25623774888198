import React from "react";

import { Button, Grid, Typography } from "@mui/material";

import InputField from "../../../components/formsUI/InputField";
import MobileNumberInput from "../../../components/formsUI/MobileNumberInput";

import { useUserRegister } from "../../../redux/actions/authAction";
// import { useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import PasswordInput from "../../../components/formsUI/PasswordInput";
import useRegisterData from "./useRegister";

const PersonalInfo = () => {
  const style = {
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 0 },
  };

  const { personalState, personalValidation } = useRegisterData();
  const { mutate } = useUserRegister();
  // const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        ...personalState,
      }}
      validationSchema={personalValidation}
      onSubmit={async (values) => {
        mutate({
          ...values,
          full_name: `${values.first_name} ${values.last_name}`.trim(),
        });
      }}
    >
      <Form>
        <Grid container spacing={2} className="pt-4">
          <Grid item xs={6}>
            <InputField
              name="first_name"
              label="First Name"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              name="last_name"
              label="Last Name"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="email"
              label="Your Email Address"
              color="secondary"
              type="email"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={12}>
            <MobileNumberInput
              name="phone"
              label="Mobile Number"
              color="#f8f9fa"
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="job_title"
              label="Job Title"
              color="secondary"
              size="small"
              sx={style}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              name="password"
              fullWidth
              label="Password"
              color="secondary"
              sx={style}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              name="password2"
              fullWidth
              label="Confirm Password"
              color="secondary"
              sx={style}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              className="text-light"
              sx={{
                borderRadius: 0,
                "&:hover": {
                  background: "#576a77e7",
                },
              }}
            >
              Register User
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="12px">
              Email ID provided shall be sent all further correspondance
              pertaining to user/company registration. Ensure you use a valid
              email id
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default PersonalInfo;
