import React from "react";

import { Divider, Grid } from "@mui/material";

import InputField from "../../../../components/formsUI/InputField";
import MobileNumberInput from "../../../../components/formsUI/MobileNumberInput";
import SelectField from "../../../../components/formsUI/SelectField";
import { titleData } from "../../../../utils/leadVariables";

const PersonalInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Personal Info</Divider>
      </Grid>
      <Grid item xs={12} sm={2} md={1.5}>
        <SelectField
          name="prefix"
          label="Prefix"
          color="secondary"
          selectValue="name"
          data={titleData}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2.5}>
        <InputField
          name="first_name"
          label="First Name *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InputField
          name="middle_name"
          label="Middle Name"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InputField
          name="last_name"
          label="Last Name *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MobileNumberInput
          name="primary_number"
          label="Primary Number *"
          color="#fff"
          border="4px"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MobileNumberInput
          name="secondary_number"
          label="Secondary Number"
          color="#fff"
          border="4px"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MobileNumberInput
          name="work_number"
          label="Work Number"
          color="#fff"
          border="4px"
        />
      </Grid>
    </>
  );
};

export default PersonalInfo;
