import React from "react";

import { useField, useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
// import ReactPhoneInput from "react-phone-input-2";

const MobileNumberInput = ({
  name,
  label,
  color,
  border,
  width,
  ...otherProps
}) => {
  const [field, mata] = useField(name);

  console.log(field, "--field");

  const data = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  const onChange = (formattedValue) => {
    data.setFieldValue(name, formattedValue);
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <>
      <PhoneInput
        containerStyle={{
          border: configTextfield.error
            ? "1px solid #d32f2f"
            : "1px solid #CACACA",
          borderRadius: "4px",
          height: "40px",
          width: width ? width : "100%",
        }}
        inputProps={{
          name: configTextfield.name,
          onBlur: configTextfield.onBlur,
          value: configTextfield.value,
        }}
        inputStyle={{
          width: "100%",
          background: "none",
          borderRadius: border ? border : "0",
          padding: "8.5px 50px",
          height: "38px",
          "&:focus": {
            borderColor: "#576a77",
          },
        }}
        country={"in"}
        dropdownStyle={{ zIndex: -2 }}
        defaultErrorMessage={configTextfield.helperText}
        value={field.value}
        onChange={(_, _1, _2, formattedValue) => onChange(formattedValue)}
        specialLabel={
          <span
            className="d-block px-1 mb-1"
            style={{
              color: configTextfield.error ? "#d32f2f" : "",
              background: color,
            }}
          >
            {label}
          </span>
        }
        enableSearch
      />
      {/* <ReactPhoneInput
        inputProps={{ ...configTextfield }}
        defaultCountry={"in"}
        // value={this.state.phone}
        onChange={onChange}
      /> */}
      <span
        style={{
          color: configTextfield.error ? "#d32f2f" : "",
          fontSize: "12px",
        }}
      >
        {configTextfield.helperText ? configTextfield.helperText : ""}
      </span>
    </>
  );
};

export default MobileNumberInput;
