import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import Container from "../../../components/Layout/Container";
import Navbar from "../../../components/Layout/navbar";
import ChangePass from "../../../components/profile/profileCard/ChangePass";
import ProfileImage from "../../../components/profile/profileCard/ProfileImage";
import Profileinfo from "../../../components/profile/profileCard/Profileinfo";
import useSingleUser from "../../settings/user/singleUser/useSingleUser";

const SingleUser = () => {
  const params = useParams();
  const { isLoading } = useSingleUser(params.uuid);
  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <Navbar />
          <Formik>
            <Form>
              <Container
                sx={{ marginTop: "4.3rem !important" }}
                className='d-flex shadow my-3 rounded py-3'
              >
                <Grid container justifyContent={"center"}>
                  <ProfileImage />
                  <Profileinfo />
                  <ChangePass />
                </Grid>
              </Container>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default SingleUser;
