import React from "react";
import { Routes, Route } from "react-router-dom";
import LogIn from "./login";
import ComapnyRegister from "./register";

const CompanyAuth = () => {
  return (
    <>
      <Routes>
        <Route path='/log-in' element={<LogIn />} />
        <Route path='/register' element={<ComapnyRegister />} />
      </Routes>
    </>
  );
};

export default CompanyAuth;
