import axios from "axios";
const { REACT_APP_API_PROTOCOL } = process.env;
const { REACT_APP_API_HOSTNAME } = process.env;
const { REACT_APP_API_PORT } = process.env;

export const rootAPI = axios.create({
  baseURL: `${REACT_APP_API_PROTOCOL}${REACT_APP_API_HOSTNAME}${REACT_APP_API_PORT}`,
});

let headers = {};
if (localStorage.getItem("token")) {
  headers["x-access-token"] = localStorage.getItem("token");
}

export const tenantAPI = axios.create({
  baseURL: `${REACT_APP_API_PROTOCOL}${
    window.location.host.split(".")[0]
  }.${REACT_APP_API_HOSTNAME}${REACT_APP_API_PORT}`,
  headers,
});
