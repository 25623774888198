import React from "react";

import { TextField } from "@mui/material";

import { useField } from "formik";

const InputField = ({ name, fullWidth, ...otherProps }) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: otherProps.variant ? otherProps.variant : "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <TextField
      {...configTextfield}
      className="input-field"
      // inputProps={{
      //   onDoubleClick: selectText,
      // }}
    />
  );
};

export default InputField;
