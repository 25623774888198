/** @format */

import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { BiEdit } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import DeleteDialog from "../../utils/deleteDialog";
import CustomDialog from "../customDialog/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const EditComponent = ({
  onClickEdit,
  onClickDelete,
  deleteDescription,
  onclickDownload,
  onClickShare,
  edit,
  clear,
  download,
  downloadLoading,
  hideEdit,
  showdelete,
  shareLoading,
  showShare,
  share,
  initialValues,
  validationSchema,
  Component,
  heading,
  mutate,
  uuid,
  deleteMutate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{ fontSize: "14px", color: "black" }}
      >
        <BsThreeDotsVertical />
      </IconButton>
      <Menu
        id='demo-customized-menu'
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!hideEdit && (
          <CustomDialog
            mutate={mutate}
            heading={heading}
            maxWidth='md'
            closeButton='Close'
            addButton='Update'
            button={
              <MenuItem
                sx={{ fontWeight: 400, color: "#313131", fontSize: "13px" }}
                // onClick={onClickEdit}
                disableRipple
              >
                <BiEdit className='me-2' />
                {edit}
              </MenuItem>
            }
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {" "}
            <Component />
          </CustomDialog>
        )}
        {showdelete ? (
          /* <MenuItem
              sx={{ fontWeight: 400, color: "#313131 !important" }}
              // onClick={onClickDelete}
              disableRipple
            >
              <RiDeleteBin6Line className="me-2" />
              {clear}
            </MenuItem> */
          <DeleteDialog
            uuid={uuid}
            mutate={deleteMutate}
            element={
              <MenuItem sx={{ fontSize: "13px" }}>
                <DeleteForeverIcon fontSize='18' className='me-2' />
                Delete
              </MenuItem>
            }
            description={
              deleteDescription || "This item will be permanently deleted"
            }
          />
        ) : (
          <MenuItem
            sx={{ fontWeight: 400, color: "#313131" }}
            onClick={() => onclickDownload()}
            disableRipple
          >
            {downloadLoading ? (
              <CircularProgress size={20} className='me-2' />
            ) : (
              <IoMdDownload className='me-2' />
            )}
            {download}
          </MenuItem>
        )}
        {showShare && (
          <MenuItem
            sx={{ fontWeight: 400, color: "#313131" }}
            onClick={onClickShare}
            disableRipple
          >
            {shareLoading ? (
              <CircularProgress size={20} className='me-2' />
            ) : (
              <MdOutlineFileUpload className='me-2' />
            )}
            {share}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default EditComponent;
