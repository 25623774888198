import { Divider, Grid } from "@mui/material";
import React from "react";
import InputField from "../../../../../components/formsUI/InputField";

const ProductPrice = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Product Price</Divider>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="price_consumer"
          label="Price Consumer*"
          color="secondary"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="consumer_discount"
          label="Consumer Discount"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="base_price"
          label="Base Price*"
          color="secondary"
          size="small"
        />
      </Grid>
      {/* <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="effective_mrp"
          label="Effective MRP*"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={12} sm={4} md={4}>
        <InputField name="cgst" label="CGST*" color="secondary" size="small" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <InputField name="sgst" label="SGST*" color="secondary" size="small" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <InputField name="igst" label="IGST*" color="secondary" size="small" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <InputField
          name="price"
          label="Price*"
          color="secondary"
          size="small"
        />
      </Grid>
    </>
  );
};

export default ProductPrice;
