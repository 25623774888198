import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const useDealStage = (options) => {
  return useQuery(
    "deal_stages",
    async () => await tenantAPI.get(`/deals/deal_stage/0/`),
    {
      ...options,
      select: (data) => {
        return data?.data?.data?.map((r) => ({ ...r, id: r.id }));
      },
    }
  );
};

export default useDealStage;
