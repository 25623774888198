/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Phone, MailOutline } from "@mui/icons-material";

import { Grid, Box, Typography } from "@mui/material";

import { useSelector } from "react-redux";

import PhoneMenu from "../../ivr/PhoneMenu";

const AccountCompany = () => {
  const { account } = useSelector((state) => state.accountStore);

  return (
    <Grid
      container
      className='py-3'
      display='grid'
      direction='column'
      alignItems='center'
    >
      <Grid item xs={12} className='d-flex align-items-center  my-2'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Annual Revenue{" "}
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          <Typography
            component='p'
            style={{ color: "#919191", fontWeight: 500, fontSize: "14px" }}
          >
            {account?.ann_revenue}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className='d-flex align-items-center  my-2'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Industry{" "}
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          <Typography
            component='p'
            style={{ color: "#919191", fontWeight: 500, fontSize: "14px" }}
          >
            {account?.industry}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className='d-flex align-items-center mb-1'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Work Number
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          {" "}
          {account?.work_number ? (
            <PhoneMenu
              name={
                <Box
                  className='text-truncate overflow-hidden me-2 mt-2'
                  sx={{
                    maxWidth: "200px",
                    border: "1px solid #1F73B7",
                    borderRadius: "5px",
                    color: "#1F73B7",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "2px 4px",
                    }}
                    className='d-flex align-items-center me-1 custom-link-color'
                  >
                    <Phone fontSize='small' /> {account?.work_number}
                  </p>
                </Box>
              }
              phNum={account?.work_number}
              // name={account?.first}
            />
          ) : (
            "-"
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className='d-flex align-items-center mb-1'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Mobile Number
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          {account?.primary_number ? (
            <PhoneMenu
              name={
                <Box
                  className='text-truncate overflow-hidden me-2 mt-2'
                  sx={{
                    maxWidth: "200px",
                    border: "1px solid #1F73B7",
                    borderRadius: "5px",
                    color: "#1F73B7",
                    display: "inline-block",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "2px 4px",
                    }}
                    className='d-flex align-items-center me-1 custom-link-color'
                  >
                    <Phone fontSize='small' /> {account?.primary_number}
                  </p>
                </Box>
              }
              phNum={account?.primary_number}
            />
          ) : (
            "-"
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className='d-flex align-items-center  mb-1'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Email
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          {account?.primary_email ? (
            <Box
              className='text-truncate overflow-hidden me-2 mt-2'
              sx={{
                maxWidth: "200px",
                border: "1px solid #1F73B7",
                borderRadius: "5px",
                color: "#1F73B7",
                display: "inline-block",
              }}
            >
              <Link
                to='#'
                style={{
                  fontSize: "14px",
                  margin: "2px 4px",
                }}
                className='d-flex align-items-center me-1 custom-link-color'
              >
                <MailOutline fontSize='small' className='me-1' />
                <span className='text-truncate'> {account?.primary_email}</span>
              </Link>
            </Box>
          ) : (
            "-"
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} className='d-flex align-items-center  my-2'>
        <Grid item xs={5}>
          <Typography
            component='p'
            style={{ color: "#616161", fontWeight: 500, fontSize: "14px" }}
          >
            Website{" "}
          </Typography>
        </Grid>
        <Grid item xs={7} className='d-flex justify-content-end'>
          <Typography
            component='p'
            style={{ color: "#919191", fontWeight: 500, fontSize: "14px" }}
          >
            {account?.website}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountCompany;
