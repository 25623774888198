import { useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { tenantAPI } from "../../../config/api"
import { setDefaultPagination } from "../../../redux/actions/LayoutAction"
import { toast } from "react-toastify"
import errorHandle from "../../../utils/errorHandle"

export const useRolesData = (options) => {
  const dispatch = useDispatch()
  const { page, size } = useSelector((store) => store.layoutStore)
  return useQuery(
    ["roles", page, size],
    async () =>
      await tenantAPI.get(`accounts/role/0/`, {
        params: {
          pg_no: page,
          offset: size,
        },
      }),
    {
      ...options,
      select: (data) => {
        return {
          data: data?.data?.data?.map((r) => ({ ...r, id: r.uuid })),
          count: data?.data?.count,
        }
      },
      onSuccess: (data) => {
        dispatch(
          setDefaultPagination({
            page,
            size,
            rowCount: data?.count,
          })
        )
        options?.onSuccess && options?.onSuccess(data)
      },
    }
  )
}

export const usePermissionsData = (options) => {
  return useQuery(
    "permissions",
    async () => await tenantAPI.get(`accounts/permissions/0`),
    {
      ...options,
      select: (data) => {
        return {
          data: data?.data?.data?.map((r) => ({ ...r, id: r.uuid })),
          count: data?.data?.count,
        }
      },
    }
  )
}

export const useDeleteRoles = (options) => {
  const deleteRoles = async (data) => {
    return await tenantAPI.delete(`accounts/role/${data?.uuid}/`)
  }

  const mutation = useMutation(deleteRoles, {
    onSuccess: () => {
      toast.success("Role deleted successfully.")
      options.refetch()
    },
    onError: (error) => {
      errorHandle(error)
    },
  })

  return { deleteMutate: mutation.mutate }
}

