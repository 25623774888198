import React from "react";

import { Divider, Grid } from "@mui/material";

import InputField from "../../../../components/formsUI/InputField";

const AdditionalInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Additional Info</Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="description"
          label="Description"
          color="secondary"
          size="small"
          maxRows={4}
          minRows={4}
          multiline
        />
      </Grid>
    </>
  );
};

export default AdditionalInfo;
