import { Grid, Typography } from "@mui/material";
import { Field } from "formik";
import React from "react";

const DeleteForm = ({ order }) => {
  return (
    <Grid container className="mt-3">
      <div id="my-radio-group" className="mb-3 ms-2">
        <Typography variant="p" fontSize={18}>
          Select an option for deleting this stage{" "}
          <span style={{ color: "red" }}>*</span>:{" "}
        </Typography>
      </div>
      <div
        role="group"
        className="d-flex flex-column ms-3"
        aria-labelledby="my-radio-group"
      >
        <label>
          {(order === "incr" || order === "all") && (
            <Typography variant="p">
              <Field type="radio" name="stage_op" value="2" />
              &nbsp; Shift all the deals of this stage to previous stage.
            </Typography>
          )}
        </label>
        <label>
          {(order === "decr" || order === "all") && (
            <Typography variant="p">
              <Field type="radio" name="stage_op" value="1" />
              &nbsp; Shift all the deals of this stage to next stage.
            </Typography>
          )}
        </label>
      </div>
    </Grid>
  );
};

export default DeleteForm;
