import React from "react";

import { Box, useMediaQuery } from "@mui/material";

const Container = ({ children, sx, ...rest }) => {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        marginLeft: !matches ? "15px" : "15px",
        marginRight: "15px",
        marginTop: "15px",
        height: "100%",
        ...sx,
        // border: "2px solid red",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Container;
