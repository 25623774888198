import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { tenantAPI } from "../../../config/api"
import errorHandle from "../../../utils/errorHandle"

const useCreateRoles = () => {
  const mutation = useMutation(
    async (data) => {
      if (data?.permissionsList?.length < 1) {
        throw new Error("Please select atleast one permission")
      }
      await tenantAPI.post(`accounts/role/`, data)
    },
    {
      onSuccess: (data, variables) => {
        toast.success(data?.data?.msg)
      },
      onError: (error) => {
        errorHandle(error)
      },
    }
  )
  return { mutate: mutation.mutate }
}

export default useCreateRoles
