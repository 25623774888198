import { Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import DashboardCard from "../../../components/home/DashboardCard";
import useGetAll from "../../../hooks/useGetAll";

const LeadStatusReport = () => {
  const navigate = useNavigate();

  const { data } = useGetAll(`/leads/bottom_summary/`, {
    select: (data) => data?.data,
  });

  var options = {
    chart: {
      type: "bar",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (
            event?.detail === 2 &&
            config.w.config.xaxis.categories[config.dataPointIndex] !=
              "Total Leads"
          ) {
            console.log(
              config.w.config.xaxis.categories[config.dataPointIndex],
              "--event"
            );
            navigate({
              pathname: "/leads",
              search: `?select=${
                config.w.config.xaxis.categories[config.dataPointIndex]
              }`,
            });
          }
        },
      },
      toolbar: {
        export: {
          csv: {
            filename: "Leads-Status-Report",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        barHeight:'40%',
      },
    },
    colors: ["#9575CD", "#7986CB", "#5C6BC0", "#3F51B5", "#64B5F6", "#CE93D8"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: data
        ? Object?.keys(data)?.map((item) => item?.replace("_", " "))
        : [],
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  var series = [
    {
      data: data ? Object?.values(data) : [],
    },
  ];

  return (
    <DashboardCard sx={{ padding: "10px", height: "410px" }}>
      <Typography
        component="p"
        className="mb-3"
        fontSize="16px"
        fontWeight="500"
      >
        Leads Status Report
      </Typography>
      <Chart options={options} series={series} type="bar" height={300} />
    </DashboardCard>
  );
};
export default LeadStatusReport;
