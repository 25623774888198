import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  openUserDialog,
  closeUserDailog,
} from "../../../../redux/actions/LayoutAction";

const useCreateUser = () => {
  const dispatch = useDispatch();

  const FORM_VALIDATION = Yup.object().shape({
    id: Yup.string(),
    email: Yup.string().email().required("This field is required"),
    password: Yup.string(),
    password2: Yup.string(),
    full_name: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Please Enter a valid mobile number"
      )
      .required("This field is required"),
    job_title: Yup.string().required("This field is required"),
  });
  const handleClickOpen = () => {
    dispatch(openUserDialog());
  };

  const handleClose = () => {
    dispatch(closeUserDailog());
  };

  return {
    FORM_VALIDATION,
    handleClickOpen,
    handleClose,
  };
};

export default useCreateUser;
