/** @format */

import { SET_IS_COMPANY, SET_USERDATA } from "../types/authTypes";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import errorHandle from "../../utils/errorHandle";
import { rootAPI, tenantAPI } from "../../config/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import setAuthToken from "../../utils/setAuthToken";
import useGetSingleUser from "../../pages/settings/user/userHooks/useGetSingleUser";
import { setSingleUser } from "./userAction";
import { getToken } from "firebase/messaging";
import { messaging, requestFcmToken } from "../../constants/firebase";
function setIsCompany(data) {
  return (dispatch) => {
    dispatch({ type: SET_IS_COMPANY, payload: data });
  };
}

//user register func

export const useUserRegister = () => {
  const dispatch = useDispatch();

  const mutation = useMutation(
    async (data) => await rootAPI.post(`/tenants/user/nt/`, data),
    {
      onSuccess: (data, variables) => {
        dispatch(
          setIsCompany({
            uuid: data.data.id,
            password: variables.password,
            pageNo: 2,
          })
        );
        toast.success("User registered successfully.");
      },
      onError: (error) => {
        // Extract error message from response and display in the toast
        const errorMessage =
          error?.response?.data?.email?.[0] ||
          error?.response?.data?.phone?.[0] ||
          "An unexpected error occurred";

        // Show error message in the toast
        toast.error(errorMessage);

        // You can also handle the error globally if needed
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};

// comapny register func

export const useCompanyRegister = () => {
  const dispatch = useDispatch();

  const mutation = useMutation(
    async (data) => {
      return await rootAPI.post(`/tenants/tenant/nt/`, data);
    },
    {
      onSuccess: (data) => {
        dispatch(
          setIsCompany({
            pageNo: 3,
            url: `https://${data?.data?.domain.split(".")[0]}.${
              process.env.REACT_APP_FE_URL
            }/my-crm/log-in`,
          })
        );
        toast.success(data.data.msg);
      },
      onError: (error) => {
        toast.error(error.response.data.schema_name[0], "error");
        errorHandle(error);
      },
    }
  );
  return { mutate: mutation.mutate, isLoading: mutation?.isLoading };
};

// ############ Set user In Local Storage #######
export const useUserData = () => {
  const dispatch = useDispatch();
  const setUserData = (data) => {
    dispatch({ type: SET_USERDATA, payload: data });
  };
  return {
    setUserData,
  };
};

// user login page
export const useUserLogin = () => {
  const navigate = useNavigate();
  const [id, setId] = useState();

  const { data, refetch } = useGetSingleUser({
    enabled: false,
    onSuccess: (data) => {
      console.log(data, "dataUserProfile");
      localStorage.setItem("userData", JSON.stringify(data?.data));
      setSingleUser(data?.data);
      if (data?.data?.data?.profile?.is_active === true) {
        navigate("/");
      } else {
        navigate("/user/profile");
      }
    },
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
    //eslint-disable-next-line
  }, [id]);

  const newData = { ...data?.data, ...data?.data?.profile };
  delete newData["profile"];

  const mutation = useMutation(
    async (data) => await tenantAPI.post(`/accounts/login/nt/`, data),
    {
      onSuccess: async (data) => {
        const fcmToken = await requestFcmToken();
        if (fcmToken) {
          console.log("FCM Token:", fcmToken);

          try {
            await tenantAPI.post(`/tportal/get-fcm-token/`, {
              fcm_token: fcmToken,
            });
            console.log("FCM token successfully sent to backend");
          } catch (error) {
            console.error("Failed to send FCM token to backend:", error);
          }
        }
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("domain", window.location.hostname.split(".")[0]);
        let pref = JSON.stringify(data.data.colPreference);
        localStorage.setItem("columnVisibility", pref);
        setId(data?.data?.uuid);
        setAuthToken(data.data.token);
        toast.setUserData(data, "error");
      },
      onError: (error) => {
        toast.error(error.response.data.msg, "error");
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};
