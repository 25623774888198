import React from "react";

import LocalFireDepartment from "@mui/icons-material/LocalFireDepartment";
import DashboardCard from "../../../components/home/DashboardCard";
import { Divider, IconButton, Typography } from "@mui/material";

import { Phone } from "@mui/icons-material";
import { useNewAndHotDeals } from "../homeHooks/useHomeData";
import PhoneMenu from "../../../components/ivr/PhoneMenu";
import { Link } from "react-router-dom";

function HotDeals() {
  const { data } = useNewAndHotDeals();
  const hotDeals = data?.hotDeals;

  return (
    <DashboardCard
      sx={{
        padding: "15px 20px 15px 13px",
        maxHeight: "400px",
      }}
    >
      <div className="d-flex justify-content-between">
        <span>
          <LocalFireDepartment
            style={{ color: "#FFA500", marginRight: "8px", fontSize: "23px" }}
          />
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
              verticalAlign: "middle",
            }}
          >
            Hot Deals
          </span>
        </span>
        <span style={{ fontSize: "15px", fontWeight: "500" }}>
          {hotDeals?.length}
        </span>
      </div>
      <Divider className="mt-2" />
      <div style={{ height: "350px", overflowY: "auto", paddingRight: "10px" }}>
        {hotDeals?.map((deal) => (
          <Link
            style={{ textDecoration: "none", color: "#000000" }}
            to={`/deals/${deal.uuid}/`}
          >
            <div
              className="d-flex justify-content-between"
              style={{ padding: "10px 0px 0px 13px" }}
            >
              <Typography
                fontSize="13px"
                fontWeight="400"
                component="p"
                className="d-flex align-items-center"
              >
                {deal.name} - {deal.status.stage_name}
              </Typography>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("test");
                }}
              >
                {/* <IconButton
              onClick={(e) => {
                console.log("test");
                e.stopPropagation();
              }}
              size="small"
            >
              <MailOutline style={{ color: "black", fontSize: "13px" }} />
            </IconButton> */}
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{
                padding: "0px 0px 10px 13px",
                borderBottom: "0.5px solid rgba(150, 150, 150, 0.5)",
              }}
            >
              <Typography
                className="second"
                fontSize="11px"
                fontWeight="400"
                component="p"
              >
                {deal.contact.first_name + " " + deal.contact.last_name}
                <br />
                {deal.contact.company_name}
              </Typography>
              <Typography
              fontSize="13px"
                fontWeight="400"
                component="p"
                className="my-auto"
              >
                {deal.valuation}
              </Typography>
              <span
                className="my-auto"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PhoneMenu
                  name={
                    <IconButton size="small">
                      <Phone style={{ color: "black", fontSize: "13px" }} />
                    </IconButton>
                  }
                  phNum={deal.contact.primary_number}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </span>
            </div>
          </Link>
        ))}
      </div>
    </DashboardCard>
  );
}

export default HotDeals;
