import React from "react";

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useField } from "formik";

const Subdomain = ({ name, label, ...otherProps }) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };
  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <FormControl variant='outlined' fullWidth {...configTextfield}>
      <InputLabel htmlFor='outlined-adornment-subdomain'>{label}</InputLabel>
      <OutlinedInput
        id='outlined-adornment-subdomain'
        type='text'
        name={name}
        value={configTextfield.value}
        sx={{
          "paddingRight": "0",
          "margin": "0",
          "& .MuiInputAdornment-positionEnd": {
            width: "auto",
            height: "100%",
            color: mata.touched && mata.error ? "#d32f2f !important" : "#000",
          },
        }}
        endAdornment={
          <InputAdornment position='end'>
            <div
              className='bg-forth d-flex justify-content-center align-items-center px-2'
              style={{
                height: "40px",
                fontSize: "10px",
                fontWeight: "500",
              }}
            >
              .leadmasters.in
            </div>
          </InputAdornment>
        }
        label={label}
      />
      <FormHelperText>
        {mata.touched && mata.error
          ? mata.error
          : `Choose a name for your Lead Masters CRM subdomain. You may consider choosing
        your team or company name.`}
      </FormHelperText>
    </FormControl>
  );
};
// Subdomain({ name: 'test', label: 'lable' });
export default Subdomain;
