import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textField: {
    background: "#f8f9fa",
    "& .MuiFilledInput-root": {
      background: "none",
    },
    "& .MuiInputAdornment-root": {
      width: 80,
    },
    "& .MuiFilledInput-root:after": {
      display: "none",
    },
  },
});

const AutoSelectField = ({
  options,
  name,
  multiple,
  getOptionLabel,
  startAdornment,
  value,
  renderInput,
  isColor,
  ...otherProps
}) => {
  const { textField } = useStyles();

  const { setFieldValue, setTouched, touched, values } = useFormikContext();
  const [field, mata] = useField(name);
  const configTextfield = {
    ...field,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }
  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={(e, value) => {
        console.log(value);
        setFieldValue(name, value);
      }}
      onBlur={() => {
        setTouched({ ...touched, [name]: true });
      }}
      value={values[name]}
      autoSelect
      size="small"
      name={name}
      multiple={multiple}
      renderInput={
        renderInput
          ? renderInput
          : (params) => {
              if (isColor) {
                let inputField = document.getElementById(params.id);
                if (inputField) {
                  inputField.style.backgroundColor = values[name];
                  inputField.style.color = values[name];
                  inputField.style.borderRadius = "3px";
                }
                params.inputProps.value = "";
              }
              return (
                <TextField
                  {...params}
                  hiddenLabel
                  variant="outlined"
                  name={name}
                  className={textField}
                  {...configTextfield}
                  {...otherProps}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        {startAdornment}

                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              );
            }
      }
    />
  );
};

export default AutoSelectField;
