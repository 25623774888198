import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Breadcrumb from "../../formsUI/Breadcrumb";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

const DealHeader = () => {
  const { deal } = useSelector((state) => state.dealStore);

  return (
    <Grid
      container
      display="row"
      className="bg-fifth"
      sx={{
        borderBottom: "2px solid #ced4da",
        py: "20px",
        paddingLeft: "25px",
      }}
    >
      <Box sx={{ width: "100%" }} className="d-flex align-items-center ps-1">
        <Grid
          container
          className="d-flex align-items-center justify-content-between"
        >
          <Grid item xs={6} className="d-flex">
            <Grid item xs={1}>
              <Link
                to="/deals"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                <BiArrowBack />
              </Link>
            </Grid>
            <Grid item xs={5}>
              <Breadcrumb module="Deals" name={deal?.data?.name} />
              {/* <Stack>
                <Typography
                  variant="p"
                  className="second"
                  fontSize="20px"
                  sx={{ fontWeight: "medium" }}
                >
                  {deal?.data?.name}
                </Typography>
              </Stack> */}
            </Grid>

            {/* <Grid className="ms-3">
              <Stack>
                <Typography
                  variant="p"
                  className="second"
                  fontSize="20px"
                  sx={{ fontWeight: "medium" }}
                >
                  <LocalFireDepartmentIcon
                    style={deal?.data?.is_hot ? { color: "#FFA500" } : {}}
                  />
                  style={item?.is_hot ? { color: "FFA500" } : {}}
                </Typography>
              </Stack>
            </Grid> */}
            {/* <Grid className="ms-3">
              <Button
                variant="outlined"
                sx={{
                  color: "#576a77",
                  border: "1px solid #576a77",
                  padding: "3px 5px",
                  fontSize: "12px",
                  "&:hover": {
                    border: "1px solid #576a77",
                  },
                }}
              >
                Edit
              </Button>
            </Grid> */}
          </Grid>

          <Grid item xs={6} className="d-flex justify-content-end pe-5">
            <Box className="d-flex ">
              <Typography
                variant="p"
                className="second me-2"
                fontSize="14px"
                sx={{ fontWeight: "medium" }}
              >
                Expiry Date:
              </Typography>
              <Typography
                variant="p"
                className="second"
                fontSize="14px"
                sx={{ fontWeight: "medium" }}
              >
                {moment(deal?.data?.expected_closing_date).format("DD/MM/YYYY")}{" "}
                &nbsp;
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6} sm={8} md={10} className="align-self-center d-flex">
            <LeadDrower
              name={
                <Button
                  variant="outlined"
                  sx={{
                    color: "#576a77",
                    border: "1px solid #576a77",
                    padding: "3px 5px",
                    fontSize: "12px",
                    "&:hover": {
                      border: "1px solid #576a77",
                    },
                  }}
                >
                  {" "}
                  Edit
                </Button>
              }
              heading="Contact Card"
              data={contact}
              mutate={mutate}
              needRightClickMenu={false}
              needEditButton={true}
            />
            <DeleteDialog
              mutate={deleteContact}
              uuid={params.uuid}
              description="This Contact will be permanantly deleted!"
              needButton={true}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
       
          </Grid> */}
        </Grid>
      </Box>
    </Grid>
  );
};

export default DealHeader;
