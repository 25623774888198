import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import UserDrower from "../../../pages/settings/user/userDrower/index";
import { useUserUpdate } from "../../../redux/actions/userAction";
import getDomain from "../../../utils/getDomain";

const Profileinfo = (data) => {
  const { user } = useSelector((state) => state.userStore);
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  const { mutate } = useUserUpdate();

  return (
    <>
      <Grid item xs={12} sm={4} md={4} sx={{padding:{xs:"1rem",sm:"0rem"}}} className="my-3">
        <Grid>
          <Grid item md={12}>
            <Typography className="mt-3" component="p">
              <span className="first" style={{ fontWeight: 500 }}>
                Full Name :-
              </span>{" "}
              {userData?.full_name}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography className="mt-3" component="p">
              <span className="first" style={{ fontWeight: 500 }}>
                Email :-
              </span>{" "}
              {user?.email}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography className="mt-3" component="p">
              <span className="first" style={{ fontWeight: 500 }}>
                Phone :-
              </span>{" "}
              {userData?.phone}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography className="mt-3" component="p">
              <span className="first" style={{ fontWeight: 500 }}>
                Job Title :-
              </span>{" "}
              {userData?.job_title}
            </Typography>
          </Grid>
          <Grid item md={12} className="text-capitalize mt-5 d-flex">
            <UserDrower
              name={
                <Button className="text-capitalize" variant="contained">
                  Update
                </Button>
              }
              heading="User Card"
              data={userData}
              mutate={mutate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={4} className="my-3" sx={{padding:{xs:"1rem",sm:"0rem"}}}>
        <Grid item md={12}>
          <Typography className="mt-3" component="p"></Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className="mt-3" component="p">
            <span className="first" style={{ fontWeight: 500 }}>
              Designation :-
            </span>{" "}
            Designation
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className="mt-3" component="p">
            <span className="first" style={{ fontWeight: 500 }}>
              Tenant :-
            </span>{" "}
            {getDomain()}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className="mt-3" component="p">
            <span className="first" style={{ fontWeight: 500 }}>
              User Role :-
            </span>{" "}
            User Role
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className="mt-3" component="p"></Typography>
        </Grid>
        {/* <Grid item  md={12} className="text-capitalize mt-5 d-flex">
          <Button className="text-capitalize" variant="contained">
            Change Password
          </Button>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Profileinfo;
