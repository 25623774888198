import React from "react";

import { Divider, Grid } from "@mui/material";
import InputField from "../../../../../components/formsUI/InputField";
import MobileNumberInput from "../../../../../components/formsUI/MobileNumberInput";

const UserlInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>User Info</Divider>
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="full_name"
          label="Full Name *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="email"
          label="Email *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="job_title"
          label="Job Title*"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <MobileNumberInput
          name="phone"
          label="Phone Number *"
          color="#fff"
          border="4px"
        />
      </Grid>

      {/* <Grid item xs={12} >
        <InputField
          name="middle_name"
          label="Middle Name *"
          color="secondary"
          size="small"
        />
      </Grid> */}
      {/* <Grid item xs={12} >
        <InputField
          name="last_name"
          label="Last Name *"
          color="secondary"
          size="small"
        />
      </Grid> */}
      {/* <Grid item xs={12} >
        <MobileNumberInput
          name="secondary_number"
          label="Secondary Number"
          color="#fff"
          border="4px"
        />
      </Grid> */}
      {/* <Grid item xs={12} >
        <MobileNumberInput
          name="work_number"
          label="Work Number"
          color="#fff"
          border="4px"
        />
      </Grid> */}
      {/* <Grid item xs={6}>
        <InputField
          name="password"
          label="Password *"
          color="secondary"
          size="small"
        />
      </Grid> */}
      {/* <Grid item xs={6}>
        <InputField
          name="password2"
          label="Confirm Password*"
          color="secondary"
          size="small"
        />
      </Grid> */}
    </>
  );
};

export default UserlInfo;
