import { useState } from "react";

const useUserDrower = (data, setContextMenu) => {
  const [state, setState] = useState(false);
  const toggleDrawer = (val) => {
    setState(!state);
  };

  const INITIAL_FORM_STATE = {
    id: data?.user ? data?.user : "",
    email: data?.email ? data?.email : "",
    password: data?.password ? data?.password : "",
    password2: data?.password2 ? data?.password2 : "",
    full_name: data?.full_name ? data?.full_name : "",
    phone: data?.phone ? data?.phone : "",
    job_title: data?.job_title ? data?.job_title : "",
  };

  return {
    state,
    INITIAL_FORM_STATE,
    toggleDrawer,
  };
};

export default useUserDrower;
