import { useQuery } from "react-query";
import {tenantAPI} from "../../../../config/api"

const useFetchCredentials = (endpoint) => {
  const fetchConfig = async () => {
    const response = await tenantAPI.get(endpoint); 
    return response.data; 
  };

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["getCreds", endpoint],
    fetchConfig, 
    {
      refetchOnWindowFocus: false, 
      retry: false, 
      select: (data) => data[0], 
    }
  );

  return { data, isLoading, isError, error, refetch };
};

export default useFetchCredentials;
