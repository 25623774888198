import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { tenantAPI } from "../../../../config/api";
import errorHandle from "../../../../utils/errorHandle";
import useGetAll from "../../../../hooks/useGetAll";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import CustomDialog from "../../../../components/Layout/customDialog/CustomDialog";
import StageForm from "./StageForm";
import { MdOutlineDeleteForever, MdOutlineMode } from "react-icons/md";
import DeleteForm from "./StageForm/DeleteForm";
import { useState } from "react";

const useDealPipeline = () => {
  const [dealsData, setDealsData] = useState([]);
  const { data, isLoading, refetch } = useGetAll(`/deals/deal_stage/0/`, {
    select: (data) => {
      return {
        data: data?.data?.data,
        count: data?.data?.count,
      };
    },
    onSuccess: (data) => setDealsData(data?.data),
  });

  const INITIAL_FORM_STATE = {
    order: dealsData ? dealsData[dealsData?.length - 1]?.order + 1 : "",
    stage_name: "",
    colour: "",
    probability: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    order: Yup.number().positive().required("This Field is Required"),
    colour: Yup.mixed().required("This Field is Required"),
    stage_name: Yup.string().required("This Field is Required"),
    probability: Yup.number()
      .positive()
      .max(100, "Percentage should not exceed 100")
      .required("This Field is Required"),
  });

  const CreateStage = async (data) => {
    return await tenantAPI.post(`/deals/deal_stage/0/`, data);
  };

  const Mutation = useMutation(CreateStage, {
    onSuccess: () => {
      toast.success(`Stage created`);
      refetch();
    },
    onError: (err) => {
      errorHandle(err);
    },
  });

  const UpdateStage = async (data) => {
    return await tenantAPI.put(`/deals/deal_stage/${data?.id}/`, data);
  };

  const UpdateMutation = useMutation(UpdateStage, {
    onSuccess: () => {
      toast.success(`Stage updated`);
      refetch();
    },
    onError: (err) => {
      errorHandle(err);
    },
  });

  const DeleteStage = async (data) => {
    if (!data?.stage_op) {
      throw new Error(`Please select an option!`);
    }

    data["stage_op"] = parseInt(data?.stage_op);
    return await tenantAPI.post(`/deals/deal_stage_delete2/`, data);
  };

  const DeleteMutation = useMutation(DeleteStage, {
    onSuccess: () => {
      toast.success(`Stage deleted`);
      refetch();
    },
    onError: (err) => {
      errorHandle(err);
    },
  });

  const UpdateOrder = async (data) => {
    return await tenantAPI.post(`/deals/deal_stage_order_swap/`, data);
  };

  const OrderMutation = useMutation(UpdateOrder, {
    onSuccess: () => {
      toast.success(`Stage Order updated`);
      refetch();
    },
    onError: (err) => {
      errorHandle(err);
    },
  });

  const ChangeOrder = (id, action) => {
    OrderMutation.mutate({
      id: id,
      action: action,
    });
  };

  const columns = [
    {
      field: "order",
      headerName: "Order",
      headerAlign: "center",
      minWidth: 100,
      cellClassName: "p-0 text-center",
      flex: 1,
    },
    {
      field: "stage_name",
      headerName: "Stage Name",
      minWidth: 150,
      headerClassName: "text-capitalize",
      cellClassName: "p-0 ps-3",
      flex: 3,
    },
    {
      field: "probability",
      headerName: "Probability",
      minWidth: 150,
      cellClassName: "p-0 ps-3",
      flex: 1,
    },
    {
      field: "colour",
      headerName: "Color",
      cellClassName: "p-2",
      minWidth: 150,
      renderCell: (params) => (
        <div
          style={{
            height: "100%",
            width: "100%",
            background: `${params?.row?.colour}`,
            border: "1px solid black",
            borderRadius: "3px",
          }}
        ></div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      headerAlign: "center",
      headerClassName: "text-capitalize",
      cellClassName: "d-flex justify-content-around",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.order !== 1 ? (
              <Tooltip title="Increment Order">
                {!OrderMutation?.isLoading ? (
                  <IconButton
                    onClick={() => ChangeOrder(params?.row?.uuid, "incr")}
                  >
                    <BsArrowUpShort />
                  </IconButton>
                ) : (
                  <CircularProgress size={18} />
                )}
              </Tooltip>
            ) : (
              <span style={{ width: "37px" }}></span>
            )}
            {params?.row?.order !== data?.length ? (
              <Tooltip title="Decrement Order">
                {!OrderMutation?.isLoading ? (
                  <IconButton
                    onClick={() => ChangeOrder(params?.row?.uuid, "decr")}
                  >
                    <BsArrowDownShort />
                  </IconButton>
                ) : (
                  <CircularProgress size={18} />
                )}
              </Tooltip>
            ) : (
              <span style={{ width: "37px" }}></span>
            )}
            <CustomDialog
              mutate={UpdateMutation.mutate}
              heading="Edit Stage"
              button={
                <Tooltip title="Edit Stage">
                  <IconButton sx={{ padding: "4px" }}>
                    <MdOutlineMode />
                  </IconButton>
                </Tooltip>
              }
              closeButton="CLOSE"
              addButton="UPDATE"
              initialValues={{
                id: params?.row?.uuid,
                order: params?.row?.order,
                stage_name: params?.row?.stage_name,
                colour: params?.row?.colour,
                probability: params?.row?.probability,
              }}
              validationSchema={FORM_VALIDATION}
            >
              <StageForm isUpdate />
            </CustomDialog>
            <CustomDialog
              mutate={DeleteMutation.mutate}
              heading="Delete Stage"
              button={
                <Tooltip title="Delete Stage">
                  <IconButton sx={{ padding: "4px" }}>
                    <MdOutlineDeleteForever />
                  </IconButton>
                </Tooltip>
              }
              closeButton="CLOSE"
              addButton="DELETE"
              initialValues={{
                stage_id: params?.row?.uuid,
                stage_op: "",
              }}
            >
              <DeleteForm
                order={
                  params?.row?.order === 1
                    ? "decr"
                    : params?.row?.order === dealsData?.length
                    ? "incr"
                    : "all"
                }
              />
            </CustomDialog>
          </>
        );
      },
    },
  ];

  return {
    data: dealsData,
    isLoading,
    columns,
    INITIAL_FORM_STATE,
    FORM_VALIDATION,
    mutate: Mutation.mutate,
  };
};

export default useDealPipeline;

// {
//   first: {order:1,id:12312321}
//   second: {order:2,id: 23142134}
// }
