import React from "react";

import { TextField } from "@mui/material";

const InputFieldNf = ({
  name,
  label,
  className,
  required,
  min,
  value,
  ...otherProps
}) => {
  return (
    <TextField
      className={className}
      handleKeyDown={(e) => {
        console.log(e, "keyyyy");
        e.preventDefault();
        e.stopPropagation();
      }}
      size="small"
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      required={required}
      min={min}
      value={value}
      {...otherProps}
    />
  );
};

export default InputFieldNf;
