import { tenantAPI } from "../config/api";

const setAuthToken = (token) => {
  if (token) {
    tenantAPI.defaults.headers.common["x-access-token"] = token;
  } else {
    delete tenantAPI.defaults.headers.common["x-access-token"];
  }
};

export default setAuthToken;
