import React, { useEffect } from "react";

import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import Checkboxes from "../../../../components/formsUI/CheckBox";
import SearchAppBar from "../../../../components/Layout/navbar/Search";
import Table from "../../../../components/Layout/table";
import useHomeUser from "./useHomeUser";
import useCreateUser from "../newUser/useCreateUser";

import { useHotkeys } from "react-hotkeys-hook";
import CreateNewUser from "../newUser";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';


// const row = [
//   {
//     id: 1,
//     full_name: "Yasin",

//     email: "Yasin@gmail.com",
//     job_title: "Developer",
//   },
//   {
//     id: 2,
//     full_name: "Aatif",

//     email: "aatif@gmail.com",
//     job_title: "Manager",
//   },
//   {
//     id: 3,

//     full_name: "Tabish",
//     email: "tabish@gmail.com",
//     job_title: "Backend Devloper",
//   },
// ];

const Users = () => {
  const below777 = useMediaQuery("(max-width:777px)");
  const matches = useMediaQuery("(max-width:600px)");
  const { isLoading, createColumnSchema, data } = useHomeUser();
  const tableRef = useGridApiRef();
  useEffect(() => {
    document.title = "All User | CRM";
  }, []);

  const OnExport = () => {
    tableRef?.current?.exportDataAsCsv();
  };
  return (
    <>
      <Grid container className="p-2 px-3">
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            Users
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "1rem" }} className="mt-2" component="p">
            Manage Users and Users forms
          </Typography>
        </Grid>
        <Grid
          container
          className="d-flex align-items-center justify-content-between mt-3 "
        >
          <Grid
            item
            xs={12}
            className="d-flex align-items-center justify-content-between"
          >
            <Grid item className="d-flex align-items-center">
              <Checkboxes />
              <SearchAppBar />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                className="me-2"
                onClick={() => OnExport()}
                sx={ below777 ? { marginBottom:"1rem"} : {marginBottom : "0rem"}}
              >
                { matches ? <IosShareRoundedIcon/> : "Export to CSV" }
                
              </Button>
              <CreateNewUser />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className="px-3">
        <Table
          className="p-2"
          style={{ width: "100%" }}
          mt={"30px"}
          apiRef={tableRef}
          // createColumnSchema={createColumnSchema}
          columns={data && data[0] ? createColumnSchema(data[0]) : []}
          data={data ? data : []}
          // data={row}

          loading={isLoading}
        />
      </Grid>
    </>
  );
};

export default Users;
