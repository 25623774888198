import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import SingleTemplate from "./SingleTemplate";
import useGetTemplates from "../hooks/useGetTemplates";
import TemplateCardActionArea from "./TemplateCardActionArea";

// const allData = [
//   {
//     id: "62f600551567242205d2bb53",
//     elementName: "nhitest",
//     category: "ACCOUNT_UPDATE",
//     hsm: null,
//     hsmOriginal: null,
//     customParams: [
//       {
//         paramName: "name",
//         paramValue: "Nhi",
//       },
//     ],
//     status: "APPROVED",
//     language: {
//       key: "English (US)",
//       value: "en_US",
//       text: "English (US)",
//     },
//     lastModified: "2022-08-12T10:47:34.325Z",
//     type: "template",
//     header: null,
//     body: "Dear {{1}},\nThank you for your time. ",
//     bodyOriginal: "Dear {{name}},\nThank you for your time. ",
//     footer: null,
//     buttons: null,
//     buttonsType: "none",
//   },
// ];

const TemplatesDialog = ({
  dialogOpen,
  handleClickClose,
  message,
  setMessage,
}) => {
  const { data } = useGetTemplates();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={dialogOpen}
        aria-labelledby="scroll-Dialog-title"
        aria-describedby="scroll-Dialog-content"
      >
        <DialogTitle
          sx={{
            background: "#ededed",
            position: "sticky",
            top: "0",
            right: "0",
            zIndex: 10,
          }}
          className="border-bottom border-dark shadow-sm d-flex justify-content-between"
          id="scroll-Dialog-title"
        >
          Choose Template
          <IconButton onClick={handleClickClose}>
            <IoMdClose />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <div className="py-3 px-2 border-bottom"> */}
          {data?.map((template) => (
            <SingleTemplate
              message={message}
              setMessage={setMessage}
              template={template}
            >
              <TemplateCardActionArea template={template} />
            </SingleTemplate>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TemplatesDialog;
