import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import InputFieldNf from "../../../../formsUI/withoutFormik/InputFieldNf";
import SmsTemplate from "./SmsTemplate";
import { useSelector } from "react-redux";
import AutocompleteNf from "../../../../formsUI/withoutFormik/AutoCompleteNf";
import TemplateDialog from "./TemplateDialog";

const ComposeSMS = () => {
  const { contact } = useSelector((state) => state.contactStore);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [template, setTemplate] = useState();

  const [parameters, setParameters] = useState();
  let bodyBreakup = template?.body?.split(/[{{}}]+/);
  const handleClickClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    let params = {};
    template?.customParams?.forEach((param) => {
      params[param.paramName] = param.paramValue;
    });
    if (Object.keys(params).length !== 0) setParameters(params);
  }, [template]);

  console.log(bodyBreakup, parameters);

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction="row" className="mb-2">
        <Grid
          item
          xs={4}
          sm={1}
          md={1}
          className="d-flex align-items-center ps-2"
        >
          <Typography color="black">To:</Typography>
        </Grid>
        <Grid item xs={8} sm={9} md={9} className="d-flex align-items-center">
          <AutocompleteNf
            name="to"
            width="100%"
            id="tags-standard"
            variant={"standard"}
            getOptionLabel={(options) => options.label || ""}
            options={[
              {
                label: "Primary Number",
                value: contact?.primary_number,
              },
              {
                label: "Secondary Number",
                value: contact?.secondary_number,
              },
              {
                label: "Work Number",
                value: contact?.work_number,
              },
            ]}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <Button
            onClick={() => setDialogOpen(true)}
            className="text-capitalize ms-2"
            size="small"
          >
            Template
          </Button>
        </Grid>
        <TemplateDialog
          dialogOpen={dialogOpen}
          handleClickClose={handleClickClose}
        >
          <SmsTemplate
            template={template}
            setTemplate={setTemplate}
            handleClickClose={handleClickClose}
          />
        </TemplateDialog>
      </Grid>

      {template?.id !== 0 && bodyBreakup?.length > 0 && (
        <div className="border px-2 py-3">
          <Typography fontSize={14} style={{ whiteSpace: "pre-line" }}>
            {bodyBreakup?.map((chunk, index) => {
              if (index % 2 === 0) return chunk;
              else
                return (
                  <span style={{ color: "blue" }}>
                    {parameters && parameters[chunk]}
                  </span>
                );
            })}
          </Typography>
        </div>
      )}

      {template?.id === 0 ? (
        <InputFieldNf
          id="filled-hidden-label-small"
          fullWidth={true}
          variant="standard"
          placeholder="Type your message here..."
          multiline
          rows={5}
          className="mt-2"
          name="body"
        />
      ) : (
        <>
          {parameters &&
            Object.keys(parameters).map((key) => (
              <div className="my-3">
                <Typography
                  fontSize={12}
                  fontWeight={500}
                >{`Custom Field {{${key}}}`}</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    e.target.value
                      ? setParameters({
                          ...parameters,
                          [key]: e.target.value,
                        })
                      : setParameters({
                          ...parameters,
                          [key]: `{{${key}}}`,
                        });
                  }}
                  placeholder={parameters[key]}
                ></TextField>
              </div>
            ))}
        </>
      )}

      <div className="d-flex justify-content-between mt-2 ">
        <Button
          variant="outlined"
          size="small"
          style={{
            // paddingInline: "15px",
            paddingBlock: "0px",
          }}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          type="submit"
          className="text-capitalize bg-first fifth px-3 py-1 ms-auto d-block "
          style={{
            paddingInline: "15px",
            paddingBlock: "0px",
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default ComposeSMS;
