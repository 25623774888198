/** @format */

import React, { useEffect, useRef, useState } from "react";

import Container from "../../../components/Layout/Container";

import Header from "../../../components/Layout/Header";
import Table from "../../../components/Layout/table";

import useTaskTable from "./useTaskTable";
import { useDispatch } from "react-redux";
import { openContactDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import useTaskData from "../taskHooks/useTaskData";
import CustomDialog from "../../../components/Layout/customDialog/CustomDialog";
import AddTaskForm from "../../../components/deal/dealAccordion/accordionChildren/task/AddTaskForm";
import { IconButton } from "@mui/material";
import { MdAdd } from "react-icons/md";
import useAddTask from "../../../components/deal/dealAccordion/accordionChildren/task/useAddTask";
import { useCreateTask } from "../../../redux/actions/dealAction";
import { useParams } from "react-router-dom";

const TaskTable = () => {
  const { createColumnSchema } = useTaskTable();
  const params = useParams();

  const [taskData, setTaskData] = useState([]);
  const { TASK_INITIAL_FORM_STATE, TASK_FORM_VALIDATION } = useAddTask();
  const { mutate: addTask } = useCreateTask(params.uuid);

  const { isLoading } = useTaskData({
    onSuccess: (data) => setTaskData(data?.data),
  });

  const tableRef = useRef();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    <CustomDialog
      closeButton='Close'
      addButton='Add'
      // styles={taskAccordion ? dialogButtonStylesOpen : dialogButtonStyles}
      mutate={addTask}
      button={
        <IconButton>
          <MdAdd />
        </IconButton>
      }
      maxWidth='md'
      initialValues={TASK_INITIAL_FORM_STATE}
      validationSchema={TASK_FORM_VALIDATION}
      heading='Add Task'
    >
      <AddTaskForm />
    </CustomDialog>;
  };

  const handleExportCSV = () => {
    if (tableRef.current) {
      tableRef.current.exportCSV(); // Ensure you have the exportCSV method in Table
    }
  };
  useEffect(() => {
    document.title = "All Contacts | CRM";
  }, []);

  return (
    <>
      <Header
        name='Task'
        showSearch={true}
        exportButton
        exportOnClick={handleExportCSV}
        addButton
        customFilter
      />
      <Container sx={{ marginTop: "30px" }}>
        <Table
          mt={"30px"}
          title='task'
          // tableTitle='task'
          data={taskData ? taskData : []}
          ref={tableRef}
          loading={isLoading}
          columns={
            taskData && taskData[0]
              ? createColumnSchema(taskData[0])
              : leadAndContactColumns
          }
        />
      </Container>
    </>
  );
};

export default TaskTable;
