/** @format */

import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";

import { SET_DEAL_DATA, SET_SINGLE_DEAL } from "../types/dealTypes";
import { tenantAPI } from "../../config/api";
import errorHandle from "../../utils/errorHandle";
import { useParams } from "react-router-dom";
import { createAddress } from "./leadActions";
import { closeLeadDialog } from "./LayoutAction";
import { useDispatch, useSelector } from "react-redux";
import download from "downloadjs";

export const setDealData = (data) => (dispatch) => {
  dispatch({ type: SET_DEAL_DATA, payload: data });
};

export const setSingleDeal = (data) => (dispatch) => {
  dispatch({ type: SET_SINGLE_DEAL, payload: data });
};

// convert to deal
export const useConvetToDealUpdate = (isAddDeal, CreateDeal) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data) => {
      if (!data.name) {
        throw new Error("Enter a name for the deal");
      }
      if (!data.owner) {
        throw new Error("Please select an owner for the deal");
      }
      if (!data?.contacts.length) {
        throw new Error("Please select atleast one contact");
      }
      if (!data.status) {
        throw new Error("Please select a deal stage");
      }
      let formData = {
        ...data,

        products: data?.product?.map((item) => {
          if (!item.product || !item?.quantity) {
            throw new Error(
              "Please select product and quantity in all inputs!"
            );
          }
          return { uuid: item?.product?.uuid, quantity: item?.quantity };
        }),
        contacts: data?.contacts?.map((item, index) => {
          let is_primary = index === 0 ? true : false;
          return { uuid: item?.uuid, is_primary: is_primary };
        }),
        owner: data?.owner?.uuid,
        expected_closing_date: moment(data?.expected_closing_date).format(
          "YYYY-MM-DD"
        ),
        status: data?.status.uuid,
      };
      return await tenantAPI.post(`/deals/0/`, formData);
    },
    {
      onSuccess: () => {
        if (isAddDeal || CreateDeal) {
          queryClient.refetchQueries(["deals"], { exact: true });
          toast.success("Deal created successfully!");
        } else {
          queryClient.refetchQueries(["contacts"], { exact: true });
          toast.success("Contact converted to Deal successfully!");
        }
      },
      onError: (error) => {
        errorHandle(error);
      },
    }
  );
  return { mutate: mutation.mutate };
};

// ########## Update Deals ###########
export const useUpdateDeal = (id) => {
  const queryClient = useQueryClient();

  const updateDeal = async (data) => {
    let newData = {
      ...data,
    };

    return await tenantAPI.put(`/deals/deal_update/${id}/`, newData);
  };

  const mutation = useMutation(updateDeal, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Deal Updated successfully");
    },
    onError: (error) => {
      if (error.response) {
        errorHandle(error);
      }
    },
  });
  return { mutate: mutation.mutate };
};

// ############## Task #############
export const useCreateTask = (id) => {
  const queryClient = useQueryClient();
  const params = useParams();

  const createNewTask = async (data) => {
    data.due_date = moment(data?.due_date).format("YYYY-MM-DD");
    data.reminder = moment(data?.reminder).format();
    let newData = {
      ...data,
      related_to: params.uuid,
      Deal: params.uuid,
      task_priority: data?.task_priority?.uuid,
      assigned_to: data?.assigned_to?.uuid,
    };
    return await tenantAPI.post(`/deals/add_task/0/`, newData);
  };

  const mutation = useMutation(createNewTask, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Task Added successfully!");
    },
    onError: (err) => errorHandle(err),
  });

  return { mutate: mutation.mutate };
};

// ############ update Task ##############
export const useTaskUpdate = (id) => {
  const params = useParams();
  const queryClient = useQueryClient();

  const upadteTask = async (data) => {
    data.due_date = moment(data?.due_date).format("YYYY-MM-DD");
    data.reminder = moment(data?.reminder).format();
    let newData = {
      ...data,
      related_to: params.uuid,
      Deal: params.uuid,
      task_priority: data?.task_priority?.uuid,
      assigned_to: data?.assigned_to?.uuid,
      is_completed: data?.is_completed,
    };
    return await tenantAPI.put(`/deals/add_task/${id}/`, newData);
  };

  const mutation = useMutation(upadteTask, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", params.uuid], { exact: true });
      toast.success("Task Updated successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

// ########### Delete Task ###############
export const useTaskDelete = (id) => {
  const queryClient = useQueryClient();

  const deleteTask = async (uuid) => {
    return await tenantAPI.delete(`/deals/add_task/${uuid}`);
  };

  const mutation = useMutation(deleteTask, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Task deleted successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

// ############## Contact #############

export const useCreateNewConatct = (owner) => {
  const params = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewContact = async (data) => {
    createAddress(data);
    console.log(data, "<===========company_name");
    data.owner = `${owner}`;
    data.deal = params.uuid;
    return await tenantAPI.post(`deals/deal_addcontact/0/`, data);
  };

  const mutation = useMutation(createNewContact, {
    onSuccess: async (data) => {
      toast.success("Contact Created Successfully.");
      dispatch(closeLeadDialog());
      queryClient.refetchQueries(["contacts"], { exact: true });
      queryClient.refetchQueries(["deals"], { exact: true });
      queryClient.refetchQueries(["deal", params.uuid], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

// ############# Quote Create ##############
export const useCreateQuote = (id) => {
  const queryClient = useQueryClient();
  const params = useParams();

  const createNewQuote = async (data) => {
    let formData = new FormData();
    formData.append("deal", params.uuid);
    formData.append(
      "expiry_date",
      moment(data?.expiry_date).format("YYYY-MM-DD")
    );
    formData.append("quote_title", data.quote_title);
    formData.append("terms_and_condition", data.terms_and_condition);
    formData.append("quote_file", data.quote_file);
    formData.append("contact", data.contact.uuid);
    formData.append("discount", data.discount);

    return await tenantAPI.post(`/deals/deal_quote/0/`, formData);
  };

  const mutation = useMutation(createNewQuote, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Quote Added successfully!");
    },
    onError: (err) => errorHandle(err),
  });
  return { mutate: mutation.mutate };
};

// ############### Update Quote #############
export const useQuoteUpdate = (id) => {
  const params = useParams();
  const queryClient = useQueryClient();

  const updateQuote = async (data) => {
    let formData = new FormData();
    formData.append("deal", params.uuid);
    formData.append(
      "expiry_date",
      moment(data?.expiry_date).format("YYYY-MM-DD")
    );
    formData.append("quote_title", data.quote_title);
    formData.append("terms_and_condition", data.terms_and_condition);
    formData.append("quote_file", data.quote_file);
    formData.append("contact", data.contact.uuid);
    formData.append("discount", data.discount);
    return await tenantAPI.put(`/deals/deal_quote/${id}/`, formData);
  };
  const mutation = useMutation(updateQuote, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Quote Updated successfully!");
    },
    onError: (err) => errorHandle(err),
  });
  return { mutate: mutation.mutate };
};

// ############# Quote Download ##########
export const useDownloadQuote = (data) => {
  const { deal } = useSelector((state) => state.dealStore);
  console.log();
  const downloadQuote = async () => {
    return await tenantAPI.get(
      `/deals/deal_quote_pdf/${data?.contact}/${data?.quote}/${data?.domain}`,
      { responseType: "blob" }
    );
  };

  const mutation = useMutation(downloadQuote, {
    onSuccess: (data) => {
      // const blob = new Blob([data.data], {
      //   type: "application/pdf; charset=utf-8",
      // });
      // const fileURL = window.URL.createObjectURL(blob);
      // let alink = document.createElement("a");
      // alink.href = fileURL;
      // alink.download = "Quote.pdf";
      // alink.click();
      if (data?.data) {
        download(
          new Blob([data?.data]),
          `${deal?.data?.name}.pdf`,
          "application/pdf"
        );
      }

      // console.log(file);
      toast.success("PDF Downloaded successfully!");
    },
    onError: (err) => errorHandle(err),
  });
  return { mutate: mutation.mutate, downloadLoading: mutation.isLoading };
};

// #############Quote Email Send ############

const sendQuotePdfAPI = async ({ contact, quote, domain }) => {
  const response = await tenantAPI.get(
    `/deals/send_quote_pdf/${quote}/${domain}/`
  );

  return response;
};

export const useSendQuotePdf = () => {
  const mutation = useMutation(
    ({ contact, quote, domain }) => sendQuotePdfAPI({ contact, quote, domain }),
    {
      onSuccess: (data) => {
        toast.success("PDF sent successfully:", data);
      },
      onError: (error) => {
        toast.error("Error sending PDF:", error.message);
      },
    }
  );

  return { mutate: mutation.mutate, shareLoading: mutation.isLoading };
};
// ############# Product ##############
export const useCreateProduct = (id, valuation) => {
  const params = useParams();
  const queryClient = useQueryClient();

  const createNewProduct = async (data) => {
    let formData = {
      deal: params.uuid,
      valuation: valuation,
      products: data?.map((item) => {
        valuation += item.quantity * item?.product?.base_price;
        return { uuid: item.uuid, quantity: item?.quantity };
      }),
    };
    return await tenantAPI.post(`/deals/deal_product/0/`, formData);
  };

  const mutation = useMutation(createNewProduct, {
    onSuccess: async () => {
      queryClient.refetchQueries(["deal", id], { exact: true });
      toast.success("Product Added successfully!");
    },
    onError: (err) => errorHandle(err),
  });
  return { mutate: mutation.mutate };
};

// ########### Delete Product ###############
export const useProductDelete = (data) => {
  const queryClient = useQueryClient();
  const deleteProduct = async () => {
    console.log(data, "producttt");
    return await tenantAPI.delete(`/deals/deal_product/0/`, { data: data });
  };

  const mutation = useMutation(deleteProduct, {
    onSuccess: () => {
      queryClient.refetchQueries(["deal", data?.deal], { exact: true });
      toast.success("Product deleted successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};
