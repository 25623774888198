import React, { useEffect, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";

const DropDown = ({ button, children, onMouseOver, ...otherMenuProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ref = useRef();
  const { leadDialog } = useSelector((state) => state.layoutStore);
  useEffect(() => {
    if (!leadDialog) {
      setAnchorEl(null);
    }
  }, [leadDialog]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {React.cloneElement(button, { onClick: handleClick, ref })}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...otherMenuProps}
      >
        {children}
      </Menu>
    </React.Fragment>
  );
};

export default DropDown;
