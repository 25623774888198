import { useQuery } from "react-query";
import { tenantAPI } from "../../../../config/api";

const getSingleUser = async () => {
  return await tenantAPI.get(`/accounts/profile/`);
};

const useGetSingleUser = (options = {}) => {
  return useQuery(["user"], () => getSingleUser(), {
    ...options,
  });
};

export default useGetSingleUser;
