import React, { useRef } from "react";

import JoditEditor from "jodit-react";
import "./Editor.css";

import { useFormikContext } from "formik";

const TextEditor = ({ name }) => {
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttonsMD: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "fontsize",
      "align",
      "image",
      "table",
      "link",
    ],
    buttonsSM: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "fontsize",
      "align",
      "image",
      "table",
      "link",
    ],
    buttonsSX: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "fontsize",
      "align",
      "image",
      "table",
      "link",
    ],
    style: {
      // background: "#F8F9FA",
      border: "none",
      borderBottom: "1px solid rgb(123, 123, 123)",
    },
    height: 250,
    allowTabNavigation: true,
    saveModeInStorage: true,
    showTooltip: true,
    toolbarAdaptive: true,
    spellcheck: true,
    statusbar: false,
    enter: "br",
    allowResizeY: false,
  };

  const { setFieldValue, values, errors } = useFormikContext();
  const editor = useRef(null);

  return (
    <>
      <JoditEditor
        ref={editor}
        value={values[name]}
        tabIndex={1} // tabIndex of textarea
        config={config}
        onBlur={(newContent) => setFieldValue(name, newContent)} // preferred to use only this option to update the content for performance reasons
      />
      {errors.body ? (
        <span className="text-danger pb-3 ps-3" style={{ fontSize: "12px" }}>
          {errors.body}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default TextEditor;
