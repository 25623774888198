import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { combineReducers } from "redux";
import { leadReducer } from "../redux/reducer/leadReducers";
import { layoutReducer } from "../redux/reducer/layoutReducer";
import { accountReducer } from "../redux/reducer/accountReducer";
import { contactReducer } from "../redux/reducer/contactReducer";
import { authReducer } from "../redux/reducer/authReducer";
import { productReducer } from "../redux/reducer/productReducer";
import { userReducer } from "../redux/reducer/userReducer";
import { dealReducer } from "../redux/reducer/dealReducer";
import { commonReducer } from "../redux/reducer/commonReducer";

const reducer = combineReducers({
  leadStore: leadReducer,
  layoutStore: layoutReducer,
  contactStore: contactReducer,
  accountStore: accountReducer,
  authStore: authReducer,
  productStore: productReducer,
  userStore: userReducer,
  dealStore: dealReducer,
  commonStore: commonReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
