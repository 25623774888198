/** @format */

import { SET_TASK_DATA, SET_SINGLE_TASK } from "../types/taskTypes";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { tenantAPI } from "../../config/api";
import errorHandle from "../../utils/errorHandle";
import { closeTaskDialog } from "./LayoutAction";
import { useNavigate } from "react-router-dom";

// Set multiple tasks data in the Redux store
export const setTaskData = (data) => (dispatch) => {
  dispatch({ type: SET_TASK_DATA, payload: data });
};

// Set a single task in the Redux store
export const setSingleTask = (data) => (dispatch) => {
  dispatch({ type: SET_SINGLE_TASK, payload: data });
};

// Hook to convert lead to task (similar to converting a lead to a contact)
export const useConvertToTask = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (data) => {
      return await tenantAPI.post(`/tasks/0/`, {
        lead_uuid: data.uuid,
        owner: data.owner,
      });
    },
    {
      onSuccess: (data) => {
        navigate(`/tasks/${data?.data?.data?.uuid}`, { replace: true });
        queryClient.refetchQueries(["leads"], { exact: true });
        toast.success(data.data.Success);
      },
      onError: (error) => {
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};

// Hook to create a new task manually
export const useCreateNewTask = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewTask = async (data) => {
    // Assuming tasks need some address data or similar preprocessing
    // Add preprocessing logic if required
    return await tenantAPI.post(`/tasks/0/`, data);
  };

  const mutation = useMutation(createNewTask, {
    onSuccess: async () => {
      toast.success("Task created successfully");
      dispatch(closeTaskDialog());
      queryClient.refetchQueries(["tasks"], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

// Hook to update an existing task
export const useUpdateTask = (id, successMessage) => {
  const queryClient = useQueryClient();

  const updateTask = async (data) => {
    if (data?.owner) data.owner = data?.owner?.uuid;
    if (data?.company) data.company = data?.company?.uuid;
    // Add any necessary preprocessing here, e.g., handling addresses
    return await tenantAPI.put(`/tasks/${id}/`, data);
  };

  const mutation = useMutation(updateTask, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["task", id], { exact: true });
      toast.success(
        data?.data?.Success || successMessage || "Task Updated Successfully"
      );
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate, isLoading: mutation.isLoading };
};

// Hook to delete a task
export const useTaskDelete = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteTask = async (uuid) => {
    return await tenantAPI.delete(`/tasks/${uuid}/`);
  };

  const mutation = useMutation(deleteTask, {
    onSuccess: () => {
      queryClient.refetchQueries(["tasks"], { exact: true });
      toast.success("Task deleted successfully.");
      navigate("/tasks", { replace: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};
