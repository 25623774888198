/** @format */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  MenuItem,
  DialogActions,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { PublishedWithChanges } from "@mui/icons-material";
import {
  useConvetToDealUpdate,
  useUpdateDeal,
} from "../../../redux/actions/dealAction";
import useConvertToDeal from "./useConvertToDeal";
import { useEffect, useState } from "react";
import AutoCompleteProduct from "../../../components/deal/AutoCompleteProduct";
import { makeStyles } from "@mui/styles";
import useContactData from "../../contact/contactHooks/useContactData";
import MultiAutoCompleteNf from "../../../components/formsUI/withoutFormik/MultiAutoCompleteNf";
import InputFieldNf from "../../../components/formsUI/withoutFormik/InputFieldNf";
import SelectDateNf from "../../../components/formsUI/withoutFormik/SelectDateNf";
import CheckboxLabels from "../../../components/formsUI/CheckBoxLabel";
import AutoSelectFieldNf from "../../../components/formsUI/withoutFormik/AutoCompleteNf";
import useDealStage from "../dealHooks/useDealStage";
import useUserData from "../../settings/user/userHooks/useAddUser";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineModeEdit } from "react-icons/md";

const useStyles = makeStyles({
  root: {
    "& .css-ctjx17-MuiPaper-root-MuiDialog-paper": {
      maxWidth: "none",
      width: "800px",
    },
  },
});

const ConvertToDeal = ({
  handleClose,
  contact,
  needButton,
  fixedStage,
  addButton,
  CreateDeal,
  isUpdate,
}) => {
  let { deal } = useSelector((state) => state.dealStore);
  deal = deal?.data;
  const { root } = useStyles();
  const { mutate } = useConvetToDealUpdate(fixedStage, CreateDeal);
  const { mutate: updateDeal } = useUpdateDeal(deal?.uuid);

  const { data } = useContactData();
  const { data: deal_stage } = useDealStage();
  const { data: userList } = useUserData();
  const { user } = useSelector((state) => state.userStore);

  const [formData, setFormData] = useState({});

  const { open, handleClickOpen, handleCtoCClose } =
    useConvertToDeal(handleClose);

  const setInitialState = () => {
    setFormData(
      isUpdate
        ? {
            name: deal?.name,
            expected_closing_date: deal?.expected_closing_date,
            description: deal?.description,
            is_hot: deal?.is_hot,
            proposed_value: deal?.proposed_value,
          }
        : {
            valuation: "",
            product: [
              {
                id: Date.now(),
                product: "",
                quantity: 1,
              },
            ],
            contacts: contact ? [contact] : [],
            name: "",
            expected_closing_date: Date.now(),
            description: "",
            is_hot: false,
            status: fixedStage ? fixedStage : "",
            proposed_value: "",
            owner: user,
          }
    );
  };

  const handleLeadChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.stage_name,
    }));
  };

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setInitialState();
  }, [fixedStage, contact]);

  const addProduct = () => {
    setFormData((prev) => ({
      ...prev,
      product: [
        ...prev.product,
        {
          id: Date.now(),
          product: "",
          quantity: 1,
        },
      ],
    }));
  };

  const deleteProduct = (id) => {
    setFormData((prev) => ({
      ...prev,
      product: prev.product.filter((item) => item.id !== id),
    }));
  };

  const onProductChange = (e) => {
    let valuation = 0;
    let products = formData.product.map((item) => {
      let obj = item.id === e.id ? { ...item, [e.name]: e.value } : item;
      valuation += +obj.product?.base_price * +obj.quantity;
      return obj;
    });
    setFormData((prev) => ({
      ...prev,
      valuation,
      product: products,
    }));
  };

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
    if (event.key === "c") {
      event.stopPropagation();
    }
    if (event.key === "d") {
      event.stopPropagation();
    }
  };

  return (
    <>
      {needButton ? (
        isUpdate ? (
          <IconButton
            sx={{ padding: "4px" }}
            onClick={() => {
              setInitialState();
              handleClickOpen();
            }}
          >
            <MdOutlineModeEdit />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickOpen}>
            <MdAddCircle className='bg-fifth rounded fs-6' />
          </IconButton>
        )
      ) : addButton ? (
        <Button
          variant='outlined'
          onClick={handleClickOpen}
          sx={{
            border: "2px solid #5582AD",
            background: "#ffffff",
            color: "#5582AD",
            fontSize: "14px",
            "&:hover": {
              background: "#ffffff",
            },
          }}
          size='small'
          className='d-flex align-items-center justify-content-center text-capitalize px-2'
        >
          add{" "}
          <span>
            <IoIosAdd />
          </span>
        </Button>
      ) : (
        <MenuItem onClick={handleClickOpen}>
          <PublishedWithChanges fontSize='18' className='me-2' />
          Convert To Deal
        </MenuItem>
      )}
      <Dialog
        onKeyDown={stopPropagationForTab}
        className={root}
        open={open}
        fullWidth
        maxWidth='md'
        aria-labelledby='alert-Dialog-title'
        aria-describedby='alert-Dialog-description'
      >
        <DialogTitle id='alert-Dialog-title' className='border-bottom'>
          {!isUpdate ? "Create a Deal" : "Update Deal"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <Divider>Deal Info</Divider>
            </Grid>

            <Grid item xs={12} className='d-flex mt-3'>
              <Grid item xs={12}>
                <InputFieldNf
                  className='px-2'
                  name='name'
                  label='Deal Name*'
                  color='secondary'
                  size='small'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={formData?.name}
                />
              </Grid>
              {!isUpdate && (
                <Grid item xs={12} className='px-2'>
                  <MultiAutoCompleteNf
                    name='owner'
                    options={userList?.data ? [...userList?.data] : []}
                    value={formData.owner}
                    label='Deal Owner'
                    field='uuid'
                    getOptionLabel={(options) =>
                      options?.profile?.full_name || ""
                    }
                    onChange={(e, value) => {
                      handleChange({ target: { name: "owner", value } });
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className='mt-3 d-flex align-items-center'>
              {!isUpdate && (
                <Grid xs={6} className='px-2'>
                  <MultiAutoCompleteNf
                    className='px-2'
                    name='contacts'
                    multiple={true}
                    options={data?.data ? [...data?.data] : []}
                    value={formData.contacts}
                    label='Add Contacts'
                    field='uuid'
                    getOptionLabel={(options) => options.first_name || ""}
                    onChange={(e, value) => {
                      handleChange({ target: { name: "contacts", value } });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6} className='px-2'>
                <SelectDateNf
                  type='Date'
                  name='expected_closing_date'
                  label='Closing Date'
                  color='secondary'
                  size='small'
                  onChange={(newValue) => {
                    setFormData((prev) => ({
                      ...prev,
                      expected_closing_date: newValue,
                    }));
                  }}
                  value={formData.expected_closing_date}
                />
              </Grid>
            </Grid>
            {!isUpdate && (
              <Grid xs={12}>
                <AutoCompleteProduct
                  addProduct={addProduct}
                  deleteProduct={deleteProduct}
                  formData={formData}
                  onProductChange={onProductChange}
                  handleChange={handleChange}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className='d-flex align-items-center justify-content-center'
            >
              {!isUpdate && (
                <Grid item xs={6} className='px-2'>
                  <AutoSelectFieldNf
                    className='mt-3'
                    name='status'
                    label='Deal Stage'
                    options={deal_stage ? [...deal_stage] : []}
                    getOptionLabel={(options) => options.stage_name || ""}
                    value={formData.status}
                    disabled={fixedStage}
                    onChange={(e, value) => {
                      handleChange({
                        target: { name: "status", value: value },
                      });
                      // handleLeadChange({ target: { name: "status", value } })
                    }}
                    // value={item.product}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CheckboxLabels
                  className='ps-3 mt-3'
                  name='is_hot'
                  onChange={(e, value) => {
                    handleChange({ target: { name: "is_hot", value } });
                  }}
                  label='Hot Deal'
                  default_value={formData?.is_hot}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className='mt-3'>
              <InputFieldNf
                className='px-2'
                name='description'
                label='Description'
                color='secondary'
                size='small'
                maxRows={4}
                minRows={4}
                multiline
                onChange={handleChange}
                value={formData?.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='border-top'>
          <Button
            onClick={() => {
              handleCtoCClose();
              setInitialState();
            }}
            color='error'
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            autoFocus
            onClick={() => {
              isUpdate
                ? updateDeal(formData, {
                    onSuccess: () => {
                      handleCtoCClose();
                      setInitialState();
                    },
                  })
                : mutate(formData, {
                    onSuccess: () => {
                      handleCtoCClose();
                      setInitialState();
                    },
                  });
            }}
            variant='contained'
          >
            {isUpdate ? "Update" : "Convert"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConvertToDeal;
