const sideBarMenu = {
  Customize: [
    {
      title: "Leads",
      path: "/settings/leads",
    },
    // {
    //   title: "Contacts",
    //   path: "",
    // },
    // {
    //   title: "Prospects and customers",
    //   path: "",
    // },
    // {
    //   title: "Deals",
    //   path: "",
    // },
    {
      title: "Deal Pipeline",
      path: "/settings/deal-pipeline",
    },
    {
      title: "Products",
      path: "/settings/products",
    },
    {
      title: "Users",
      path: "/settings/users",
    },
    // {
    //   title: "Email templates",
    //   path: "",
    // },
    // {
    //   title: "Notes",
    //   path: "",
    // },
  ],
  "Communication Channels": [
    {
      title: "Twilio Config",
      path: "/settings/Twilio",
    },
    // {
    //   title: "Voice and Text",
    //   path: "",
    // },
    // {
    //   title: "Chat",
    //   path: "",
    // },
  ],
  "Import Data": [
    {
      title: "Import Leads",
      path: "/settings/import-data/import-leads",
    },
  ],
};

export default sideBarMenu;
